import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
export default function Customers() {
    const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
    <div className="container_box">
    <div className=" w-[40%] customer">
        <h6 style={{ color: theme.primary_bg }} className="font-bold "> Customers </h6>
        <h4 className="text-3xl font-bold py-3">Our <span>Valued</span> Associations</h4>
        <p className="text-lg"> Built for scale, made by experts and secure by design. Bringing you maximum tax savings, unmatched speed and complete peace of mind.
        </p>
      </div>
      <div>
        <img src='/Images/customer.png'/>
      </div>
    </div>
    </Root>
  );
}
const Root = styled.section`
  color: ${(p) => addOpacity(p.theme.primary_txt, 0.9)};
  .container_box{
    display:flex;
    align-items:center;
    gap:10px;
    
    @media (min-width:320px) and (max-width:768px)
    {
      display:block;
    }
    .customer{
      h4 {
        font-size:42px;
        span {
          color:#FFA53B;
        }
      }
      @media (min-width:320px) and (max-width:768px)
      {
        width:100%;
        margin-bottom:40px;
      }
    }
    }
`