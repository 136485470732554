import {combineReducers} from 'redux'
import { profileReducer } from './profile/reducer'
import { platformSettingReducer } from './platformSetting/reducer'
import { itrReducer } from './itr/reducer'

const rootReducer = combineReducers({
  profile: profileReducer,
  platformSetting:platformSettingReducer,
  itr:itrReducer,
})

export default rootReducer