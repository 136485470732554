import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProfileDropDown from "./ProfileDropDown";
import SearchBar from "./SearchBar";
import LanguageSelect from "./LanguageSelect";
import ThemeSelect from "./ThemeSelect";
import Notification from "../Notification";
import { FaAngleDown } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { getAllStatesAction, getCategoriesAction } from "../../redux/platformSetting/action";

export default function Header() {
  const [menuActive, setMenuActive] = useState(false);

  const { strings, theme,themeMode , states } = useSelector((s) => s.platformSetting);
  const { userDetails } = useSelector((s) => s.profile);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toggleBodyScroll = () => {
    const body = document.body;
    if(menuActive){
      body.style.overflow = 'hidden' ;
    }else{
      body.style.overflow = 'auto' ;
    }
   
  };

  useEffect(()=>{
    toggleBodyScroll()
  },[menuActive])

  useEffect(()=>{
    // call action for set all states
    dispatch(getAllStatesAction())
    dispatch(getCategoriesAction())
  },[dispatch])
  
  return (
    <HeaderSection theme={theme}>
      <div className="top_bar" style={{ backgroundColor: theme.primary_bg }}>
        <div className="container_box flex justify-end gap-2">
          <p>Newsletters</p>
          <p>Email Us</p>
        </div>
      </div>
      <header style={{ backgroundColor: theme.secandary_bg }}>
        <div
          className={`text-white p-4 flex items-center justify-between container_box`}
        >
          <button
            onClick={() => {
              setMenuActive(!menuActive);
            }}
            className="toggel_menu"
          >
            <HiOutlineMenu />
          </button>
          {menuActive &&  <button className="background_click" onClick={() => {
              setMenuActive(false);
            }}></button>}
         
          <div
            className={
              menuActive
                ? "hamburger_menu flex items-center justify-between active"
                : "hamburger_menu flex items-center justify-between"
            }
          >
           <button
            style={{ color: "#000" }}
            onClick={() => {
              setMenuActive(!menuActive);
            }}
            className="toggel_close"
          ><IoClose />
          </button>
        
            <div className="flex items-center space-x-3" >
              <span className="font-bold text-lg cursor-pointer" onClick={()=> navigate('/')}>
                <img src={themeMode === 'Dark' ? "/Images/samosa_logo_light.png ":"/Images/samosa_logo.png "} />
              </span>
            </div>

            <nav>
              <ul className="flex space-x-4 menu_bar">
                <NavLink
                  to="/service"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Service
                </NavLink>
                <NavLink
                  to="/resources"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Resources
                </NavLink>

                <NavLink
                  to="/tax-tool"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Tools
                </NavLink>


                {userDetails?.id ?
                  <NavLink
                  to="/filing/itr/link_pan"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                 File your ITR
                </NavLink>
                :  <>
                  <NavLink
                  to="/login"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Let's Begin
                </NavLink>

                {/* <NavLink
                  to="/register"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Let's Begin
                </NavLink> */}
                
                </>}
                
              </ul>
            </nav>
          </div>

          <div className="flex gap-2 items-center header-icons justify-end flex-1">
            <SearchBar />
            <ThemeSelect />
            {/* <Notification /> */}
            <LanguageSelect />
            <ProfileDropDown />
          </div>
        </div>
      </header>
    </HeaderSection>
  );
}

const HeaderSection = styled.section`
  .toggel_menu {
    display: none;
    font-size: 24px;
    color: ${(p) => p.theme.primary_bg};
    @media (max-width: 770px) {
      display: block;
    }
  }
  .hamburger_menu {
    flex: 2;
    >nav ul {
      @media (max-width: 770px) {
        flex-direction:column;
      }
    }
    @media (max-width: 770px) {
      display:none;
      position: fixed;
      left: 0px;
      flex-direction: column;
      background-color: ${(p) => p.theme.tertiary_bg};
      top: 88px;
      height: 100%;
      top: 0px;
      z-index: 999;
      overflow: auto;
    }
    &.active{
      @media (max-width: 770px) {
        display:block;

      }
    }
  }
  .background_click{
    @media (max-width: 770px) {
      backdrop-filter: blur(2px);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index:3;
    }
  }
  .top_bar {
    color: ${(p) => p.theme.secandary_txt};
  }
  .container_box {
    @media (max-width: 1024px) {
      display: flex;
      justify-content: center;
    }
  }
  .menu_bar {
    color: ${(p) => p.theme.primary_txt};
    .nav-items {
      position: absolute;
      z-index: 1;
      background: #2a8ed6;
      width: 100px;
      left: 45%;
      right: 0;
      display: none;
      color: #fff;
      border-radius: 5px;
    }
    .subnav:hover .nav-items {
      display: block;
      padding: 25px;
      margin-top: 20px;
    }
    .subnav li a {
      display: flex;
      align-items: center;
    }
  }
  .header-icons {
    @media (max-width: 1024px) {
      margin-left: 10px;
    }
  }
  .toggel_close{
    display:none;
    @media (max-width: 770px) {
      display:block;
    }
  }
`;
