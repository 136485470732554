import React from "react";
import styled from "styled-components";
import { TbReceiptTax } from "react-icons/tb";

export default function Service() {
  return (
    <ServiceBox>

      <div className="services-banner-main flex items-center justify-center">
        <div className="services-content text-center">
          <h5>WE'RE TAX CONSULTANT</h5>
          <h2>Forward-Thinking<br />Accounting & Tax<br />
            Services.</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </div>
      </div>

      <div className="container_box">
        <div className="accounting-agency flex items-center justify-center gap-5">
          <div className="img-main">
              <img className="image1" src="Images/revenue-operations-concept.jpg"></img>
                <img className="image2" src="Images/paper-analysis.jpg"></img>
          </div>
          <div className="accounting-content">
            <div className="accounting-heading">
              <h5>WELCOME TO ACCOUNTING</h5>
              <h2>We Are the Best Accounting<br></br>Agency</h2>
            </div>
            <div className="accounting-right flex items-center gap-5">
              <div className="accounting-icon">
                <img src="Images/clear-invest.png"></img>
              </div>
              <div className="accounting-right-content">
                <h4>Market Analysis</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy</p>
              </div>
            </div>
            <div className="accounting-right flex items-center gap-5">
              <div className="accounting-icon">
                <img src="Images/clear-invest.png"></img>
              </div>
              <div className="accounting-right-content">
                <h4>Accounting Advisor</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy</p>
              </div>
            </div>
            <div className="accounting-right flex items-center gap-5">
              <div className="accounting-icon">
                <img src="Images/clear-invest.png"></img>
              </div>
              <div className="accounting-right-content">
                <h4>Genral Consultancy</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy</p>
              </div>
            </div>
            
          </div>
        </div>


        <div className="our-services-main ">
          <div className="services-heading text-center">
            <h5>OUR SERVICES</h5>
            <h2>What Service We Offer</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
          <div className="cards-main flex items-center justify-center gap-5 mb-5">
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-invoice.png"></img>

              </div>
              <div className="card-content">
                <h4>Individual Tax</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-gst.png"></img>


              </div>
              <div className="card-content">
                <h4>Bussiness Tax</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-vendor.png"></img>


              </div>
              <div className="card-content">
                <h4>Accounting</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
          </div>
          <div className="cards-main flex items-center justify-center gap-5">
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-tax-cloud.png"></img>


              </div>
              <div className="card-content">
                <h4>Insurance Tax</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-invest.png"></img>


              </div>
              <div className="card-content">
                <h4>Finance Secure</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
            <div className="first-card">
              <div className="card-img flex justify-center">
                <img src="Images/clear-protect.png"></img>


              </div>
              <div className="card-content">
                <h4>Incorporation</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

              </div>
            </div>
          </div>
        </div>
        <div className="our-project">
          <div className="our-project-heading">
            <h2>Let's See Our Latest Project</h2>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
          <div className="project-main flex items-center gap-5 mb-5">
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Permissive Tax <br></br>Planning</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Purpossive Tax <br></br>Planning</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Short-range Tax <br></br>Planning</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
          </div>
          <div className="project-main flex items-center gap-5">
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Timing of<br></br> Income</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Planning  of <br></br> Expenditures</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
            <div className="project-list-main">
              <div className="list-img">
                <img src="Images/card-1.jpg"></img>
              </div>
              <div className="list-content">
                <div className="premessive-text text-center">
                  <h4>Permissive Tax <br></br>Planning</h4>
                  <p>Lorem Ipsum is simply dummy text</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ServiceBox>
  );
}
const ServiceBox = styled.section`
    .services-banner-main {
      background-image: url(../Images/hm-banner-3.jpg);
      width:100%;
      background-repeat:no-repeat;
      background-size:cover;
      padding:120px 0;
      .services-content {
        width:60%;
        h5 {
          font-size:14px;
          font-weight:700;
          color:#FFA53B;
        }
        p {
          font-size:16px;
          color:#fff;
        }
        h2 {
          font-size:42px;
          font-weight:700;
          color:#fff;
          
        }
      }
      
    }
    .our-services-main {
      width:100%;
      .services-heading {
        margin-bottom:50px;
        h2 {
          font-size:42px;
          font-weight:700;
          
        }
        h5 {
          font-size:14px;
          font-weight:700;
          color:#FFA53B;
        }
        p {
          font-size:16px;
          
        }
        
      }
      .cards-main {
        width:100%;
        .first-card {
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          padding:30px 15px;
          border-radius: 0 35px;
        }
        .card-content {
          h4 {
            text-align:center;
            font-size:24px;
            font-weight:700;
          }
        }
        .first-card {
          .card-img {
            img {
              width:110px;
            }
          }
        }
        .first-card:hover {
          background-color:#FFA53B;
          color:#fff;
          transition: 0.3s;
        }
        @media (max-width:991px) {
          flex-direction :column;
        }

      }
    }

    .accounting-agency {
      width:100%;
      margin-bottom:50px;
       .img-main {
        width:40%;
        .accounting-img {
          width:100%;
        }
        @media (max-width:1024px)
        {
          width:80%;
          margin-bottom:50px;
        }
       }
      
      .accounting-content {
        width:60%;
        .accounting-heading {
          h5 {
            font-size:14px;
            font-weight:700;
            color:#FFA53B;
          }
          h2 {
            font-size:42px;
            font-weight:700;
            margin-bottom:30px;
          }
          @media (max-width:1024px) 
          {
            text-align:center;
          }
        }
         .accounting-right {
           margin-bottom:15px;
           .accounting-icon {
            img {
              width:60px;
            }
           }
           @media (max-width:1070px) 
           {
            align-items: center;
            justify-content: center;
           }
         }
         @media (max-width:1024px) 
         {
          margin-top:110px;
          width:100%;
         }
      }
      @media (max-width:1024px) 
      {
        flex-direction:column;
      }
    }
    
    .our-project {
      width:100%;
      margin-top:50px;
      .our-project-heading {
        margin-bottom:50px;
        h2 {
          text-align:center;
          font-size:42px;
          font-weight:700;
          
        }
        p {
          text-align:center;
        }
      }
      .project-list-main {
        .list-img {
          width:100%;
          
        }
        .list-content {
          width:90%;
          box-shadow:
          0 2.8px 2.2px rgba(0, 0, 0, 0.034),
          0 6.7px 5.3px rgba(0, 0, 0, 0.048),
          0 12.5px 10px rgba(0, 0, 0, 0.06),
          0 22.3px 17.9px rgba(0, 0, 0, 0.072),
          0 41.8px 33.4px rgba(0, 0, 0, 0.086),
          0 100px 80px rgba(0, 0, 0, 0.12);
          transform: translate(5.5%, -61%);
          background:#fff;
          .premessive-text {
            padding:10px;
            h4 {
              font-size:24px;
              font-weight:700;
            }
          }
        }
      }
      .project-main {
        @media (max-width:991px)
        {
          flex-direction:column;
        }
      }

    }
    .img-main {
      position:relative;
      top:0;
      left:0;
      .image1 {
        position:relative;
        top:0;
        left:0;
      }
      .image2 {
        position:absolute;
        top:80px;
        right:30px;
      }
    }

    
    `
