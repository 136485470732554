import React from 'react'
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
export default function ConfirmationBox({open,handleClose,children}) {
    const { theme ,strings} = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} className={open && 'active'}>
    <button onClick={() => {handleClose()}} className="close_btn_bg"></button>
    <div className="dialogBox">
        <div className="title">
          <button className="close_btn" onClick={() => {handleClose()}} style={{color:theme.primary_txt}}><IoClose /></button>
        </div>
      {children}
    </div>
  </Root>
  )
}
const Root = styled.section`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000042;
  backdrop-filter: blur(2px);
  top: 0px;
  left: 0px;
  display:none;
  z-index:10;
  &.active{
    display:unset
  }
  .close_btn_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: transparent;
  }
  .close_btn{
    background: ${p => p.theme.tertiary_bg};
    border: none;
    font-size: 20px;
    border-radius: 4px;
    display: block;
    margin-left: auto;
  }
  .dialogBox {
    position: absolute;
    left:50%;
    top:20px;
    transform: translateX(-50%);
    background: ${(p) => p.theme.secandary_bg};
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    padding: 10px;
    overflow: scroll;
  }
`