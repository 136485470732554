import React from "react";
import SelectOption from "../../../../styledComponent/Inputs/SelectOption";
import { useSelector } from "react-redux";
import TextArea from "../../../../styledComponent/Inputs/TextArea";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import DateField from "../../../../styledComponent/Inputs/DateField";
import InputField from "../../../../styledComponent/Inputs/InputField";
import CheckBox from "../../../../styledComponent/Inputs/CheckBox";
import SecandaryButton from "../../../../styledComponent/Button/SecandaryButton";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";

export default function Depreciation() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <div>
      <h4 className="mt-3">{strings.balance_sheet.add_depreciation_entry}</h4>
      <div className="flex_input mt-2">
        <label>{strings.balance_sheet.depreciation_block}</label>
        <SelectOption
          options={[
            strings.balance_sheet.depreciation_block,
            strings.balance_sheet.depreciation_block,
          ]}
          error={""}
          value={""}
          handleChange={() => {}}
        />
      </div>
      <div className="flex_input my-3">
        <label>
          {strings.label.description + strings.label.this_field_is_optional}
        </label>
        <TextArea error={""} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.opening_amount}</label>
        <CurrencyField error={""} value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.purchase_amount_upto_oct_3}</label>
        <CurrencyField error={""} value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>
          {strings.balance_sheet.sale_amount_on_assets_purchased_upto_oct_3}
        </label>
        <CurrencyField error={""} value={""} handleChange={() => {}} />
      </div>

      <div className="flex_input my-2">
        <label>{strings.balance_sheet.purchase_amount_upto_oct_4}</label>
        <CurrencyField error={""} value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>
          {strings.balance_sheet.sale_amount_on_assets_purchased_upto_oct_4}
        </label>
        <CurrencyField error={""} value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.date_on_which_sale_was_made}</label>
        <DateField error={""} value={""} handleChange={() => {}} />
      </div>
      <p className="opacity-60">
        {strings.message.specify_date_of_sale_if_you_had_any_asset}
      </p>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.personal_usage_percentage}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>

      <div className="flex_input my-2">
        <label>
          {strings.balance_sheet.additional_depreciation_claimed_during_previous_ay}
        </label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <p className="opacity-60">
        {strings.message.during_year_for_assets_purchased_from}
      </p>
      <CheckBox
        value={""}
        label={strings.balance_sheet.is_block_nil}
        handleChange={() => {}}
      />
      <CheckBox
        value={""}
        label={strings.balance_sheet.additional_depreciation}
        handleChange={() => {}}
      />

      <div className="flex items-center gap-3">
        <SecandaryButton
          title={strings.button.add}
          handleClick={() => console.log("--")}
        />
        <PrimaryButton
          title={strings.button.save_and_close}
          handleClick={() => console.log("--")}
        />
      </div>
    </div>
  );
}
