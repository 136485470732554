import React from "react";
import { useSelector } from "react-redux";
import InputField from "../../../../styledComponent/Inputs/InputField";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";

export default function ScheduleICDS() {
  const { theme, strings } = useSelector((state) => state.platformSetting);
  return (
    <div>
      <h4 className="my-4">
        {
          strings.balance_sheet
            .schedule_icds_effect_of_income_computation_disclosure_standards_on_profit
        }
      </h4>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.accounting_policies}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.valuation_of_inventories}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.construction_contracts}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.revenue_recognition}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.tangible_fixed_assets}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.changes_foreign_exchange_rates}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.government_grants}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.securities}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>{strings.balance_sheet.borrowing_costs}</label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <div className="flex_input my-2">
        <label>
          {
            strings.balance_sheet
              .provisions_contingent_liabilities_contingent_assets
          }
        </label>
        <InputField value={""} handleChange={() => {}} />
      </div>
      <PrimaryButton
        title={strings.button.save_and_close}
        handleClick={() => {}}
      />
    </div>
  );
}
