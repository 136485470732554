import React, { useState } from "react";
import StyleFormBox from "../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Documents from "../component/Profile/Documents";
import Tickets from "../component/Tickets/Tickets";
import TrackStatus from "../component/Profile/TrackStatus";
import PurchaseHistory from "../component/Profile/PurchaseHistory";
import EditProfile from "../component/Profile/EditProfile";
import Settings from "../component/Profile/Settings";
import FormDialogBox from "../component/DialogBox/FormDialogBox";
import { MdVerified } from "react-icons/md";
import { CiMail } from "react-icons/ci";
export default function Profile() {
  const [settingBox, setSettingBox] = useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { userDetails } = useSelector((s) => s.profile);
  // console.log("userDetails ===========>", userDetails);
  return (
    <Root>
      <div className="profile_details">
        <div className="main-profile">
          <StyleFormBox>
            <div className="avtar-pro">
              <div className="avtar-img">
                <img src={userDetails.avatar} className=" avatar" />
              </div>
              <div className="flex flex-column avtar-btm">
                <div className="flex items-center mb-2 avtar-name">
                  <a
                    href="#"
                    className="text-gray-900 flex items-center gap-2 text-hover-primary fs-5 fw-bold me-1"
                  >
                    Arun Kumar <MdVerified />
                  </a>
                  <a href="#"></a>
                </div>

                <div className="flex gap-2 flex-wrap fw-semibold fs-6 mb-4 pe-2">
                  <a
                    href="#"
                    className="flex items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                    Developer
                  </a>
                  <a
                    href="#"
                    className="flex items-center text-gray-500 text-hover-primary me-5 mb-2"
                  >
                    SF, Bay Area
                  </a>
                  <a
                    href="#"
                    className="flex items-center text-gray-500 text-hover-primary mb-2"
                  >
                    <CiMail />
                    arun@123.com
                  </a>
                </div>
              </div>
            </div>
            <EditProfile />
            <div className="flex gap-2">
              <button
                className={activeTab === "overview" ? "active" : ""}
                onClick={() => setActiveTab("overview")}
              >
                Overview
              </button>
              <button
                className={activeTab === "settings" ? "active" : ""}
                onClick={() => setActiveTab("settings")}
              >
                Settings
              </button>

              <button
                className={activeTab === "documents" ? "active" : ""}
                onClick={() => setActiveTab("documents")}
              >
                Documents
              </button>
              <button
                className={activeTab === "itrstatus" ? "active" : ""}
                onClick={() => setActiveTab("itrstatus")}
              >
                ITR Status
              </button>
              <button
                className={activeTab === "ticket" ? "active" : ""}
                onClick={() => setActiveTab("ticket")}
              >
                Tickets
              </button>
              <button
                className={activeTab === "purchaseHistory" ? "active" : ""}
                onClick={() => setActiveTab("purchaseHistory")}
              >
                PurchaseHistory
              </button>
            </div>
          </StyleFormBox>

          <StyleFormBox>
            {activeTab === "overview" ? (
              <div>
                {" "}
                <div className="overview-header">
                  <h4>Profile Details</h4>
                </div>
                <div className="profile-data">
                  <div className="profile-left">
                    <h5>Full Name</h5>
                  </div>
                  <div className="profile-right">
                    <h5>Arun kumar</h5>
                  </div>
                </div>
                <hr></hr>
                <div className="profile-data">
                  <div className="profile-left">
                    <h5>Contact Number</h5>
                  </div>
                  <div className="profile-right">
                    <h5>0987654321</h5>
                  </div>
                </div>
                <hr></hr>
                <div className="profile-data">
                  <div className="profile-left">
                    <h5>Communication</h5>
                  </div>
                  <div className="profile-right">
                    <h5>Email, Phone</h5>
                  </div>
                </div>
                <hr></hr>
                <div className="profile-data">
                  <div className="profile-left">
                    <h5>Email</h5>
                  </div>
                  <div className="profile-right">
                    <h5>Arun@123.com</h5>
                  </div>
                </div>
              </div>
            ) : activeTab === "documents" ? (
              <Documents />
            ) : activeTab === "itrstatus" ? (
              <TrackStatus />
            ) : activeTab === "settings" ? (
              <Settings />
            ) : activeTab === "ticket" ? (
              <Tickets />
            ) : activeTab === "purchaseHistory" ? (
              <PurchaseHistory />
            ) : (
              ""
            )}
          </StyleFormBox>

          <button
            className="primary_button mt-3"
            onClick={() => setSettingBox(true)}
          >
            See All Settings
          </button>
        </div>
      </div>
    

      <FormDialogBox
        open={settingBox}
        formTitle={"General Settings"}
        handleClose={() => {
          setSettingBox(false);
        }}
      >
        <Settings />
      </FormDialogBox>
    </Root>
  );
}
const Root = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 10px;
  display: flex;
  gap: 8px;
  .profile_details {
    width: 100%;
    .main-profile {
      width: 100%;
      .avtar-pro {
        display: flex;
        gap: 20px;
        .avtar-btm {
          flex-direction: column;
          .avtar-name {
            a {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .overview-header {
        width: 100%;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
      }
      .profile-data {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        .profile-left {
          width: 25%;
          h5 {
            color: #99a1b7;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .profile-right {
          width: 25%;
          h5 {
            color: #000;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
  ul {
    list-style-type: none;
    li {
      a {
        font-size: 15px;
        font-weight: 700;
        color: #99a1b7;
      }
    }
  }
  ul li:hover {
    list-style-type: none;
    border-bottom: 2px solid rgb(255, 165, 59);
  }
  .profile_details_tabs {
    flex: 3;
    /* width:70%; */
  }
  .avatar {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
`;
