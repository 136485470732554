import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import ToolTip from "../../../styledComponent/ToolTip";
import Input from "../../../styledComponent/Inputs/Input";
import Select from "../../../styledComponent/Inputs/Select";
import CheckBox from "../../../styledComponent/Inputs/CheckBox";
import { getCitiesByIdAction } from "../../../redux/platformSetting/action";
import {
  postAddDonationsAction,
  updateAddDonationsAction,
} from "../../../redux/itr/action";
import * as Yup from "yup";
import LoadingBox from "../../DialogBox/LoadingBox";
import { removeEmptyKeys } from "../../../utils/helpers";
import { toast } from "react-toastify";

export default function Donation80GGA({
  editData,
  organizations80g,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [activeState, setActiveState] = useState(1);
  const [citiesList, setCitiesList] = useState([]);
  const [loader, setLoader] = useState(false);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData} = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };
  
  const handleSubmitForm = (values) => {

    if (values.id) {
      setLoader(true)
      dispatch(
        updateAddDonationsAction(removeEmptyKeys(values), (e) => {
          console.log(e);
          if (e.status == 200) {
            setLoader(false)
            toast.success('Successfully update data')
            handleCallback(e.data);
          }else{
            setLoader(false)
          }
        })
      );
    } else {
      let { id, ...newData } = values;
      setLoader(true)
      dispatch(
        postAddDonationsAction(removeEmptyKeys(newData), (e) => {
          console.log(e);
          if (e.status == 200) {
            setLoader(false)
            toast.success('Successfully upload data')
            handleCallback(e.data);
          }else{
            setLoader(false)
          }
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    organization: Yup.string().required(strings.validation_message.required),
    doneeWithPanName: Yup.string().required(strings.validation_message.required),
    doneePAN: Yup.string().required(strings.validation_message.required),
    addrDetail: Yup.string().required(strings.validation_message.required),
    cityId: Yup.number().required(strings.validation_message.required),
    stateId: Yup.number().required(strings.validation_message.required),
    pinCode: Yup.number().required(strings.validation_message.required),
    donationAmtCash: Yup.number().required(strings.validation_message.required),
    // donationAmtOtherMode: Yup.number().required(strings.validation_message.required),

  });

  useEffect(() => {
    getCities();
  }, [activeState]);


  useEffect(() => {
    setLoader(true)
    setFormData({
      organization: editData?.organization?.id || "",
      donationType: "80GGA",
      doneeWithPanName: editData?.doneeWithPanName || "",
      doneePAN: editData?.doneePAN || "",
      addrDetail: editData?.addrDetail || "",
      cityId: 1,
      stateId: editData?.stateId?.id || 1,
      pinCode: editData?.pinCode || '',
      donationAmtCash: editData?.donationAmtCash || '',
      donationAmtOtherMode: editData?.donationAmtOtherMode || '',
      id: editData?.id || "",
      itrId: ItrData.id,
    });
    setLoader(false)
  }, [editData]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
        handleFormUpdate
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <StyleFormBox>
            <div className="flex_input mt-2">
              <label>
                {strings.tax_saving.clause_under_which_donation_is_made}
              </label>
              <Select
                value={values.organization}
                error={errors.organization}
                name={"organization"}
                onChange={handleChange}
              >
                <option value={""}>{strings.label.choose_option}</option>
                {organizations80g?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.OrganizationName}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex_input mt-2">
              <label>{strings.tax_saving.name_of_donee}</label>
              <Input
                placeholder={strings.placeholder.name_of_donee_organization}
                name={"doneeWithPanName"}
                value={values.doneeWithPanName}
                error={errors.doneeWithPanName}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input mt-2">
              <label>{strings.tax_saving.donation_amount_cash}</label>
              <Input
                inputType={"currency"}
                value={values.donationAmtCash}
                error={errors.donationAmtCash}
                name={"donationAmtCash"}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input mt-2">
              <label>{strings.tax_saving.donation_amount_non_cash} </label>
              <Input
                inputType={"currency"}
                name={"donationAmtOtherMode"}
                value={values.donationAmtOtherMode}
                error={errors.donationAmtOtherMode}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input mt-2">
              <label>{strings.tax_saving.pan_of_donee}</label>
              <Input
                placeholder={strings.placeholder.pan_of_donee_here}
                value={values.doneePAN}
                error={errors.doneePAN}
                name={"doneePAN"}
                onChange={handleChange}
              />
            </div>
            <span className="note_message">
              {strings.message.enter_if_you_donated_to_govt}
            </span>
            <hr />
            <h4 className="mt-3">{strings.placeholder.address_of_donee}</h4>
            <div className="flex_input mt-2">
              <label>{strings.address.address_line}</label>
              <Input
                placeholder={strings.placeholder.address_of_donee}
                value={values.addrDetail}
                error={errors.addrDetail}
                name={"addrDetail"}
                onChange={handleChange}
              />
            </div>
            <div className="flex_input mt-2">
              <label>{strings.address.pincode} </label>
              <Input
                placeholder={strings.placeholder.enter_pin_code}
                value={values.pinCode}
                error={errors.pinCode}
                type={"number"}
                name={"pinCode"}
                onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
              />
            </div>
            <div className="flex_input my-2">
              <label>{strings.address.state}</label>
              <Select
                name={"stateId"}
                error={errors.stateId}
                value={values.stateId}
                onChange={(e) => {
                  handleChange(e);
                  setActiveState(e.target.value);
                }}
              >
                <option value="" disabled>
                  {strings.label.choose_option}
                </option>
                {states?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </div>
            <div className="flex_input my-2">
              <label>{strings.address.city}</label>

              <Select
                name={"cityId"}
                error={errors.cityId}
                value={values.cityId}
                onChange={handleChange}
              >
                <option value="" disabled>
                  {strings.label.choose_option}
                </option>
                {citiesList?.map((i, ix) => (
                  <option value={i.id} key={ix}>
                    {i.name}
                  </option>
                ))}
              </Select>
            </div>

            <button className="primary_button" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </StyleFormBox>
        )}
      </Formik>
    </>
  );
}
