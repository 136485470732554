import React from "react";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import { useSelector } from "react-redux";
import { IoTicket } from "react-icons/io5";
export default function ChatSupport() {
  const { strings, theme } = useSelector((s) => s.platformSetting);

  return (
    <div className="min_container">
      <StyleFormBox>
        <div className="mx-auto block text-3xl p-4 rounded-full" style={{background:theme.primary_bg , width:'fit-content'}}>
          <IoTicket />
        </div>
        <PrimaryButton
          title={strings.button.ticketing_inhouse_chat}
          handleClick={() => {}}
        />
      </StyleFormBox>
    </div>
  );
}
