import React from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import { NavLink } from "react-router-dom";
import TicketsBox from "./TicketsBox";
export default function Tickets() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dummyData = [
    {
      id: "1",
      ticketNumber: "UTPBO",
      service: "ITR 1",
      date: "20/01/2024",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      status: "Closed",
    },
    {
      id: "2",
      ticketNumber: "UTPB1",
      service: "ITR 1",
      date: "20/01/2024",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      status: "",
    },
  ];
  return (
    <Root theme={theme}>
      <AccordianTab title={"Tickets"}>
      <button>Add Ticket</button>
        <div>
          {dummyData.map((i, ix) => {
            return (
             <div key={ix}> <TicketsBox data ={i}/></div>
            );
          })}
        </div>
      </AccordianTab>
    </Root>
  );
}

const Root = styled.div`
.box_section{
  margin-top:16px;
}

`;
