import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegSquareCaretRight } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
import { FaChevronRight } from "react-icons/fa";
export default function MyDashboard() {
    const { theme } = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>

            <div className="my-dashboard-section">
                <div className="container_box">
                    <div className="dashboard-heading">
                        <h4>My Dashboard</h4>
                    </div>
                    <div className="dashboard-main">
                        <div className="dashboard-left">
                            <div className="dashboard-box">
                                <div className="btm-left-main flex">
                                    <div className="dashboard-text-first-left">
                                        <p>Filing Status:AY 2024 - 2025</p><br></br>
                                        <h6>Hello there,</h6>
                                        <h5>File your IT Return in 3 simple steps</h5>
                                        <div className="it-return-main">
                                            <div className="return-first">
                                                <div className="return-img">
                                                    <img src="Images/tick-circle.png"></img>
                                                </div>
                                                <div className="return-text">
                                                    <h5>Link PAN & Pre-fill</h5>
                                                </div>
                                            </div>
                                            <div className="return-first">
                                                <div className="return-img">
                                                    <img src="Images/tick-circle.png"></img>
                                                </div>
                                                <div className="return-text">
                                                    <h5>Add your Details</h5>
                                                </div>
                                            </div>
                                            <div className="return-first">
                                                <div className="return-img">
                                                    <img src="Images/tick-circle.png"></img>
                                                </div>
                                                <div className="return-text">
                                                    <h5>File ITR & e-Verify</h5>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="know-more-btn">
                                            <button type="button">Know more about steps</button>
                                        </div>
                                        <div className="start-filing-btn">
                                            <button type="button">Start Filing</button>
                                        </div>
                                    </div>
                                    <div className="dashboard-left-second">
                                        <img src="Images/dashboard-left.png"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="bt-main flex">
                                <div className="bt-first-main flex">
                                    <div className="bt-1">
                                        <div className="bt-icons">
                                            <img src="Images/ssl.png"></img>
                                        </div>
                                    </div>
                                    <div className="bt-1">
                                        <div className="bt-icons">
                                            <img src="Images/iso-certified.png"></img>
                                        </div>
                                    </div>
                                    <div className="bt-1">
                                        <div className="bt-icons">
                                            <img src="Images/user.png"></img>
                                            <h6>6M+</h6>
                                            <p>users</p>
                                        </div>
                                    </div>
                                    <div className="bt-1">
                                        <div className="bt-icons">
                                            <img src="Images/cr.png"></img>
                                            <h6>800Cr+</h6>
                                            <p>Worth taxes filed</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="bt-2">
                                        <div className="bt-icons">
                                            <img src="Images/shield.png"></img>
                                            <p>
                                                Cleartax is a Govt. authorized ERI license holder.

                                                Your data is 100% secure with Cleartax.</p>
                                        </div>
                                    </div>
                            </div>
                            <div className="card-section flex">
                                <div className="card-box">
                                    <div className="main-card">
                                        <div className="card-icon">
                                            <img src="Images/circle-bk.png"></img>
                                        </div>
                                        <div className="card-content">
                                            <h6>My ITR Filings</h6>
                                            <p>A comprehensive record of your past ITR filings</p>
                                        </div>
                                        <div className="card-icon">
                                        <FaChevronRight />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-box">
                                    <div className="main-card">
                                        <div className="card-icon">
                                            <img src="Images/profile.png"></img>
                                        </div>
                                        <div className="card-content">
                                            <h6>My ITR Filings</h6>
                                            <p>A comprehensive record of your past ITR filings</p>
                                        </div>
                                        <div className="card-icon">
                                        <FaChevronRight />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="card-section-2 flex">
                                <div className="card-box-2">
                                    <div className="main-card-2">
                                        <div className="card-icon-2">
                                            <img src="Images/circle-bk.png"></img>
                                        </div>
                                        <div className="card-content-2">
                                            <h6>Start for free, pay only when you file</h6>
                                            <p>Auto plan selection, auto regime selection, post filing assurance<br></br> and much more!</p>
                                        </div>
                                        <div className="card-icon-2">
                                        <FaChevronRight />
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="dashboard-left-second">
                            <div className="first-right-box">
                                <div className="right-box-main flex items-center">
                                    <div className="right-icon">
                                        <img src="Images/step.png"></img>
                                    </div>
                                    <div className="right-text">
                                        <p>Step by step guide<br></br> to file ITR</p>
                                        <hr></hr>
                                        <button type="button">View Guide</button>
                                    </div>
                                </div>
                            </div>
                            <div className="right-second-box">
                                <div className="second-box-main">
                                    <div className="second-text">
                                        <span>Expert Assisted Filing</span><br></br>
                                        <h4>Need assistance to file Income Tax Return?</h4><br></br>
                                        <p>Hire an expert to do your taxes equipped with end-to-end online fulfilment. No hassle. 100% digital.</p><br></br>
                                        <p>Plan Starts from</p>
                                        <h6>₹ 1,399₹ <strike>3,499</strike></h6>
                                        <button className="inline-flex text-center justify-center items-center font-bold py-3 px-6 focus:outline-none text-base text-white; rounded-sm mt-5 w-full border border-gray-900 bg-transparent" type="button">Hire an expert</button>
                                    </div>
                                    <div className="right-btm-img">
                                        <img src="Images/grop.png"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="first-right-box mt-5">
                                <div className="right-box-main flex items-center">
                                    <div className="right-icon">
                                        <img src="Images/step.png"></img>
                                    </div>
                                    <div className="right-text">
                                        <h6>Live Support</h6>
                                        <p>for ITR Doubts and<br></br> Queries</p>
                                        <hr></hr>
                                        <button type="button">Chat Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Root>
    );
}

const Root = styled.section`
.my-dashboard-section {
    width:100%;
    .dashboard-heading {
        width:100%;
        margin:40px 0;
        h4 {
            font-size:32px;
            font-weight:500;
            color:#000;
        }
    }
    .dashboard-main {
        width:100%;
        display:flex;
        gap:20px;
        .dashboard-left {
            width:70%;
            .dashboard-box {
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                width:100%;
                border-radius:32px;
                background:#fff;
                .dashboard-text-first-left {
                    width:70%;
                    padding: 40px 20px;
                    p {
                        font-size:16px;
                    }
                    h6 {
                        font-size:14px;
                        font-weight:700;
                    }
                    h5 {
                        font-size:24px;
                        font-weight:700;
                    }
                    .it-return-main {
                        width:100%;
                        display:flex;
                        margin-top:40px;
                        .return-first {
                            width:100%;
                            position:relative;
                            .return-img {
                                display:flex;
                                align-items:center;
                                width:30%;
                                img {
                                    width:20px;
                                }  
                            }
                            .return-img:after {
                                border-bottom: 2px dashed #a2a2a2;
                                content: " ";
                                flex: 1;
                                margin: 0 25px;
                                position:absolute;
                                width:120px;
                            }
                            .return-text {
                                
                                h5 {
                                    font-size:16px;
                                    font-weight:700;
                                }
                            }
                        }
                        
                    }
                    .know-more-btn {
                        width:100%;
                        button {
                            color:blue;
                            background:none;
                            border:none;
                            margin:30px 0;
                        }
                    }
                    .start-filing-btn {
                        button {
                            background:blue;
                            padding:10px;
                            border-radius:4px;
                            width:220px;
                            color:#fff;
                        }
                    }
                    @media (max-width:580px) {
                        width:100%;
                    }
                }

            }
                .bt-main {
                    width:100%;
                    margin:40px 0;
                    .bt-first-main {
                        width:70%;
                        gap:50px;
                        align-items:center;
                        .bt-1 {
                            .bt-icons {
                                display:flex;
                                align-items:center;
                                img {
                                    width:60px;
                                }
                            }
                        }
                        @media (max-width:580px) {
                            width:100%;
                        }
                        
                    }
                    .bt-2 {
                        width:30%;
                        .bt-icons {
                            display:flex;
                            align-items:center;
                        }
                        @media (max-width:768px) {
                            width:100%;
                        }
                    }
                    @media (max-width:580px) {
                        flex-direction:column;
                    }
                }
                .card-section {
                    width:100%;
                    gap:10px;
                    .card-box {
                        width:50%;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        background:#e7e7f9;
                        padding:30px 15px;
                        border-radius:12px;
                        .main-card {
                            display:flex;
                            align-items:center;
                            gap:10px;
                            .card-icon {

                            }
                            .card-content {
                                h6 {
                                    font-size:20px;
                                    font-weight:700;
                                }
                            }
                        }
                    }
                }
                .card-section-2 {
                    width:100%;
                    gap:10px;
                    margin-top:30px;
                    .card-box-2 {
                        width:100%;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        background:#e7e7f9;
                        padding:30px 15px;
                        border-radius:12px;
                        .main-card-2 {
                            display:flex;
                            align-items:center;
                            gap:10px;
                            .card-icon-2 {

                            }
                            .card-content-2 {
                                h6 {
                                    font-size:20px;
                                    font-weight:700;
                                }
                            }
                        }
                    }
                }
                @media (max-width:991px) {
                    width:100%;
                }
                
            
        }
        .dashboard-left-second {
            width:30%;
            img {
                @media (max-width:991px) {
                    display:none;
                }
            }
            .first-right-box {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                width:100%;
                padding:15px 7px;
                border-radius:32px;
                background:#fff;
                border: 2px solid rgb(180 209 248);
                .right-box-main {
                    gap:20px;
                    .right-icon {

                    }
                    .right-text {
                        p {
                            font-size:16px;
                        }
                        h6 {
                            font-weight:700;
                            margin-bottom:10px;
                        }
                        button {
                            background:none;
                            color:blue;
                            margin-top:10px;
                        }
                    }
                }
            }
            .right-second-box {
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                padding:30px 15px 0 15px;
                border-radius:32px;
                border: 1px solid #fdca85;
                background:#feedd7;
                margin-top:30px;
                .second-box-main {
                    .second-text {
                        span {
                            background:#000;
                            color:#fff;
                            padding:5px;
                            border-radius:5px;
                            font-size:12px;
                        }
                        h4 {
                            font-size:20px;
                            font-weight:600;
                            color:#000;
                        }
                    }
                    .right-btm-img {
                        img {
                            width:170px;
                        }
                    }
                }
            }
            
            @media (max-width:991px){
                width:100%;
            }
        }
        @media (max-width:991px) {
            flex-direction:column;
        }
    }
}
.btm-left-main {
    @media (max-width:991px) {
        flex-direction:column;
    }
}

`