import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";

export default function TaxTool() {
  const { theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="header-section">
        <div className="header-content flex justify-center items-center">
          <div className="text-line">
            <h5>Filing for AY 2024-25 is coming soon</h5>
          </div>
          <div className="pre-button">
            <button type="button">Pre-Book Now</button>
            <span className="off_coupon">Grab 25% OFF</span>
          </div>
        </div>
      </div>
      <div className="tax-tools-section">
        <div className="container_box">
          <div className="tax-tools-heading">
            <h2>Financial Planning Calculators - Free Online Tax Tools</h2>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>80DD calculator</h5>
                  <p>
                    If you have disabled dependants in your family, you can
                    claim a deduction of the same from your taxable income. This
                    deduction is available only if the disability is more than
                    40%.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>80TTA calculator</h5>
                  <p>
                    Use this 80TTA calculator to calculate the amount of
                    deduction you can claim against the interest received on
                    your savings account from your total taxable income.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>80U calculator</h5>
                  <p>
                    Disabled taxpayers can claim a deduction from income for
                    themselves under section 80U. The deduction amount under
                    this section is fixed as per the percentage of disability.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Medical facility calculator</h5>
                  <p>
                    Received medical facility from your employer in India or
                    outside India? Calculate the medical facility received and
                    the tax deduction available on it with Tax2win’s medical
                    facility calculator.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Indexed cost of acquisition or improvement</h5>
                  <p>
                    The indexed cost of acquisition or improvement is the cost
                    of acquisition as adjusted by the inflation rate.
                    Calculating the indexed cost of acquisition/improvement can
                    help reduce tax liability on capital gains.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Simple interest calculator</h5>
                  <p>
                    Be at the top of your financial planning game by getting an
                    estimate of the simple interest you have to pay or receive
                    using this free simple interest calculator.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Compound interest calculator</h5>
                  <p>
                    Whether you have to pay interest on a loan or receive
                    interest on bank investments, compound interest calculation
                    is crucial for financial planning.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>IFSC code search calculator</h5>
                  <p>
                    If you are in the middle of a bank transaction and want to
                    find out your IFSC code online, simply enter your bank and
                    branch name and get the IFSC code at your fingertips.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Leave Encashment & Taxable Leave Salary Calculator</h5>
                  <p>
                    Instantly calculate the taxable portion of your leave
                    encashment and leave salary for accurate tax planning!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Income From House Property Calculator</h5>
                  <p>
                    Compute your income from property rentals quickly and
                    accurately for tax assessment purposes!
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Gratuity Calculator</h5>
                  <p>
                    {" "}
                    Use this gratuity calculator to calculate the amount of
                    gratuity you can claim as a deduction.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Transport allowance calculator</h5>
                  <p>
                    If you are also receiving transport allowance from your
                    employer, you are eligible to claim a tax exemption on the
                    same. Calculate the amount of transport allowance exemption
                    and save more tax.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Calculator on Section 234F</h5>
                  <p>
                    Missed filing your ITR for a specific FY? You have to pay a
                    late fee under section 234F. This calculator helps you
                    calculate the late fee applicable.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>ITR Eligibility Calculator</h5>
                  <p>
                    Check your eligibility for filing an Income Tax Return (ITR)
                    in seconds!
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Sukanya Samriddhi Yojana Calculator</h5>
                  <p>
                    Plan for your child's future with ease—calculate returns and
                    contributions for the Sukanya Samriddhi Yojana!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>NSC calculator</h5>
                  <p>
                    NSC, or National Savings Certificate, is a fixed investment
                    option in India. The NSC calculator allows you to estimate
                    the interest receivable and the tax deduction available on
                    NSC investments.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>
                    Children education and hostel allowance deductions
                    calculator
                  </h5>
                  <p>
                    Reduce your tax liability by getting an accurate estimate of
                    the amount of tax-free children's education and hostel
                    allowance available to you.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Holding period calculator for capital assets</h5>
                  <p>
                    The taxation of capital assets is based on the holding
                    period of capital assets. The holding period calculator
                    helps you determine the nature of capital assets based on
                    the holding period (short-term or long-term).
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Rent free accommodation calculator</h5>
                  <p>
                    If you are receiving a furnished or unfurnished
                    accommodation from your employer, then you can calculate the
                    taxable value of your accommodation using the rent-free
                    accommodation calculator.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Presumptive income under u/s 44E calculator</h5>
                  <p>
                    Want to avoid the burden of maintaining books of accounts
                    and complex paperwork? Use this calculator to calculate your
                    presumptive income under section 44E.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Remuneration to partners calculator</h5>
                  <p>
                    Firms and companies are allowed to claim a deduction for the
                    remuneration paid to working partners under section 40(b).
                    Calculate the amount of deduction you can claim in just a
                    few clicks.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Income Tax Calculator</h5>
                  <p>
                    Estimating your taxes before you file your tax return with
                    this tool.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Rent Reciept Generator</h5>
                  <p>
                    Avoid the last-minute hassle of arranging your rent receipts
                    for claiming HRA exemption. Use our free rent receipt
                    generator and save taxes.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>HRA Calculator</h5>
                  <p>
                    Estimate your House Rent Allowance (HRA) exemption and
                    optimize your tax savings effortlessly!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-main flex justify-center">
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>New vs Old Tax Regime Comparison Calculator</h5>
                  <p>
                    Have you also been stuck between the choice of a more
                    beneficial tax regime?? Relax!! We have made it easier for
                    you.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>Form 12BB</h5>
                  <p>
                    Maximize your take-home salary by calculating all possible
                    deductions and exemptions that you can claim. This free 12BB
                    calculator has been carefully curated so that you never miss
                    out on any potential tax savings.
                  </p>
                </div>
              </div>
            </div>
            <div className="first-card">
              <div className="card-content">
                <div className="card-icon">
                  <img src="Images/more-calc-icon.png"></img>
                </div>
                <div className="card-text">
                  <h5>TDS Calculator</h5>
                  <p>
                    Easily compute your Tax Deducted at Source (TDS) and save
                    taxes with this handy calculator!{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="file-itr-section">
        <div className="container_box">
          <div className="file-itr-main">
            <div className="flex box">
              <div className="itr-left">
                <h2>File ITR Yourself Anytime & Anywhere</h2>
                <p>
                  We promise you the best refunds on your income tax returns
                  without making you spend any time or money
                </p>
                <div className="itr-button">
                  <button type="button">
                    File your ITR <FaArrowRightLong />
                  </button>
                </div>
              </div>
              <div className="itr-right">
                <img src="Images/anytime-anywhere.png"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .header-section {
    width: 100%;
    background: linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
    padding: 20px 0;
    .header-content {
      width: 100%;
      .text-line {
        width: 30%;
        h5 {
          color: #fff;
          text-align: center;
        }
        @media (max-width: 576px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .pre-button {
        button {
          width: 150px;
          background: ${(p) => p.theme.secandary_bg};
          padding: 12px;
          border-radius: 5px;
        }
        .off_coupon {
          border: dashed 2px #fff;
          font-weight: 600;
          background: rgba(255, 255, 255, 0.1);
          margin-left: 1rem;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          color: #fff;
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  }
  .tax-tools-section {
    width: 100%;
    margin: 50px 0;
    .tax-tools-heading {
      width: 100%;
      margin-bottom: 30px;
      h2 {
        font-size: 32px;
        text-align: center;
        font-weight: 700;
      }
    }
    .card-main {
      width: 100%;
      gap: 20px;
      .first-card {
        width: 30%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding: 30px 15px;
        background: ${(p) => p.theme.secandary_bg};
        border-radius: 10px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
          width: 70%;
        }
      }
      .first-card:hover {
        background: linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
        color: #fff;
      }
      .card-content {
        width: 100%;
        .card-icon {
          margin-bottom: 10px;
          img {
            width: 40px;
          }
        }
        .card-text {
          h5 {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .file-itr-section {
    width: 100%;
    .file-itr-main {
      display: flex;
      justify-content: center;
      .box {
        width: 80%;
        padding: 40px 20px;
        background: ${(p) => p.theme.secandary_bg};
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        justify-content: space-around;

        .itr-left {
          width: 50%;
          h2 {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
          }
          .itr-button {
            margin-top: 20px;
            button {
              background: #ffa53b;
              padding: 10px;
              width: 140px;
              border-radius: 5px;
              color: #fff;
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }
          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
`;
