import styled from "styled-components";


export default function Terms() {
    return (
        <Root>
            <div className="terms-section">
                <div className="container_box">
                    <div className="terms-main">
                        <div className="terms-left">
                            <div className="terms-heading">
                                <h2>Website terms and conditions</h2>
                                <p>We give you a large choice of terms and conditions templates suitable for all types of website,<br></br> from an online store, to a technical web-based service, to a news site that has an advertising<br></br> revenue model. Our clear, precise and easy to edit templates:</p>
                            </div>
                            <div className="terms-left-content">
                                <ul>
                                    <li>reassure visitors that they should trade with you</li>
                                    <li>set out the framework of your business model</li>
                                    <li>help protect you from unfounded claims</li>
                                    <li>include strong intellectual property protection</li>
                                </ul>
                            </div>
                        </div>
                        <div className="terms-right">
                            <div className="terms-img">
                                <img src="Images/website-terms-and-conditions-templates-in.jpg"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Root>
    );
}

const Root = styled.section`

.terms-section {
    width:100%;
    .terms-main {
        width:100%;
        display:flex;
        .terms-left {
            width:70%;
            .terms-heading {
                h2 {
                    font-size:42px;
                    font-weight:400;
                }
                p {
                    font-size:16px;
                    font-weight:normal;
                    margin-top:10px;
                }
            }
            .terms-left-content {
                width:100%;
                ul {
                    list-style-type:disc;
                    margin-top:50px;
                }
            }
            @media (max-width:768px) {
                width:100%;
                text-align:center;
            }
           
        }
        .terms-right {
            width:30%;
            .terms-img {
                width:100%;
                clip-path: circle(50%);
                img {
                    width:100%;
                }
            }
        }
        @media (max-width:768px) {
            flex-direction:column;
            align-items:center;
        }
    }
}

`