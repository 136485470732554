import React from 'react'
import { useSelector } from 'react-redux';
import styled from 'styled-components'
export default function SecandaryButton({title , handleClick ,addClass ,disable}) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme} onClick={()=>{handleClick()}} className={addClass} disabled={disable}>{title}</Root>
  )
}
const Root = styled.button`
        width:100%;
        background-color:transparent;
        color:${p => p.theme.primary_bg};
        font-weight:600;
        border-radius: 4px;
        padding: 10px;
        border: 1px solid ${p => p.theme.primary_bg};
        cursor:pointer;
`