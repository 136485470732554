import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import SearchField from "../../styledComponent/Inputs/SearchField";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import { NavLink, useNavigate } from "react-router-dom";
import { getGlossaryAction } from "../../redux/profile/action";
export default function Glossary() {
  const [searchVal, setSearchVal] = useState("");
  const [glossaryData, setGlossaryData] = useState([]);
  const { strings, theme } = useSelector((state) => state.platformSetting);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectFun = () => {
    // navigate("128721hd81287");
  };

  useEffect(() => {
    dispatch(
      getGlossaryAction((e) => {
        if (e.status == 200) {
          setGlossaryData(e.data.glossary);
        }
      })
    );
  }, []);

  console.log("glossaryData", glossaryData);
  return (
    <GlossaryBox theme={theme}>
      <h4>{strings.label.glossary_section}</h4>
      <SearchField
        value={searchVal}
        handleChange={(e) => {
          setSearchVal(e);
        }}
      />
      <StyleFormBox>
        {glossaryData?.map((item, index) => (
          <NavLink to={`/glossary/${item.id}`} className="block w-full text-left m-2" key={item.id}>{item.title}</NavLink>
        ))}
      </StyleFormBox>
      <div className="glossary_bx">
        <h4 className="title">A</h4>
        <StyleFormBox>
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Aadhaar: What is Aadhar Card, Eligibility, Benefits
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Absorption Costing
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accounting Cycle
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accounting Principles
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accrual Accounting
          </h5>
        </StyleFormBox>
      </div>
      <div className="glossary_bx">
        <h4 className="title">A</h4>
        <StyleFormBox>
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Aadhaar: What is Aadhar Card, Eligibility, Benefits
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Absorption Costing
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accounting Cycle
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accounting Principles
          </h5>
          <hr />
          <h5
            onClick={() => {
              redirectFun();
            }}
          >
            Accrual Accounting
          </h5>
        </StyleFormBox>
      </div>
    </GlossaryBox>
  );
}

const GlossaryBox = styled.div`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin: 30px auto 0px;
  border-radius: 10px;
  max-width: 1000px;
  .tab_section {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    > button {
      display: block;
      text-align: left;
      border: 1px solid ${(p) => p.theme.border_clr};
      padding: 10px;
      border-radius: 10px;
      margin: 10px 0px;
      transition: all 0.5s;
      &:hover {
        background-color: ${(p) => p.theme.tertiary_bg};
      }
      &.active {
        background-color: ${(p) => p.theme.tertiary_bg};
      }
    }
  }
  .glossary_bx {
    margin: 14px 0px;
    h4 {
      padding: 0px 10px;
      font-size: 26px;
    }
    h5 {
      cursor: pointer;
    }
    hr {
      margin: 10px 0px;
    }
  }
`;
