import React, { useState } from 'react'
import SelectOption from '../../styledComponent/Inputs/SelectOption'
import { useSelector } from 'react-redux';
import InputField from '../../styledComponent/Inputs/InputField';
import PrimaryButton from '../../styledComponent/Button/PrimaryButton';
import StyleFormBox from '../../styledComponent/Form/StyleFormBox';
import { FaAngleRight } from "react-icons/fa";
import styled from 'styled-components';
import ConfirmationBox from '../DialogBox/ConfirmationBox';
import { FaCheck } from "react-icons/fa";
import SecandaryButton from '../../styledComponent/Button/SecandaryButton';
import { IoClose } from "react-icons/io5";
export default function MakePayment({handleClick}) {
  const [verifyBox , setVerifyBox] = useState(false)
  const [onReject , setOnReject] = useState(false)
  const { theme ,strings} = useSelector((state) => state.platformSetting);
  return (
    <Root className="container_box flex gap-4" theme={theme}>
        <StyleFormBox>
        <div className="content">
        <label>{strings.label.select_bank}</label>
        <SelectOption
            options={[
              strings.label.select_bank,
              strings.label.select_bank,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />

           <div className="flex justify-between gap-4">
          <div className="flex-1"> <label>{strings.label.account_number}</label>
           <InputField
              placeholder={strings.label.account_number}
              error={""}
              value={""}
              handleChange={() => {}}
            /></div>
          <div className="flex-1"> <label>{strings.label.ifsc_code}</label>
           <InputField
              placeholder={strings.label.ifsc_code}
              error={""}
              value={""}
              handleChange={() => {}}
            /></div>
           </div>

          <label>{strings.label.account_name}</label>
           <InputField
              placeholder={strings.label.account_name}
              error={""}
              value={""}
              handleChange={() => {}}
            />

         <label>{strings.label.account_type}</label>
           <SelectOption
            options={[
              strings.label.account_type,
              strings.label.account_type,
            ]}
            error={""}
            value={""}
            handleChange={() => {}}
          />
          <PrimaryButton title={strings.button.continue} addClass={'mt-5'} handleClick={()=>{setVerifyBox(true)}}/>
        </div>

        </StyleFormBox>
       <section>
       <StyleFormBox>
        <h4>{strings.document.cards}</h4>
        <hr/>
        <div className='card_box my-4 flex items-center gap-3 cursor-pointer'>
              <img src='/images/visa_icon.png'/>
              <div>
                <h5>ICIC Visa Card</h5>
                <p>****** 1508 | Secured</p>
              </div>
              <button className='ml-auto'><FaAngleRight /></button>
          </div>
        <div className='card_box my-4 flex items-center gap-3 cursor-pointer'>
              <img src='/images/visa_icon.png'/>
              <div>
                <h5>ICIC Visa Card</h5>
                <p>****** 1508 | Secured</p>
              </div>
              <button className='ml-auto'><FaAngleRight /></button>
          </div>
        </StyleFormBox>
        <StyleFormBox>
        <h4>{strings.document.upi}</h4>
        <hr/>
        <div className='card_box my-4 flex items-center gap-3 cursor-pointer'>
              <img src='/images/gpay_icon.png'/>
              <div>
                <h5>Google Pay UPI</h5>
              </div>
              <button className='ml-auto'><FaAngleRight /></button>
          </div>
        <div className='card_box my-4 flex items-center gap-3 cursor-pointer'>
              <img src='/images/gpay_icon.png'/>
              <div>
                <h5>Google Pay UPI</h5>
              </div>
              <button className='ml-auto'><FaAngleRight /></button>
          </div>

        </StyleFormBox>
       </section>
 {/* Dialog Box on Success */}
 <ConfirmationBox
        open={verifyBox}
        handleClose={() => {
          setVerifyBox(false)
        }}
      >
        <div className="text-center">
          <div className="icon text-3xl bg-green-500 w-auto mx-auto my-2 mb-4 rounded-full p-4 inline-block" style={{color:theme.secandary_txt}}>
            <FaCheck />
          </div>
          <h4>{strings.profile.payment_successful}</h4>
          <p> Your e-Filling is successful. Income Tax Dept. has generated the below acknowledgement number asa confirmation of your return submission.</p>
          <hr className='mt-4'/> 
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>Filled PAN:</h5><h4>XYZ0072CXIO</h4>
          </div>
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>Filled on:</h5><h4>20/03 /23</h4>
          </div>
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>Acknowledgement:</h5><h4>1265894785</h4>
          </div>
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>ITR Type:</h5><h4>ITR 1</h4>
          </div>
          <div className="flex items-center gap-4 mt-4">
            <SecandaryButton
              title={strings.button.e_verify}
              handleClick={() => {setOnReject(true); setVerifyBox(false)}}
            />
            <PrimaryButton title={strings.button.download} handleClick={()=>{handleClick('e_verify_return')}}/>
          </div>
        </div>
      </ConfirmationBox>
      {/* Dialog Box  */}

 {/* Dialog Box on Failed */}
 <ConfirmationBox
        open={onReject}
        handleClose={() => {
          setOnReject(false)
        }}
      >
        <div className="text-center">
          <div className="icon text-3xl bg-red-500 w-auto mx-auto my-2 mb-4 rounded-full p-4 inline-block" style={{color:theme.secandary_txt}}>
          <IoClose />
          </div>
          <h4>Payment Unsuccessful</h4>
          <p>Your payment was declined. Please try again or use a different payment method.</p>
          <hr className='mt-4'/> 
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>Filled PAN:</h5><h4>XYZ0072CXIO</h4>
          </div>
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>Acknowledgement:</h5><h4>1265894785</h4>
          </div>
          <div className='flex items-center justify-between gap-4 my-4'>
            <h5>ITR Type:</h5><h4>ITR 1</h4>
          </div>
        </div>
      </ConfirmationBox>
      {/* Dialog Box  */}

    </Root>
  )
}
const Root = styled.div`
    align-items: start;
>section{
  flex:1;
}
.card_box{
  border: 1px solid ${(p) => p.theme.border_clr};
  border-radius: 6px;
  padding: 8px;
}
`