import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import DateField from "../../styledComponent/Inputs/DateField";
import { userRegistrationAction } from "../../redux/profile/action";
import { Formik } from "formik";
import * as Yup from "yup";

import LoadingBox from "../DialogBox/LoadingBox";
import Input from "../../styledComponent/Inputs/Input";
import { toast } from "react-toastify";
export default function CreateUser({ handleClick }) {
  const [loading, setLoading] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();

  const handleCallback = (e) => {
    if(e.status == 201){
      handleClick(e.data.data)
    }
    setLoading(false);
  };

  const handleSubmitForm = (data) => {
    const { confirmPassword, ...dataObj } = data;
    const formData = new FormData();
    for (const key in dataObj) {
      formData.append(key, data[key]);
    }
    setLoading(true);
    dispatch(userRegistrationAction(formData, handleCallback));
  };

  const validationSchema = Yup.object().shape({
    userFirstName: Yup.string().required(strings.validation_message.first_name),
    userLastName: Yup.string().required(strings.validation_message.last_name),
    userEmail: Yup.string()
      .email(strings.validation_message.invalid_email)
      .required(strings.validation_message.email),
    phoneNumber: Yup.string().required(strings.validation_message.phone_number),
    password: Yup.string().required(strings.validation_message.password),
    confirmPassword: Yup.string()
      .required(strings.validation_message.confirm_password)
      .oneOf(
        [Yup.ref("password"), null],
        strings.validation_message.password_must_match
      ),
  });

  return (
    <Root theme={theme}>
      <Formik
        initialValues={{
          userFirstName: "",
          userLastName: "",
          userEmail: "",
          phoneNumber: "",
          dob: "",
          password: "",
          confirmPassword: "",
          companyId: 1,
          avatar: "",
          userCoverImage: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="image_select w-40 h-40 rounded-full block mx-auto mt-[-40px] border-6 overflow-hidden relative">
                <input
                  type="file"
                  name={"avatar"}
                  className="h-full w-full cursor-pointer opacity-0 relative z-20"
                  onChange={(e) => {
                    setFieldValue("avatar", e.currentTarget.files[0]);
                  }}
                />
                {values.avatar ? (
                  <img
                    src={URL.createObjectURL(values.avatar)}
                    className="absolute top-0 left-0 h-full w-full object-cover"
                  />
                ) : (
                  <span
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-7xl z-10"
                    style={{ color: theme.primary_bg }}
                  >
                    <FaUser />
                  </span>
                )}
              </div>

              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.first_name}</label>
                  <Input
                    name={"userFirstName"}
                    placeholder={strings.label.first_name}
                    error={errors.userFirstName}
                    value={values.userFirstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.last_name}</label>
                  <Input
                    name={"userLastName"}
                    placeholder={strings.label.last_name}
                    error={errors.userLastName}
                    value={values.userLastName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.date_of_birth} </label>
                  <Input
                    name={"dob"}
                    type={"date"}
                    error={errors.dob}
                    value={values.dob}
                    format={"YYYY-MM-DD"}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label>{strings.label.phone_number}</label>
                  <Input
                    inputType={"phone"}
                    name={"phoneNumber"}
                    error={errors.phoneNumber}
                    value={values.phoneNumber}
                    onChange={(phoneNumber) =>
                      handleChange({
                        target: { name: "phoneNumber", value: phoneNumber },
                      })
                    }
                    
                  />
                </div>
              </div>

              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.email}</label>
                  <Input
                    name={"userEmail"}
                    placeholder={strings.label.email}
                    error={errors.userEmail}
                    value={values.userEmail}
                    onChange={handleChange}
                  />
                </div>

                <div className="w-full">
                  <label>{strings.label.password}</label>
                  <Input
                    inputType={"password"}
                    name={"password"}
                    placeholder={strings.label.password}
                    error={errors.password}
                    value={values.password}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label>{strings.label.confirm_password}</label>
                  <Input
                    inputType={"password"}
                    name={"confirmPassword"}
                    placeholder={strings.label.confirm_password}
                    error={errors.confirmPassword}
                    value={values.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full"></div>
              </div>
            </div>
            <button className="primary_button mt-5" type="submit">
              {strings.button.save_and_continue}
            </button>
          </form>
        )}
      </Formik>
      <LoadingBox open={loading} />
    </Root>
  );
}
const Root = styled.section`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  .image_select {
    background-color: ${(p) => p.theme.secandary_bg};
    border: 8px solid ${(p) => p.theme.tertiary_bg};
  }
`;
