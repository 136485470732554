import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
export default function AboutUs() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const navigate = useNavigate()
  return (
    <Root theme={theme}>
      <img className="bg_img" src="/Images/about_bg.png" />
      <div className="container_section">
        <h4 className="text-4xl my-4">Why <span>SamosaTax</span> ?</h4>
        <p className="text-xl why-tax">
          Welcome to our place, your one-stop solution for all your income tax
          filing needs. 
         At SamosaTax, we understand the complexities and
          problems that come with filing your income tax returns (ITR). That's
          why we've developed a user-friendly platform that allows you to file
          your ITR at your own convenience, with the option to access expert
          assistance if needed. Our mission is to simplify the process of filing
          ITR for individuals. We believe that everyone should have access to
          reliable and affordable tax filing services, which is why we offer our
          services at an affordable range without compromising on quality.
        </p>
        {/* <p className="text-xl why-tax">
          Whether you're a seasoned taxpayer or new to the process, our platform
          serves all your needs. With step-by-step guidance and intuitive
          interfaces, we make filing your ITR an Ideal experience. And if you
          ever find yourself in need of assistance or have any queries, our team
          of expert professionals is always ready to help.
        </p> */}
        <button onClick={() => navigate("/about-us")}>READ MORE</button>
      </div>
    </Root>
  );
}
const Root = styled.section`
  position: relative;
  margin-top: 60px;
  .container_section {
    position: absolute;
    width: 94%;
    max-width: 1000px;
    background: ${(p) => addOpacity(p.theme.secandary_bg, 0.6)};
    backdrop-filter: blur(15px);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    top: -30px;
    > button {
      margin-top: 20px;
      background-color: ${(p) => p.theme.primary_bg};
      padding: 6px 30px;
      border-radius: 50px;
      font-weight: 600;
      color: ${(p) => p.theme.secandary_txt};
    }
    h4 {
      font-size:42px;
      span {
        color:#FFA53B;
      }
    }
  }
  .bg_img {
    @media (max-width:1024px){
      min-height: 500px;
      object-fit: cover;
    }
  }

  .why-tax {
    @media (max-width: 420px) and (min-width: 342px) {
      font-size: 12px;
      line-height: 15px;
    }
    @media (max-width: 576px) and (min-width: 421px) {
      font-size: 14px;
      line-height: 16px;
    }
  }


`;
