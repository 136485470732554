import React, { useEffect, useState } from "react";
import LoadingBox from "../../../DialogBox/LoadingBox";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import {
  editFilingDueDateAction,
  saveFilingDueDateAction,
} from "../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../utils/helpers";
import { toast } from "react-toastify";

export default function FilingDueDates({
  editData,
  AssessmentYear,
  handleCallback,
  clearRecordCallback,
}) {
  const [formData, setFormData] = useState({
    itrId: null,
    filingDueDateLoss: [],
  });
  const [loader, setLoader] = useState(false);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  // Schema for validation
  const validationSchema = Yup.object().shape({
    filingDueDateLoss: Yup.array().of(
      Yup.object().shape({
        dueData: Yup.string().required(strings.validation_message.required),
        filingDate: Yup.string().required(strings.validation_message.required),
      })
    ),
  });

  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editFilingDueDateAction : saveFilingDueDateAction;
    dispatch(
      action(data, (e) => {
        console.log("e", e);
        if (e.status === 200) {
          handleCallback(e);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const updateArray = (arr, newAry) => {
    if (newAry.length > 0) {
      return (
        newAry?.map((item) => {
          return {
            id: item.id,
            assessmentYear: item.assessmentYear,
            dueData: item.dueData || "",
            filingDate: item.filingDate || "",
          };
        }) || []
      );
    } else {
      return (
        arr?.map((item) => {
          return {
            assessmentYear: item.value,
            dueData: "",
            filingDate: "",
          };
        }) || []
      );
    }
  };

  useEffect(() => {
    if (AssessmentYear?.length > 0) {
      setLoader(true);
      let data = editData.length > 0 ? editData : [];
      setFormData({
        type: editData.length > 0 ? "edit" : "add",
        itrId: ItrData?.id,
        filingDueDateLoss: updateArray(AssessmentYear, data),
      });
      setLoader(false);
    }
  }, [editData, AssessmentYear, ItrData]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <>
            {editData.length > 0 ? (
              <button
                onClick={() => clearRecordCallback()}
                className="add_item_button block ml-auto"
              >
                Clear All record
              </button>
            ) : (
              ""
            )}

            {values.filingDueDateLoss?.map((item, index) => (
              <StyleFormBox key={item.assessmentYear}>
                <h3>Assessment Year {item.assessmentYear}</h3>
                <div className="flex_input mt-2">
                  <label>{strings.label.due_date}</label>
                  <Input
                    inputType="date"
                    name={`filingDueDateLoss.${index}.dueData`}
                    value={item.dueData}
                    error={errors.filingDueDateLoss?.[index]?.dueData}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input mt-2">
                  <label>{strings.label.filing_date}</label>
                  <Input
                    inputType="date"
                    name={`filingDueDateLoss.${index}.filingDate`}
                    value={item.filingDate}
                    error={errors.filingDueDateLoss?.[index]?.filingDate}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
            ))}

            <button
              type="submit"
              className="primary_button mt-4"
              onClick={handleSubmit}
            >
              {strings.button.save_and_close}
            </button>
          </>
        )}
      </Formik>
    </>
  );
}
