import { CONTACT_US, CREATE_TICKET, FORGOT_PASSWORD, GET_ALL_DATA,ITR_STATUS, GET_FAQ, GET_GLOSSARY, GET_GLOSSARY_ID, GET_QUESTIONS, GET_SERVICE_LIST, GET_TICKET_TAGS, RESET_PASSWORD, SEND_VERIFY_OTP, SET_USER_DATA, SUBMIT_QUESTIONS, USER_LOGIN, USER_REGISTRATION, VERIFY_OTP, VERIFY_OTP_FORGOT_PASSWORD, LOGOUT_USER, UPDATE_USER, SOCIAL_LOGIN } from "../constant";

// Action creator
export const fetchUserData = () => ({
  type: GET_ALL_DATA
});

export const userRegistrationAction = (data,handleCallback)=>({
  type:USER_REGISTRATION,
  payload: { data, handleCallback },
})
// Update User profile
export const updateUserAction = (data,handleCallback)=>({
  type:UPDATE_USER,
  payload: { data, handleCallback },
})

export const userLoginAction = (data , handleCallback)=>({
  type:USER_LOGIN,
  payload: { data, handleCallback },
})

export const socialLoginAction = (data , handleCallback)=>({
  type:SOCIAL_LOGIN,
  payload: { data, handleCallback },
})

export const userLoginDataAction = (userData)=>({
  type:SET_USER_DATA,
  payload: userData,
})

// Get Verification OTP
export const sendVerificationOtpAction = (email,handleCallback)=>({
  type:SEND_VERIFY_OTP,
  payload:{ email,handleCallback}
})

export const verifyOtpAction = (data,handleCallback) =>({
  type:VERIFY_OTP,
  payload:{ data,handleCallback}
})


// Forgot Password
export const forgotPasswordAction = (data,handleCallback)=>({
  type:FORGOT_PASSWORD,
  payload:{ data,handleCallback}
})

export const verifyOtpForgotPasswordAction = (data,handleCallback)=>({
  type:VERIFY_OTP_FORGOT_PASSWORD,
  payload:{ data,handleCallback}
})

export const resetPasswordAction = (data,handleCallback)=>({
  type:RESET_PASSWORD,
  payload:{ data,handleCallback}
})

export const getGlossaryAction = (handleCallback)=>({
  type:GET_GLOSSARY,
  payload:{handleCallback}
})

export const getGlossaryByIdAction = (id,handleCallback)=>({
  type:GET_GLOSSARY_ID,
  payload:{id,handleCallback}
})

export const getFaqAction = (handleCallback)=>({
  type:GET_FAQ,
  payload:{handleCallback}
})

export const getQuestionsAction =(handleCallback)=>({
  type:GET_QUESTIONS,
  payload:{handleCallback}
})
export const submitQuestionAction = (data,handleCallback)=>({
  type:SUBMIT_QUESTIONS,
  payload:{data,handleCallback}
})

export const getTicketTagAction = (parm,handleCallback)=>({
  type:GET_TICKET_TAGS,
  payload:{parm,handleCallback}
})

export const createTicketAction = (data,handleCallback)=>({
  type:CREATE_TICKET,
  payload:{data,handleCallback}
})

export const getServiceListAction = (handleCallback)=>({
  type:GET_SERVICE_LIST,
  payload:{handleCallback}
})

export const contactUsAction = (data,handleCallback)=>({
  type:CONTACT_US,
  payload:{data,handleCallback}
})

export const getItrStatusAction = (handleCallback)=>({
  type:ITR_STATUS,
  payload:{handleCallback}
})

// Logout User 

export const userLogoutAction = ()=>({
  type:LOGOUT_USER,
})