import { GET_ALL_DATA, GET_ALL_STATES, GET_CATEGORIES, GET_CITIES_BY_ID, GET_LANGUAGES, GET_USER_THEME, SET_ALL_STATES, SET_CATEGORIES, SET_PLATFORM_THEME } from "../constant";

// Action creator
export const fetchUserData = () => ({
  type: GET_ALL_DATA
});

export const changeTheme = (theme,themeMode,activeTheme)=>({
  type: SET_PLATFORM_THEME,
  payload: [theme,themeMode,activeTheme],
  
})

export const getUserThemeAction = (handleCallback)=>({
  type:GET_USER_THEME,
  payload:[handleCallback]
})

export const getAllStatesAction = ()=>({
  type:GET_ALL_STATES,
})

export const getCitiesByIdAction = (id,handleCallback)=>({
  type:GET_CITIES_BY_ID,
  payload:{ id, handleCallback },
})

export const getCategoriesAction = ()=>({
  type:GET_CATEGORIES,
})

export const getLanguagesAction = ({handleCallback})=>({
  type:GET_LANGUAGES,
  payload:{handleCallback}
})


// export const setAllStateAction = (data)=>({
// type:SET_ALL_STATES,
// payload:data
// })
// export const setAllStates = ()=>{

// }
