import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { addOpacity } from '../../utils/helpers';
export default function TicketsBox({data}) {
    const [ticket , setTicket] = useState({})
    const { strings, theme } = useSelector((s) => s.platformSetting);
    useEffect(()=>{
        setTicket(data)
    },[data])
  return (
    <Root theme={theme}>
    <NavLink to={`/ticket-chat/${ticket.ticketNumber}`} >
      <div className="flex items-center justify-between">
        <h6>
          {strings.profile.ticket_number} : {ticket.ticketNumber}
        </h6>
        <h5 style={{ color: "#F62525" }}>Closed</h5>
      </div>
      <h6>
        {strings.profile.select_service} : <span>{ticket.service}</span>
      </h6>
      <h6>
        {strings.profile.date} : <span>{ticket.date}</span>{" "}
      </h6>
      <h6>
        {strings.label.description} : <span>{ticket.description}</span>
      </h6>
     </NavLink>
    </Root>
  )
}
const Root = styled.div`
    background-color: ${(p) => addOpacity(p.theme.tertiary_bg, 0.5)};
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    margin:10px 0px;
    &:hover{
      background-color: ${(p) => addOpacity(p.theme.tertiary_bg, 1)};
    }
    > * {
      margin: 6px 0px;
    }
    span {
      font-weight: 400;
    }
`