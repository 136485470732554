import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import { useSelector } from "react-redux";
export default function AccordianBox({ title, description, children, initial = true  }) {
    const [dropdownTab, setDropdownTab] = useState(initial);
    const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
      <div className="box_heading">
        <div>
          <h4 style={{color:theme.primary_txt}}>{title}</h4>
          <p style={{color:theme.primary_txt}}>{description || ''}</p>
        </div>
          <button onClick={() => {setDropdownTab(!dropdownTab)}} style={{color:theme.primary_txt}} className={dropdownTab == true ?'active':''}> <FaAngleDown/> </button>
      </div>
      {dropdownTab && <div className="box_section">{children}</div>}
      
    </Root>
  );
}
const Root = styled.section`
  margin-top: 8px;
  background-color: ${(p) => p.theme.secandary_bg};
  color:${(p) => p.theme.primary_txt};
  border:1px solid ${(p) => p.theme.border_clr};
  border-radius: 10px;
  .box_heading {
    display: flex;
    gap:18px;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    button {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
      border-radius: 50%;
      padding: 6px;
      border: none;
      font-size: 16px;
      transition:all 0.5s;
      &.active {
      transform: rotate(180deg);
    }
    }
  }
  .box_section{
    padding: 18px;
    border-top:1px solid ${(p) => p.theme.border_clr};
  }
`;
