import React, { useState } from 'react'
import { IoSearch } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { addOpacity } from '../../utils/helpers';

export default function SearchBar() {
    const [searchActivate , setSearchActivate] = useState(false)
    const {strings,theme} = useSelector(s=>s.platformSetting)
  return (
    <div >
        <button onClick={()=>setSearchActivate(true)} className='block p-2 rounded'  style={{ background: addOpacity(theme.primary_bg, 0.8) }}><IoSearch /></button>
        {/* <div></div> */}
    </div>
  )
}
