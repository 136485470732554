import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { MdOutlineEventNote } from "react-icons/md";
import { addOpacity } from "../../utils/helpers";
export default function TaxFilling() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="container_box">
        <img src="/images/stars.png" className="m-auto" />
        <h1 className="text-center text-4xl my-4 font-bold tax_filling ">
          Best income <span>tax filing </span>website in India
        </h1>
        <p className="text-center text-lg">
          We have changed the way people do tax filing in India. We don’t just
          get your tax return filed within minutes but get you maximum refunds
          in shortest time.
        </p>
        <div className="flex justify-between service_box">
          <div>
            <span>
              <img src="/Images/rating.png" />
            </span>
            <h4 className="font-bold text-3xl">4.5</h4>
            <h5>Star Service Rating</h5>
          </div>
          <div>
            <span>
              <img src="/Images/top25.png" />
            </span>
            <h4 className="font-bold text-3xl">Top 25</h4>
            <h5>Emerging Startup of Asia</h5>
          </div>
          <div>
            <span>
              <img src="/Images/trusted-user.png" />
            </span>
            <h4 className="font-bold text-3xl">1+ Million</h4>
            <h5>Trusted Users</h5>
          </div>
          <div>
            <span>
              <img src="/Images/authorised.png" />
            </span>
            <h4 className="font-bold text-3xl">Authorized</h4>
            <h5>by Income Tax Department</h5>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 50px 10px;
  background-color: ${(p) => p.theme.tertiary_bg};
  color: ${(p) => addOpacity(p.theme.primary_txt, 0.9)};
  .service_box {
    margin: 30px 0px;
    @media (min-width:320px) and (max-width:1024px)
    {
      display:grid;
      justify-content:center;
      text-align:center;
    }
    span {
      font-size: 30px;
      color: ${(p) => p.theme.primary_bg};
      padding: 8px 0px;
      display: block;
      @media (min-width:320px) and (max-width:991px)
      {
        display:flex;
        justify-content:center;
      }
    
    }
    h5 {
      font-size: 18px;
      font-weight: 600;
    }
    >div {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 30px 15px;
      border-radius: 10px;
      width:250px;
      @media (max-width:991px)
      {
        margin-bottom:20px;
      }
    }
  }
  h1.tax_filling {
    font-size:42px;
    span {
      color:#FFA53B;
    }
  }
`;
