import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export default function Details() {
  const {theme } = useSelector((s) => s.platformSetting);
  return (
    <DetailSection theme={theme}>
      <div className="tax-details">
        <div className="container_box">
          <div className="tax-details-heading text-center">
            <h2>
              Get <span>Instant Tax</span> Help{" "}
            </h2>
          </div>
          <div className="tax-details-content flex justify-center gap-4">
            <div className="tax-details-first">
              <div className="details-icon flex justify-center">
                <img src="Images/rating (1).png"></img>
              </div>
              <div className="details-text">
                <h5>
                  4.8 Google<br></br>Rating
                </h5>
              </div>
            </div>
            <div className="tax-details-first">
              <div className="details-icon flex justify-center">
                <img src="Images/rating (1).png"></img>
              </div>
              <div className="details-text">
                <h5>
                  Trusted by 1+ Million <br></br>Users
                </h5>
              </div>
            </div>
            <div className="tax-details-first">
              <div className="details-icon flex justify-center">
                <img src="Images/rating (1).png"></img>
              </div>
              <div className="details-text">
                <h5>
                  Secure and <br></br>safe
                </h5>
              </div>
            </div>
            <div className="tax-details-first">
              <div className="details-icon flex justify-center">
                <img src="Images/rating (1).png"></img>
              </div>
              <div className="details-text">
                <h5>
                  Authorized by Income <br></br>Tax Department
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tax-planning">
        <div className="container_box">
          <div className="tax-planning-heading">
            <h2>
              We Have Solutions for All <span>Your Tax</span> Queries
            </h2>
          </div>
          <div className="tax-planning-main flex justify-center gap-4">
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>File Your Return</h5>
              </div>
            </div>
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>CA Assisted Tax Filing</h5>
              </div>
            </div>
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>Tax Planning Optimiser</h5>
              </div>
            </div>
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>Income Tax Calculator</h5>
              </div>
            </div>
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>Income Tax Notices</h5>
              </div>
            </div>
            <div className="tax-planning-first">
              <div className="tax-planning-content">
                <h5>Income Tax Refund</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-choose-us-main">
        <div className="container_box">
          <div className="choose-us-heading">
            <h2>
              Why <span>Choose</span> Us!!
            </h2>
          </div>
          <div className="choose-btm-main flex justify-center gap-4">
            <div className="choose-btm-left">
              <div className="choose-left-heading">
                <h4>Say ‘NO’ to</h4>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Tax Blunders</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Stress</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Confusion</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Running around CA offices</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Notices from Income Tax Department</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Spending hours figuring out taxes</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Penalties and legal complications</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Errors</p>
                </div>
              </div>
            </div>
            <div className="choose-btm-right">
              <div className="choose-left-heading">
                <h4>Say ‘Yes’ to</h4>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Personalized Experience</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Utilize benefits of Tax Expertise</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>End-to-end Solutions</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>10+ years of Industry Experience</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Saving taxes legally</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Compliance</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Peace of mind</p>
                </div>
              </div>
              <div className="choose-left-btm-content flex items-center gap-4">
                <div className="choose-left-img">
                  <img src="Images/stars-removebg-preview.png"></img>
                </div>
                <div className="choose-left-content text-white">
                  <p>Errors</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DetailSection>
  );
}
const DetailSection = styled.section`
  .tax-details {
    background-image: url(../Images/hm-banner-3.jpg);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 120px 0;
    width: 100%;
    .tax-details-heading {
      margin-bottom: 50px;
      h2 {
        font-weight: 700;
        font-size: 42px;
        color: #fff;
        span {
          color: #ffa53b;
        }
      }
    }
    .tax-details-content {
      .tax-details-first {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 25px 20px;
        background: ${(p) => p.theme.secandary_bg};
        width: 250px;
        border-radius: 10px;
        .details-text {
          h5 {
            text-align: center;
          }
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .tax-planning {
    width: 100%;
    margin-top: 50px;
    .tax-planning-heading {
      margin-bottom: 50px;
      h2 {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        span {
          color: #ffa53b;
        }
      }
    }
    .tax-planning-main {
      .tax-planning-first {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 10px;
        padding: 10px 20px;
        .tax-planning-content {
          h5 {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
          }
        }
      }
      .tax-planning-first:hover {
        background: #ffa53b;
        color: #fff;
      }
      @media (max-width: 768px) {
        display: grid;
      }
    }
  }

  .why-choose-us-main {
    width: 100%;
    .choose-us-heading {
      margin-bottom: 50px;
      h2 {
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        span {
          color: #ffa53b;
        }
      }
    }

    .choose-btm-main {
      .choose-btm-left {
        background: #0c4276;
        padding: 50px 20px;
        border-radius: 10px;
        width: 50%;

        .choose-left-heading {
          h4 {
            color: #fff;
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 30px;
          }
        }

        .choose-left-btm-content {
          .choose-left-img {
            img {
              width: 25px;
            }
          }
          .choose-left-content {
            p {
              font-size: 24px;
            }
          }
        }
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      .choose-btm-right {
        background: #ffa53b;
        padding: 50px 20px;
        border-radius: 10px;
        width: 50%;

        .choose-left-heading {
          h4 {
            color: #fff;
            font-size: 36px;
            font-weight: 600;
            margin-bottom: 30px;
          }
        }

        .choose-left-btm-content {
          .choose-left-img {
            img {
              width: 25px;
            }
          }
          .choose-left-content {
            p {
              font-size: 24px;
            }
          }
        }
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;
