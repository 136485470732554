import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useSelector, useDispatch } from "react-redux";
import Input from "../../../../styledComponent/Inputs/Input";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";
import Select from "../../../../styledComponent/Inputs/Select";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import { RiDeleteBin6Fill } from "react-icons/ri";
export default function SelfOccupiedProperty({
  handleCallback,
  formsData,
  financialYear,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const { ItrData } = useSelector((s) => s.itr);
  const [citiesList, setCitiesList] = useState([]);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const [activeState, setActiveState] = useState(1);

  const dispatch = useDispatch();
  const validationSchema1 = Yup.object().shape({
    interestPaidOnHL: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    unrealizedRent: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
  });

  const validationSchema2 = Yup.object().shape({
    interestPaidOnHL: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),
    unrealizedRent: Yup.number().moreThan(
      0,
      strings.validation_message.amount_greater_than_0
    ),

    // intPaidPreConstPeriod: Yup.string().required(
    //   strings.validation_message.required
    // ),
    // annualRentReceived: Yup.string().required(
    //   strings.validation_message.required
    // ),

    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required),
    }),
    propertyCoOwners: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        pan: Yup.string().required("PAN is required"),
        sharePercentage: Yup.number()
          .required("Share percentage is required")
          .min(0)
          .max(100),
      })
    ),
  });

  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };
  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    setFormData({
      propertyType: 100,
      interestPaidOnHL: formsData?.interestPaidOnHL || "",
      unrealizedRent: formsData?.unrealizedRent || "",
      ...(ItrData.form == "ITR_2" || ItrData.form == "ITR_3"
        ? {
            intPaidPreConstAmount: formsData?.intPaidPreConstAmount || 0,
            intPaidPreConstPeriod:
              formsData?.intPaidPreConstPeriod || "2020-2021",
            annualRentReceived: formsData?.annualRentReceived || 0,
            address: {
              flatDoorNo: formsData?.address?.flatDoorNo || "",
              premiseName: formsData?.address?.premiseName || "",
              street: formsData?.address?.street || "",
              area: formsData?.address?.area || "",
              city: formsData?.address?.city || "",
              state: formsData?.address?.state || "",
              country: 101,
              pinCode: formsData?.address?.pinCode || "",
            },
            propertyCoOwners: formsData?.propertyCoOwners || [],
          }
        : {}),
    });
    setLoader(false);
  }, [formsData]);

  return (
    <div>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={
          ItrData.form == "ITR_2" || ItrData.form == "ITR_3"
            ? validationSchema2
            : validationSchema1
        }
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.income.interest_paid_housing_loan_for_this}</h4>
              <div className="flex_input my-2">
                <label>
                  {strings.income.interest_paid_on_loan_for_this_house_property}
                </label>
                <Input
                  name={"interestPaidOnHL"}
                  inputType={"currency"}
                  error={errors.interestPaidOnHL}
                  value={values.interestPaidOnHL}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4>{strings.income.unrealized_rent}</h4>
              <div className="flex_input my-2">
                <label>{strings.income.arrears_unrealized_rent_received}</label>
                <Input
                  name={"unrealizedRent"}
                  inputType={"currency"}
                  error={errors.unrealizedRent}
                  value={values.unrealizedRent}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            {ItrData.form == "ITR_2" || ItrData.form == "ITR_3" ? (
              <>
                <StyleFormBox>
                  <h4>
                    {strings.income.interest_paid_during_the_pre_construction}
                  </h4>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .financial_year_previous_complete_house_property
                      }
                    </label>
                    <Select
                      name={"intPaidPreConstPeriod"}
                      value={values.intPaidPreConstPeriod}
                      onChange={handleChange}
                      error={errors.intPaidPreConstPeriod}
                    >
                      <option value={""}>{strings.label.choose_option}</option>
                      {financialYear?.map((i, ix) => (
                        <option value={i.value} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .total_interest_amount_paid_during_pre_construction
                      }
                    </label>
                    <Input
                      type={"number"}
                      inputType={"currency"}
                      error={errors.intPaidPreConstAmount}
                      name={"intPaidPreConstAmount"}
                      value={values.intPaidPreConstAmount}
                      onChange={handleChange}
                    />
                  </div>
                </StyleFormBox>

                <StyleFormBox>
                  <h4>{strings.income.your_house_address}</h4>
                  <p>
                    {
                      strings.income
                        .you_can_provide_either_your_current_address_or_permanent_address
                    }
                  </p>

                  <div className="flex_input my-2">
                    <label>
                      {strings.address.flat +
                        " / " +
                        strings.address.door_no +
                        " / " +
                        strings.address.block_no}
                    </label>
                    <Input
                      name={"address.flatDoorNo"}
                      error={errors.address && errors.address.flatDoorNo}
                      value={values.address && values.address.flatDoorNo}
                      placeholder={strings.address.flat}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.premise_name}</label>
                    <Input
                      name={"address.premiseName"}
                      value={values.address && values.address.premiseName}
                      error={errors.address && errors.address.premiseName}
                      placeholder={strings.address.premise_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {strings.address.road + " / " + strings.address.street}
                    </label>
                    <Input
                      name={"address.street"}
                      value={values.address && values.address.street}
                      error={errors.address && errors.address.street}
                      placeholder={strings.address.road}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.area_locality}</label>
                    <Input
                      name={"address.area"}
                      value={values.address && values.address.area}
                      error={errors.address && errors.address.area}
                      placeholder={strings.address.road}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.pincode}</label>
                    <Input
                      name={"address.pinCode"}
                      type={"number"}
                      error={errors.address && errors.address.pinCode}
                      value={values.address && values.address.pinCode}
                      placeholder={strings.address.pincode}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.state}</label>
                    <Select
                      name={"address.state"}
                      error={errors.address && errors.address.state}
                      value={values.address && values.address.state}
                      onChange={(e) => {
                        handleChange(e);
                        setActiveState(e.target.value);
                      }}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {states?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div className="flex_input my-2">
                    <label>{strings.address.city}</label>

                    <Select
                      name={"address.city"}
                      error={errors.address && errors.address.city}
                      value={values.address && values.address.city}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {citiesList?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </StyleFormBox>
                <FieldArray name="propertyCoOwners">
                  {({ insert, remove, push }) => (
                    <StyleFormBox>
                      <h4>{strings.address.co_owners_details}</h4>
                      <p>{strings.address.in_case_this_property_is_jointly}</p>
                      {values.propertyCoOwners?.map((coOwner, index) => (
                        <div className="flex_input my-2" key={index}>
                          <div>
                            <label>{strings.address.name_of_co_owner}</label>
                            <Input
                              name={`propertyCoOwners.${index}.name`}
                              value={coOwner.name}
                              placeholder={strings.address.name_of_co_owner}
                              onChange={handleChange}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].name
                              }
                            />
                          </div>
                          <div>
                            <label>{strings.address.pan_of_co_owner}</label>
                            <Input
                              name={`propertyCoOwners.${index}.pan`}
                              value={coOwner.pan}
                              placeholder={strings.address.pan_of_co_owner}
                              onChange={handleChange}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].pan
                              }
                            />
                          </div>
                          <div>
                            <label>{strings.address.percentage_stage}</label>
                            <Input
                              inputType={"percentage"}
                              name={`propertyCoOwners.${index}.sharePercentage`}
                              value={coOwner.sharePercentage}
                              placeholder={strings.address.pan_of_co_owner}
                              onChange={handleChange}
                              error={
                                errors.propertyCoOwners &&
                                errors.propertyCoOwners[index] &&
                                errors.propertyCoOwners[index].sharePercentage
                              }
                            />
                          </div>
                          <button
                            className="delete_button mt-6"
                            onClick={() => remove(index)}
                          >
                            <RiDeleteBin6Fill />
                          </button>
                        </div>
                      ))}

                      <div className="text-right mt-5">
                        <button
                          className="add_item_button"
                          onClick={(e) => {
                            e.preventDefault();
                            push({ name: "", pan: "", sharePercentage: 0 });
                          }}
                        >
                          {strings.button.add_more_item}
                        </button>
                      </div>
                    </StyleFormBox>
                  )}
                </FieldArray>
              </>
            ) : (
              ""
            )}

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
