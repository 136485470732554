import React from "react";
import StyleFormBox from "../../styledComponent/Form/StyleFormBox";
import PrimaryButton from "../../styledComponent/Button/PrimaryButton";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function SelfDeclaration({handleClick}) {
    const { theme ,strings} = useSelector((state) => state.platformSetting);
    const navigate = useNavigate()
  return (
    <div className="container_box">
      <StyleFormBox>
        <h4>Self Declaration</h4>
        <hr />
        <h4 className="my-4">1. Terms</h4>
        <p>
          A mobile app's Terms and Conditions agreement (T&C) s where you set
          out the rules and restrictions for anyone who uses your mobile app. It
          helps limit your legal liability while managing user expectations.
        </p>
        <p>
          While not legally required like a Privacy Policy is, this agreement
          comes with a number of priceless business benefits that you won't want
          to miss out on. This article will get you started with writing your
          own custom Terms and Conditions agreement for a mobile app, regardless
          if it's for Apple iOS or Google Android.
        </p>
        <p>
          We've also put together a Sample Mobile App Terms and Conditions
          Template that you can use to help write your own. A mobile app's Terms
          and Conditions agreement (T&C) is where you set out the rules and
          restrictions for anyone who uses your mobile app. It helps limit yor
          legal liability while managing user expectations.
        </p>
        <p>
          While not legally required like a privacy policy is, this agreement
          comes with a number of priceless business benefits that you won't want
          to miss out on. This article will get you started with writing your
          own custom Terms and Conditions agreement for a mobile app, regardless
          if it's for Apple iOS or Google Android.{" "}
        </p>

        <p>
          We've also put together a Sample Mobile App Terms and Conditions
          Template that you can use to help
        </p>
        <h4 className="my-4">2. Conditions</h4>

        <div >
        <input type="checkbox" id="agreement" onChange={(e)=>{console.log('checkbox check',e.target.checked)}}/>
        <label htmlFor="agreement" className="cursor-pointer"> I have read and agreed to the terms and conditions</label>
        </div>
        <PrimaryButton title={strings.button.submit} addClass={'mt-5'} handleClick={()=>{handleClick('make_payment')}} />
      </StyleFormBox>
    </div>
  );
}
