import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IoSearch } from "react-icons/io5";

export default function SearchField({ placeholder,handleChange,value }) {
  const { theme } = useSelector((state) => state.platformSetting);
  return (
    <Root theme={theme}>
        <div className="input_field">
        <span><IoSearch /></span>
          <input
            type="text"
            placeholder={placeholder || 'Search'}
            value={value}
            // onKeyDown={(e)=>{console.log('eeee',e.key)e.key === 'Enter' ? }}
            onChange={(e)=>{handleChange(e.target.value)}}
          />
        </div>
    </Root>
  );
}
const Root = styled.div`
  width: 100%;
  position:relative;
  .input_field {
    position: relative;
    display:flex;
    width: 100%;
    align-items: center;
    border-radius: 12px;
    border: 1px solid ${(p) =>p.theme.border_clr};
    span{
        padding: 10px;
        font-size: 20px;
        border-radius: 12px 0px 0px 12px;
        color: ${(p) => p.theme.primary_txt};
    }

    input{
        padding: 12px 0px;
        border-radius: 0px 12px 12px 0px;
        border: none;
        width:100%;
        color: ${(p) => p.theme.primary_txt};
        background-color:transparent;
        &::placeholder {
        color: ${(p) => p.theme.primary_txt};
        font-weight: 400;
        font-size: 14px;
        opacity:0.5;
      }
      &:focus{
        outline:none;
      }
    }
  }
`;
