import React, { useEffect, useState } from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import ItrStatus from "./ItrStatus";
import { getItrStatusAction } from "../../redux/profile/action";
export default function TrackStatus() {
  const [trackStatus , setTrackStatus] = useState([])
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getItrStatusAction((e)=>{
      if(e.status == 200){
        setTrackStatus(e.data)
      }
    }))
  },[])

  return (
    <Root>
      <AccordianTab title={"Track Status"} >
        <div >
        {trackStatus.map((i,ix)=> {
         return( <div key={ix}><ItrStatus data={i}/></div>)
        })}
        </div>
      </AccordianTab>
    </Root>
  );
}
const Root = styled.div``;
