import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector ,useDispatch} from "react-redux";
import { addOpacity } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { setItrData } from "../../redux/itr/action";
export default function ItrStatus({ data }) {
  const [statusData, setStatusData] = useState({});
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (data) {
      setStatusData(data);
    }
  }, [data]);
  
  const setITRData = (link,data)=>{
    dispatch(setItrData(data))
    navigate(`/form/filing/${link}/personalInfo`)
  }

  return (
    <Root theme={theme}>
      <div className="flex items-center justify-between">
        <h4>{statusData.name}</h4>
        <span style={{color:statusData.statusColor}}>{statusData.statusText}</span>
      </div>
      <div className="flex items-center justify-between">
        <div>
        <div className="progress_bar">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
          <p>{statusData.progress}</p>
        </div>
        <button  className="action_btn" onClick={()=>setITRData(statusData.form ,statusData)}>{strings.button.continue}</button>
      </div>
    </Root>
  );
}
const Root = styled.div`
  background-color: ${(p) => addOpacity(p.theme.tertiary_bg, 0.5)};
  padding: 10px;
  border-radius: 10px;
  margin:10px 0px;

  .action_btn {
    background-color: ${(p) => p.theme.primary_bg};
    color: ${(p) => p.theme.secandary_txt};
    border-radius: 4px;
    padding: 4px 10px;
    cursor: pointer;
    font-weight: 500;
  }
  .progress_bar{
    display:flex;
    gap:18px;
    margin: 10px 0px;
    span{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #fff;
    }
  }
`;
