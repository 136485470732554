import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../../styledComponent/Inputs/Input";
import { useSelector } from "react-redux";
import Select from "../../../../../styledComponent/Inputs/Select";
import AccordianBox from "../../../../../styledComponent/Form/AccordianBox";

export default function ScheduleOI({ data }) {
  const [loader, setLoader] = useState(true);
  const [formData, setFormData] = useState({
    id: 40,
    createdAt: 1729854088796,
    updatedAt: 1731305521990,
    effectOnProfitSection145_2: 0,
    increaseDecreaseProfitSection145A: 0,
    decreaseIncreaseLossSection145A: 0,
    amountNotCreditedProfitLoss5a: 0,
    amountNotCreditedProfitLoss5b: 0,
    amountNotCreditedProfitLoss5c: 0,
    amountNotCreditedProfitLoss5d: 0,
    amountNotCreditedProfitLoss5e: 0,
    amountNotCreditedProfitLoss5f: 0,
    section36_6a: 0,
    section36_6b: 0,
    section36_6c: 0,
    section36_6d: 0,
    section36_6e: 0,
    section36_6f: 0,
    section36_6g: 0,
    section36_6h: 0,
    section36_6i: 0,
    section36_6j: 0,
    section36_6k: 0,
    section36_6l: 0,
    section36_6m: 0,
    section36_6n: 0,
    section36_6o: 0,
    section36_6p: 0,
    section36_6q: 0,
    section36_6r: 0,
    section36_6s: 0,
    section37_7a: 0,
    section37_7b: 0,
    section37_7c: 0,
    section37_7d: 0,
    section37_7e: 0,
    section37_7f: 2000,
    section37_7g: 0,
    section37_7h: 0,
    section37_7i: 0,
    section37_7j: 2000,
    section40_8Aa: 0,
    section40_8Ab: 23423,
    equalizationLevySection40_8Ac: 0,
    outsideIndiaSection40_8Ac: 0,
    section40_8Ad: 0,
    section40_8Ae: 0,
    section40_8Af: 0,
    section40_8Ag: 0,
    section40_8Ah: 0,
    section40_8Ai_8Aato8Ah: 23423,
    section40_8B: 0,
    section40A_9a: 0,
    section40A_9b: 0,
    section40A_9c: 0,
    section40A_9d: 0,
    section40A_9e: 0,
    totalAmountsection40A_9ato9e: 0,
    section43BAllowed_10a: 0,
    section43BAllowed_10b: 0,
    section43BAllowed_10c: 32324,
    section43BAllowed_10d: 0,
    section43BAllowed_10e: 0,
    section43BAllowed_10f: 0,
    section43BAllowed_10g: 33,
    totalAmountAllowed_10ato10g: 32357,
    section43Bdisallowed_11a: 0,
    section43Bdisallowed_11b: 0,
    section43Bdisallowed_11c: 232,
    section43Bdisallowed_11d: 0,
    section43Bdisallowed_11e: 0,
    section43Bdisallowed_11f: 0,
    section43Bdisallowed_11g: 44,
    totalAmountDisallowed_11ato11g: 276,
    taxAndDuty_12a: 0,
    taxAndDuty_12b: 0,
    taxAndDuty_12c: 0,
    taxAndDuty_12d: 0,
    taxAndDuty_12e: 0,
    taxAndDuty_12f: 0,
    taxAndDuty_12g: 0,
    taxAndDuty_12h: 0,
    totalTaxAndDuty_12ato12h: 0,
    amountsDeemedToBeProfits: 0,
    section33AB_13i: 0,
    section33ABA_13ii: 0,
    amountOfProfitSection41_14: 0,
    amountPriorPeriodCreditDebit_15: 0,
    amountOfExpenditureDisallowed14A_16: 0,
    deemedIncomeSection32AD_21i: 0,
    deemedIncomeSection33AB_21ii: 0,
    deemedIncomeSection33ABA_21iii: 0,
    deemedIncomeSection35ABA_21iv: 0,
    deemedIncomeSection35ABB_21v: 0,
    deemedIncomeSection40A3A_21vi: 0,
    deemedIncomeSection72A_21vii: 0,
    deemedIncomeSection80HHD_21viii: 0,
    deemedIncomeSection80IA_21ix: 0,
    totalDeemedIncome_21ito21ix: 0,
    isActive: true,
    isDeleted: false,
    methodOfAccounting: null,
    changeInMethodOfAccounting: null,
    methodOfValuationRawMaterial: null,
    methodOfValuaitonFinishedGoods: null,
    changeInMethodOfStockValuation: null,
    userId: 1,
    itrId: 4,
    professionalBusiness: 22,
    dataAdded: false,
  });
  const { ItrData } = useSelector((s) => s.itr);
  const { strings } = useSelector((s) => s.platformSetting);

  // const handleSubmitForm = (obj) => {
  //   setLoader(true);
  //   let { type, ...data } = obj;
  //   const action =
  //     type == "edit"
  //       ? editManufacturingConcernAction
  //       : saveManufacturingConcernAction;
  //   dispatch(
  //     action(data, (e) => {
  //       if (e.status === 200) {
  //         handleCallback(type, e.data);
  //         toast.success(
  //           `Successfully ${type == "edit" ? "updated" : "added"} data`
  //         );
  //       } else {
  //         toast.error("Something Went wrong");
  //       }
  //       setLoader(false);
  //     })
  //   );
  // };

  useEffect(() => {
    setLoader(true);
    // setFormData({
    //   name: data?.name || "",
    //   openingStock: data?.openingStock || "",
    //   SaleDuringPreviousYear: data?.SaleDuringPreviousYear || "",
    //   purchaseDuringPreviousYear: data?.purchaseDuringPreviousYear || "",
    //   closingStock: data?.closingStock || "",
    //   shortageExcess: data?.shortageExcess || "",
    //   unitOfMeasures: data?.unitOfMeasures?.id || "",
    //   consumptionDuringPreviousYear: data?.consumptionDuringPreviousYear || "",
    //   yieldFinishedProduct: data?.yieldFinishedProduct || "",
    //   percentageOfYield: data?.percentageOfYield || "",

    //   itrId: ItrData?.id,
    //   id: data?.id || "",
    //   type: data?.id ? "edit" : "add",
    // });
    setLoader(false);
  }, [data]);

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        // onSubmit={(values) => {
        //   handleSubmitForm(removeEmptyKeys(values));
        // }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <div className="flex_input my-3">
                <label>
                  1. Method of accounting employed in the previous year
                </label>
                <Select
                  name={"unitOfMeasures"}
                  error={errors.unitOfMeasures}
                  value={values?.unitOfMeasures}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {/* {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))} */}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>2. Is there any change in Method of Accounting</label>
                <Select
                  name={"unitOfMeasures"}
                  error={errors.unitOfMeasures}
                  value={values?.unitOfMeasures}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>

                  {/* {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))} */}
                </Select>
              </div>
              <div className="flex_input my-3">
                <label>
                  3. Effect on the profit because of deviation, if any, as per
                  Income Computation Disclosure Standards notified under section
                  145(2)
                </label>
                <h4 className="text-right">$0</h4>
              </div>
              <label>
                4. Method of valuation of closing stock employed in the previous
                year
              </label>
              <div className="px-4">
                <div className="flex_input my-3">
                  <label>
                    4(a). Raw Material (if at cost or market rates whichever is
                    less write 1, if at cost write 2, if at market rate write 3)
                  </label>
                  <Select
                    name={"unitOfMeasures"}
                    error={errors.unitOfMeasures}
                    value={values?.unitOfMeasures}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>

                    {/* {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))} */}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(b). Finished goods (if at cost or market rates whichever
                    is less write 1, if at cost write 2, if at market rate write
                    3)
                  </label>
                  <Select
                    name={"unitOfMeasures"}
                    error={errors.unitOfMeasures}
                    value={values?.unitOfMeasures}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>

                    {/* {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))} */}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(c). Is there any change in Method of Stock Valuation
                  </label>
                  <Select
                    name={"unitOfMeasures"}
                    error={errors.unitOfMeasures}
                    value={values?.unitOfMeasures}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      {strings.label.choose_option}
                    </option>

                    {/* {unitCategory?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))} */}
                  </Select>
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(d). Increase in the profit or decrease in loss because of
                    deviation, if any, from the method of valuation specified
                    under section 145A
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    4(e). Decrease in the profit or increase in loss because of
                    deviation, if any, from the method of valuation specified
                    under section 145A
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </StyleFormBox>

            <AccordianBox
              title={"Amounts not credited to the profit and loss account : $0"}
            >
              <h4>
                5. Amounts not credited to the profit and loss account, being
              </h4>
              <div className="px-4">
                <div className="flex_input my-3">
                  <label>
                    5(a). The items falling within the scope of section 28
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(b). The proforma credits, drawbacks, refund of duty of
                    customs or excise or service tax, or refund of sales tax or
                    value added tax, where such credits, drawbacks or refunds
                    are admitted as due by the authorities concerned
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(c). Escalation claims accepted during the previous year
                  </label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>5(d). Any other item of income</label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>5(e). Capital receipt, if any</label>
                  <Input
                    type="number"
                    inputType="currency"
                    name="percentageOfYield"
                    error={errors.percentageOfYield}
                    value={values.percentageOfYield}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>
                    5(f). Total of amounts not credited to profit and loss
                    account(5a+5b+5c +5d+5e)
                  </label>
                  <h4 className="text-right">$0</h4>
                </div>
              </div>
            </AccordianBox>
            <AccordianBox
              title={"Section 36  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallowable under section 36 due to non-fulfilment of conditions specified in relevant clauses"
              }
            >
              <p>
                6. Amounts debited to the profit and loss account, to the extent
                disallowable under section 36 due to non-fulfilment of
                conditions specified in relevant clauses
              </p>
              <div className="flex_input my-3">
                <label>
                  6(a). Premium paid for insurance against risk of damage or
                  dest-ruction of stocks or store [36(1)(i)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6a}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(b). Premium paid for insurance on the health of employees
                  [36(1)(ib)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6b}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(c). Any sum paid to an employee as bonus or commission for
                  services rendered, where such sum was otherwise payable to him
                  as profits or dividend [36(1)(ii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6c}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(d). Any amount of interest paid in respect of borrowed
                  capital [36(1)(iii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6d}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(e). Amount of discount on a zero-coupon bond [36(1)(iiia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6e}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(f). Amount of contributions to a recognised provident fund
                  [36(1)(iv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6f}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(g). Amount of contributions to an approved superannuation
                  fund [36(1)(iv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6g}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(h). Amount of contribution to a pension scheme referred to
                  in section 80CCD [36(1)(iva)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6h}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(i). Amount of contributions to an approved gratuity fund
                  [36(1)(v)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6i}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>6(j). Amount of contributions to any other fund</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6j}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(k). Any sum received from employees as contribution to any
                  provident fund or superannuation fund or any fund set up under
                  ESI Act or any other fund for the welfare of employees to the
                  extent not credited to the employees account on or before the
                  due date [36(1)(va)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6k}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(l). Amount of bad and doubtful debts [36(1)(vii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6l}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(m). Provision for bad and doubtful debts [36(1)(viia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6m}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(n). Amount transferred to any special reserve [36(1)(viii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6n}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(o). Expenditure for the purposes of promoting family
                  planning amongst employees [36(1)(ix)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6o}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(p). Amount of securities transaction paid in respect of
                  transaction in securities if such income is not included in
                  business income [36(1)(xv)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6p}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(q). Marked to market loss or other expected loss as computed
                  in accordance with the ICDS notified u/s 145(2) [36(1)(xviii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6q}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>6(r). Any other disallowance</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6r}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  6(s). Total amount disallowable under section 36 (total of 6a
                  to 6r)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section36_6s}
                </h4>
              </div>
            </AccordianBox>
            <AccordianBox
              title={"Section 37  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallo-wable under section 37"
              }
            >
              <p>
                7. Amounts debited to the profit and loss account, to the extent
                disallowable under section 37
              </p>
              <div className="flex_input my-3">
                <label>7(a). Expenditure of capital nature [37(1)]</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7a}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>7(b). Expenditure of personal nature [37(1)]</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7b}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(c). Expenditure laid out or expended wholly and exclusively
                  NOT for the purpose of business or profession [37(1)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7c}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(d). Expenditure on advertisement in any souvenir, brochure,
                  tract, pamphlet or the like, published by a political party
                  [37(2B)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7d}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(e). Expenditure by way of penalty or fine for violation of
                  any law for the time being in force
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7e}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>7(f). Any other penalty or fine</label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7f}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(g). Expenditure incurred for any purpose which is an offence
                  or which is prohibited by law
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7g}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(h). Amount of any liability of a contingent nature
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7h}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(i). Any other amount not allowable under section 37
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7i}
                </h4>
              </div>
              <div className="flex_input my-3">
                <label>
                  7(j). Total amount disallowable under section 37(total of 7a
                  to 7i)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section37_7j}
                </h4>
              </div>
            </AccordianBox>
            <AccordianBox
              title={"Section 40  : $0"}
              initial={false}
              description={
                "Amounts debited to the profit and loss account, to the extent disallowable under section 40"
              }
            >
              <p>
                8(A). Amounts debited to the profit and loss account, to the
                extent disallowable under section 40
              </p>
              <div className="flex_input my-3">
                <label>
                  8(Aa). Disallowance of expenditure for payment outside India
                  or to a non-resident on which TDS is not deducted/deposited
                  u/s [40(a)(i)] on account of non-compliance with provisions of
                  Chapter XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Aa}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ab). Disallowance of expenditure for payment to a resident
                  on which TDS is not deducted/deposited u/s [40(a)(ia)] on
                  account of non-compliance with the provisions of Chapter
                  XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Ab}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ac). Disallowance of expenditure for non-deduction and
                  payment of equalization levy u/s [40(a)(ib)], on account of
                  non-compliance with the provisions of Chapter VIII
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    formData?.equalizationLevySection40_8Ac}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ac). Disallowance of expenditure for payment of salary
                  outside India or non-resident on which TDS is not
                  deducted/deposited u/s [40(a)(iii)] on account of
                  non-compliance with the provisions of Chapter XVII-B
                </label>
                <h4 className="text-right">
                  {strings.label.rupee +
                    " " +
                    formData?.outsideIndiaSection40_8Ac}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ad). Disallowance of amount paid as direct taxes like income
                  tax, agriculture tax, interest paid on income tax, etc. u/s
                  [40(a)(ii)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Ad}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ae). Disallowance of amount paid as wealth tax u/s
                  [40(a)(iia)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Ae}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Af). Disallowance of amount paid by way of royalty, license
                  fee, service fee etc. By the state government undertaking to
                  state government u/s [40(a)(iib)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Af}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ag). Disallowance of amount paid as interest, salary, bonus,
                  commission or remuneration paid to any partner or member u/s
                  [40(b)]
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Ag}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(Ai). Total amount disallowable under section 40(total of Aa
                  to Ah)
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8Ah}
                </h4>
              </div>

              <div className="flex_input my-3">
                <label>
                  8(B). Any amount disallowed under section 40 in any preceding
                  previous year but allowable during the previous year
                </label>
                <h4 className="text-right">
                  {strings.label.rupee + " " + formData?.section40_8B}
                </h4>
              </div>
            </AccordianBox>
            
          </form>
        )}
      </Formik>
    </div>
  );
}
