import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../styledComponent/Inputs/Input";
import ToggelButton from "../../../styledComponent/Button/ToggelButton";
import Select from "../../../styledComponent/Inputs/Select";
import { Formik } from "formik";
import LoadingBox from "../../DialogBox/LoadingBox";
import * as Yup from "yup";
import {
  saveTaxCollectedAction,
  updateTaxCollectedAction,
} from "../../../redux/itr/action";
import { removeEmptyKeys } from "../../../utils/helpers";

export default function TaxCollectedSource({
  handleClose,
  FinancialYear,
  handleCallback,
  TdsRelatedTo,
  editData,
}) {
  const [taxCollectedData, setTaxCollectedData] = useState({});
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();

  const handleSubmitForm = (values) => {
    if (values.id !== 0) {
      dispatch(
        updateTaxCollectedAction(values, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback("update", e.data);
          }
        })
      );
    } else {
      let { id, ...newData } = values;
      dispatch(
        saveTaxCollectedAction(newData, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback("add", e.data);
          }
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    tanOfDeductor: Yup.string().required(strings.validation_message.required),
    nameOfDeductor: Yup.string().required(strings.validation_message.required),
    taxDeducted: Yup.string().required(strings.validation_message.required),
    // tdsRelatingTo: Yup.string().when('tdsAnotherAssessee', {
    //   is: true,
    //   then: Yup.string().required(strings.validation_message.required),
    // }),
    tdsCredit: Yup.string().required(strings.validation_message.required),
    incomeRelatingTdsCredit: Yup.string().required(
      strings.validation_message.required
    ),
    panOtherPerson: Yup.string().required(strings.validation_message.required),
    taxDeductedClaimedForThisYear: Yup.string().required(
      strings.validation_message.required
    ),
    incomeAgainstTdsDeducted: Yup.string().required(
      strings.validation_message.required
    ),
    financialYearTdsDeducted: Yup.string().required(
      strings.validation_message.required
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      tdsRelatingTo: editData?.tdsRelatingTo || "",
      tdsCredit: editData?.tdsCredit || "",
      incomeRelatingTdsCredit: editData?.incomeRelatingTdsCredit || "",
      panOfOtherPerson: editData?.panOfOtherPerson || "",
      // deductionId: 1,
      tanOfCollector: editData?.tanOfCollector || "",
      nameOfCollector: editData?.nameOfCollector || "",
      totalTaxDeducted: editData?.totalTaxDeducted || "",
      creditsFromAnotherAssessee: editData?.creditsFromAnotherAssessee || false,
      taxDeductedClaimedForTheYear:
        editData?.taxDeductedClaimedForTheYear || "",
      expenditureAgainstTdsDeducted:
        editData?.expenditureAgainstTdsDeducted || "",
      financialYearTcsDeducted: editData?.financialYearTcsDeducted || "",
      id: editData?.id || 0,
      itrId: ItrData?.id,
    });
    setLoader(false);
  }, [editData]);

  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
        }) => (
          <div>
            <StyleFormBox>
              <div className="flex gap-10 justify-between mt-3">
                <h5>{strings.tax_saving.tax_collected_source}</h5>
              </div>
              <p>{strings.tax_saving.provide_this_information_for_tds}</p>
              <div className="flex_input mt-2">
                <label>{strings.tax_saving.tan_of_collector}</label>
                <Input
                  placeholder={strings.tax_saving.tan_of_collector}
                  name={"tanOfCollector"}
                  value={values.tanOfCollector}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input mt-2">
                <label>{strings.tax_saving.name_of_collector}</label>
                <Input
                  name={"nameOfCollector"}
                  placeholder={strings.tax_saving.name_of_collector}
                  value={values.nameOfCollector}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input mt-2">
                <div>
                  <label>{strings.tax_saving.total_tax_deducted}</label>
                  <p className="note_message">
                    {strings.message.leave_if_not_deducted_in_name}
                  </p>
                </div>
                <Input
                  inputType={"currency"}
                  name={"totalTaxDeducted"}
                  placeholder={strings.tax_saving.name_of_collector}
                  value={values.totalTaxDeducted}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input mt-2">
                <label>{strings.tax_saving.claim_transfer_tds_credits}</label>

                <ToggelButton
                  value={values.creditsFromAnotherAssessee}
                  handleClick={() =>
                    setFieldValue(
                      "creditsFromAnotherAssessee",
                      !values.creditsFromAnotherAssessee
                    )
                  }
                />
              </div>
              {values.creditsFromAnotherAssessee === true ? (
                <>
                  <div className="flex_input mt-2">
                    <label>{strings.tax_saving.tds_credit_relating_to}</label>
                    <Select
                      name={"tdsRelatingTo"}
                      value={values.tdsRelatingTo}
                      error={errors.tdsRelatingTo}
                      onChange={handleChange}
                    >
                      <option value="">{strings.label.choose_option}</option>
                      {TdsRelatedTo?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex_input mt-2">
                    <div>
                      <label>{strings.tax_saving.tds_credit}</label>
                      <p className="note_message">
                        {strings.message.amount_tds_received_transfered}
                      </p>
                    </div>
                    <Input
                      inputType={"currency"}
                      name={"tdsCredit"}
                      value={values.tdsCredit}
                      error={errors.tdsCredit}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex_input mt-2">
                    <label>
                      {strings.tax_saving.income_against_which_tds_was_deducted}
                    </label>
                    <Input
                      inputType={"currency"}
                      name={"incomeRelatingTdsCredit"}
                      value={values.incomeRelatingTdsCredit}
                      error={errors.incomeRelatingTdsCredit}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="flex_input mt-2">
                    <label>{strings.tax_saving.pan_other_person}</label>
                    <Input
                      name={"panOtherPerson"}
                      value={values.panOtherPerson}
                      error={errors.panOtherPerson}
                      onChange={handleChange}
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="flex_input mt-2">
                <label>{strings.tax_saving.tax_deducted_claimed} </label>
                <Input
                  inputType={"currency"}
                  name={"taxDeductedClaimedForTheYear"}
                  placeholder={strings.tax_saving.name_of_collector}
                  value={values.taxDeductedClaimedForTheYear}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input mt-2">
                <label>
                  {strings.tax_saving.expenditure_year_tcs_deducted}
                </label>
                <Input
                  inputType={"currency"}
                  name={"expenditureAgainstTdsDeducted"}
                  placeholder={strings.tax_saving.name_of_collector}
                  value={values.expenditureAgainstTdsDeducted}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input mt-2">
                <label>{strings.tax_saving.financial_year_tcs_deducted}</label>
                <Select
                  value={values.financialYearTcsDeducted}
                  name={"financialYearTcsDeducted"}
                  onChange={handleChange}
                >
                  <option value="">{strings.label.choose_option}</option>
                  {FinancialYear?.map((i, ix) => (
                    <option value={i.value} key={ix}>
                      {i.name}
                    </option>
                  ))}
                </Select>
              </div>

              <span className="note_message">
                {strings.message.this_is_same_as_financial_year}
              </span>

              <div className="flex_input">
                <button className="secandary_button" onClick={handleClose}>
                  {strings.button.cancel}
                </button>
                <button
                  className="primary_button"
                  onClick={() => handleSubmit(taxCollectedData)}
                >
                  {strings.button.save_and_close}
                </button>
              </div>
            </StyleFormBox>
          </div>
        )}
      </Formik>
    </div>
  );
}
