import React from 'react'
import UploadForm16 from '../component/ITR/ItrUploadForm'

export default function ITR34() {
  return (
    <div className="container_box">
      <UploadForm16 redirect={"/form/filing/ITR3-4"}/>
    </div>
  )
}
