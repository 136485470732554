import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Input from "../../../../styledComponent/Inputs/Input";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  editaddGSTDetailsAction,
  saveaddGSTDetailsAction,
} from "../../../../redux/itr/action";
export default function AddGst({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    gstInfo: Yup.array().of(
      Yup.object().shape({
        gstnNumber: Yup.string().required("GSTN Number is required"),
        grossReceipts: Yup.number()
          .typeError("Gross Receipts must be a number")
          .required("Gross Receipts are required"),
      })
    ),
  });

  const saveGstDetailsApi = async (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editaddGSTDetailsAction : saveaddGSTDetailsAction;

    dispatch(
      action(data, (e) => {
        if (e.status == 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
          setLoader(false);
        } else {
          toast.error(e.message);
          setLoader(false);
        }
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      itrId: ItrData.id,
      gstInfo:
        data?.length > 0
          ? data.map(({ id, gstnNumber, grossReceipts }) => ({
              id,
              gstnNumber,
              grossReceipts,
            }))
          : [{ gstnNumber: "", grossReceipts: "" }],
      type: data?.length > 0 ? "edit" : "add",
    });

    setLoader(false);
  }, [data]);

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveGstDetailsApi(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <StyleFormBox>
              <h4>{strings.tax.gst_details}</h4>
              <p>{strings.tax.information_regarding_turnover_receipt_report}</p>

              <FieldArray name="gstInfo">
                {({ insert, remove, push }) => (
                  <div>
                    {values?.gstInfo?.map((item, index) => (
                      <StyleFormBox key={index}>
                        <button
                          className="delete_button mb-2 block ml-auto"
                          onClick={() => remove(index)}
                        >
                          <RiDeleteBin6Fill />
                        </button>

                        <div className="flex_input my-2">
                          <label>{strings.tax.gstn_number} </label>
                          <Input
                            name={`gstInfo.${index}.gstnNumber`}
                            error={
                              errors?.gstInfo &&
                              errors?.gstInfo[index]?.gstnNumber
                            }
                            value={item.gstnNumber}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="flex_input my-2">
                          <label>{strings.tax.gross_receipts} </label>
                          <Input
                            name={`gstInfo.${index}.grossReceipts`}
                            error={
                              errors?.gstInfo &&
                              errors?.gstInfo[index]?.grossReceipts
                            }
                            value={item.grossReceipts}
                            onChange={handleChange}
                          />
                        </div>
                      </StyleFormBox>
                    ))}

                    <div className="text-right mt-5">
                      <button
                        className="add_item_button"
                        onClick={(e) => {
                          e.preventDefault();
                          push({
                            gstnNumber: "",
                            grossReceipts: "",
                          });
                        }}
                      >
                        {strings.button.add_more_item}
                      </button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </StyleFormBox>

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
