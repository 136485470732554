import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import AccordianBox from "../../../../../styledComponent/Form/AccordianBox";
import InputField from "../../../../../styledComponent/Inputs/InputField";
import AddMoreButton from "../../../../../styledComponent/Button/AddMoreButton";
import AddActionButton from "../../../../../styledComponent/Button/AddActionButton";
import {
  editScheduleBP,
  getScheduleBP,
  saveScheduleBP,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import LoadingBox from "../../../../DialogBox/LoadingBox";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import Input from "../../../../../styledComponent/Inputs/Input";

export default function ScheduleBp({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const getgetScheduleBPApi = () => {
    dispatch(
      getScheduleBP(ItrData.id, (e) => {
        if (e.status == 200) {
          if (e.status == 200) {
          } else {
            toast.error("Something went wrong");
          }
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const handleSubmitForm = (obj) => {
    // setLoader(true);
    let { type, ...data } = obj;
    const action = type == "edit" ? editScheduleBP : saveScheduleBP;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    getgetScheduleBPApi();
    setFormData({
      profitBeforeTax: data?.profitBeforeTax || "",
      incomeSalaries: data?.incomeSalaries || "",
      incomeHouseProperty: data?.incomeHouseProperty || "",
      incomeCapitalGain: data?.incomeCapitalGain || "",
      incomeDividend: data?.incomeDividend || "",
      incomeOtherThanDividend: data?.incomeOtherThanDividend || "",
      incomeUnderSection115bbf: data?.incomeUnderSection115bbf || "",
      incomeUnderSection115bbg: data?.incomeUnderSection115bbg || "",
      incomeUnderSection115bbh: data?.incomeUnderSection115bbh || "",
      expenseSalaries: data?.expenseSalaries || "",
      expenseHouseProperty: data?.expenseHouseProperty || "",
      expenseCapitalGain: data?.expenseCapitalGain || "",
      expenseAnyOtherSource: data?.expenseAnyOtherSource || "",
      expenseUnderSection115bbf: data?.expenseUnderSection115bbf || "",
      expenseUnderSection115bbg: data?.expenseUnderSection115bbg || "",
      expenseUnderSection115bbh: data?.expenseUnderSection115bbh || "",
      expensesDebitedToPL: data?.expensesDebitedToPL || "",
      expensesDebitedToPLUnderSection14A:
        data?.expensesDebitedToPLUnderSection14A || "",
      shareOfIncomeFromFirm: data?.shareOfIncomeFromFirm || "",
      shareOfIncomeFromAOP: data?.shareOfIncomeFromAOP || "",
      anyOtherExemptIncome: data?.anyOtherExemptIncome || [],
      depreciationAsPerBooks: data?.depreciationAsPerBooks || "",
      depreciationAsPerIncomeTaxAct: data?.depreciationAsPerIncomeTaxAct || "",
      depreciationAllowableSection_32:
        data?.depreciationAllowableSection_32 || "",
      anyOtherAmountAllowedAsDeduction:
        data?.anyOtherAmountAllowedAsDeduction || "",
      remunerationDeduction: data?.remunerationDeduction || "",
      notIncludedSalary: data?.notIncludedSalary || "",
      notIncludedBonus: data?.notIncludedBonus || "",
      notIncludedCommission: data?.notIncludedCommission || "",
      notIncludedInterestFromFirm: data?.notIncludedInterestFromFirm || "",
      notIncludedAnyOtherInterest: data?.notIncludedAnyOtherInterest || "",
      notIncludedOther: data?.notIncludedOther || "",
      notIncludedOtherIncomeInPL: data?.notIncludedOtherIncomeInPL || "",
      allowancesDeductionUnderOtherSections:
        data?.allowancesDeductionUnderOtherSections || "",
      profitAndGainsSection44B: data?.profitAndGainsSection44B || "",
      profitAndGainsSection44BB: data?.profitAndGainsSection44BB || "",
      profitAndGainsSection44BBA: data?.profitAndGainsSection44BBA || "",
      profitAndGainsSection44DA: data?.profitAndGainsSection44DA || "",
      ProfitDetailsUnderRule7: data?.ProfitDetailsUnderRule7 || [
        {
          section: 725,
          totalIncome: "34",
          businessIncome: "34",
          agricultureIncome: "345",
        },
      ],

      itrId: ItrData?.id,
      id: data?.id || "",
      type: data?.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);

  return (
    <>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <StyleFormBox>
              <div className="flex_input">
                <div>
                  <label>
                    {strings.income.profit_before_tax_as_pl_account} *
                  </label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_pl}
                  </span>
                </div>

                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitBeforeTax"}
                  error={errors.profitBeforeTax}
                  value={values.profitBeforeTax}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <AccordianBox
              title={strings.income.income_from_other_source_credit_to_pl}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.salaries} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeSalaries"}
                  error={errors.incomeSalaries}
                  value={values.incomeSalaries}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <abel>{strings.income.house_properties} *</abel>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeHouseProperty"}
                  error={errors.incomeHouseProperty}
                  value={values.incomeHouseProperty}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.captial_gains} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeCapitalGain"}
                  error={errors.incomeCapitalGain}
                  value={values.incomeCapitalGain}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.dividend_income} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeDividend"}
                  error={errors.incomeDividend}
                  value={values.incomeDividend}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.other_divident_income} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeOtherThanDividend"}
                  error={errors.incomeOtherThanDividend}
                  value={values.incomeOtherThanDividend}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.us_115bbf} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeUnderSection115bbf"}
                  error={errors.incomeUnderSection115bbf}
                  value={values.incomeUnderSection115bbf}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.us_115bbg}*</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeUnderSection115bbg"}
                  error={errors.incomeUnderSection115bbg}
                  value={values.incomeUnderSection115bbg}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.us_115bbh} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"incomeUnderSection115bbh"}
                  error={errors.incomeUnderSection115bbh}
                  value={values.incomeUnderSection115bbh}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.expenses_from_other_source_debited_to_pl}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.salaries} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseSalaries"}
                  error={errors.expenseSalaries}
                  value={values.expenseSalaries}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.house_properties} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseHouseProperty"}
                  error={errors.expenseHouseProperty}
                  value={values.expenseHouseProperty}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.captial_gains} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseCapitalGain"}
                  error={errors.expenseCapitalGain}
                  value={values.expenseCapitalGain}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.any_other_source} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseAnyOtherSource"}
                  error={errors.expenseAnyOtherSource}
                  value={values.expenseAnyOtherSource}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <label>{strings.income.us_115bbf} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseUnderSection115bbf"}
                  error={errors.expenseUnderSection115bbf}
                  value={values.expenseUnderSection115bbf}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.us_115bbg} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseUnderSection115bbg"}
                  error={errors.expenseUnderSection115bbg}
                  value={values.expenseUnderSection115bbg}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.us_115bbh} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expenseUnderSection115bbh"}
                  error={errors.expenseUnderSection115bbh}
                  value={values.expenseUnderSection115bbh}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={
                strings.income.expenses_debited_pl_account_to_exempt_income
              }
              initial={false}
            >
              <div className="flex_input my-3">
                <label>
                  {strings.income.expenses_debited_pl_account_to_exempt_income}{" "}
                  *
                </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expensesDebitedToPL"}
                  error={errors.expensesDebitedToPL}
                  value={values.expensesDebitedToPL}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>
                  {strings.income.expenses_debited_pl_account_exempt_disallowed}{" "}
                  *
                </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"expensesDebitedToPLUnderSection14A"}
                  error={errors.expensesDebitedToPLUnderSection14A}
                  value={values.expensesDebitedToPLUnderSection14A}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.exempt_income_credited_to_pl}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.share_income_from_firm} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"shareOfIncomeFromFirm"}
                  error={errors.shareOfIncomeFromFirm}
                  value={values.shareOfIncomeFromFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.share_income_from_aop_boi} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"shareOfIncomeFromAOP"}
                  error={errors.shareOfIncomeFromAOP}
                  value={values.shareOfIncomeFromAOP}
                  onChange={handleChange}
                />
              </div>

              <h5>{strings.income.any_other_exempt_income}</h5>
              <div className="flex_input my-3">
                <label>{strings.income.amount} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"anyOtherExemptIncome.amount"}
                  error={errors.anyOtherExemptIncome?.amount}
                  value={values.anyOtherExemptIncome?.amount}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>{strings.label.description} *</label>
                <Input
                  inputType={"message"}
                  name={"anyOtherExemptIncome.description"}
                  error={errors.anyOtherExemptIncome?.description}
                  value={values.anyOtherExemptIncome?.description}
                  onChange={handleChange}
                />
              </div>

              <div className="text-right mt-5">
                <AddMoreButton title={strings.button.add_more_item} />
                <AddActionButton title={strings.button.add} />
              </div>
            </AccordianBox>

            <AccordianBox title={strings.income.depreciation} initial={false}>
              <div className="flex_input my-3">
                <div>
                  <label>
                    {strings.income.depreciation_per_book_debited_pl} *
                  </label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_pl}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"depreciationAsPerBooks"}
                  error={errors.depreciationAsPerBooks}
                  value={values.depreciationAsPerBooks}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <div>
                  <label>
                    {strings.income.depreciation_it_act_sec_321ii_321iia} *
                  </label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_depreciation}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"depreciationAsPerIncomeTaxAct"}
                  error={errors.depreciationAsPerIncomeTaxAct}
                  value={values.depreciationAsPerIncomeTaxAct}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>
                  {strings.income.depreciation_under_section_321i} *
                </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"depreciationAllowableSection_32"}
                  error={errors.depreciationAllowableSection_32}
                  value={values.depreciationAllowableSection_32}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.amount_allowable_as_deduction}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.amount_allowable_as_deduction} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"anyOtherAmountAllowedAsDeduction"}
                  error={errors.anyOtherAmountAllowedAsDeduction}
                  value={values.anyOtherAmountAllowedAsDeduction}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-3">
                <div>
                  <label>{strings.income.remuneration_deduction} *</label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_from_firm_card}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"remunerationDeduction"}
                  error={errors.remunerationDeduction}
                  value={values.remunerationDeduction}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.income_in_pl_account}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.tax.salary_income}</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedSalary"}
                  error={errors.notIncludedSalary}
                  value={values.notIncludedSalary}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.bonus} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedBonus"}
                  error={errors.notIncludedBonus}
                  value={values.notIncludedBonus}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.commission} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedCommission"}
                  error={errors.notIncludedCommission}
                  value={values.notIncludedCommission}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <div>
                  <label>{strings.income.interest_from_firm} </label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_from_firm_card}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedInterestFromFirm"}
                  error={errors.notIncludedInterestFromFirm}
                  value={values.notIncludedInterestFromFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.any_other_interest} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedAnyOtherInterest"}
                  error={errors.notIncludedAnyOtherInterest}
                  value={values.notIncludedAnyOtherInterest}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <div>
                  <label>{strings.income.others}</label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_from_firm_card}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedOther"}
                  error={errors.notIncludedOther}
                  value={values.notIncludedOther}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <div>
                  <label>{strings.income.income_not_included_in_pl}</label>
                  <span className="text-xs" style={{ color: theme.primary_bg }}>
                    {strings.message.automatically_copied_from_firm_card}
                  </span>
                </div>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"notIncludedOtherIncomeInPL"}
                  error={errors.notIncludedOtherIncomeInPL}
                  value={values.notIncludedOtherIncomeInPL}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.allowances_under_other_sections}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.deduction_under_section_321iii} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"allowancesDeductionUnderOtherSections"}
                  error={errors.allowancesDeductionUnderOtherSections}
                  value={values.allowancesDeductionUnderOtherSections}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.profit_of_business_deemed_to_under}
              initial={false}
            >
              <div className="flex_input my-3">
                <label>{strings.income.section_44b} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitAndGainsSection44B"}
                  error={errors.profitAndGainsSection44B}
                  value={values.profitAndGainsSection44B}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.section_44bb}</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitAndGainsSection44BB"}
                  error={errors.profitAndGainsSection44BB}
                  value={values.profitAndGainsSection44BB}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.section_44bba} </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitAndGainsSection44BBA"}
                  error={errors.profitAndGainsSection44BBA}
                  value={values.profitAndGainsSection44BBA}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.section_44da}</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitAndGainsSection44DA"}
                  error={errors.profitAndGainsSection44DA}
                  value={values.profitAndGainsSection44DA}
                  onChange={handleChange}
                />
              </div>
            </AccordianBox>

            <AccordianBox
              title={strings.income.profite_activities_covered_under}
              initial={false}
            >
              <StyleFormBox>
                <h4>{strings.income.income_under_rule7}</h4>
                <div className="flex_input my-3">
                  <label>{strings.balance_sheet.total_income} *</label>
                  <Input
                    type={"number"}
                    inputType={"currency"}
                    name={"ProfitDetailsUnderRule7.totalIncome"}
                    error={errors.ProfitDetailsUnderRule7?.totalIncome}
                    value={values.ProfitDetailsUnderRule7?.totalIncome}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.business_income} *</label>
                  <Input
                    type={"number"}
                    inputType={"currency"}
                    name={"ProfitDetailsUnderRule7.businessIncome"}
                    error={errors.ProfitDetailsUnderRule7?.businessIncome}
                    value={values.ProfitDetailsUnderRule7?.businessIncome}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.agricultural_income} *</label>
                  <Input
                    type={"number"}
                    inputType={"currency"}
                    name={"ProfitDetailsUnderRule7.agricultureIncome"}
                    error={errors.ProfitDetailsUnderRule7?.agricultureIncome}
                    value={values.ProfitDetailsUnderRule7?.agricultureIncome}
                    onChange={handleChange}
                  />
                </div>
              </StyleFormBox>
              <StyleFormBox>
                <h4>{strings.income.income_under_rule7a}</h4>
                <div className="flex_input my-3">
                  <label>{strings.balance_sheet.total_income}</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.business_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.agricultural_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
              </StyleFormBox>
              <StyleFormBox>
                <h4>{strings.income.income_under_rule7b1}</h4>
                <div className="flex_input my-3">
                  <label>{strings.balance_sheet.total_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.business_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.agricultural_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
              </StyleFormBox>
              <StyleFormBox>
                <h4>{strings.income.income_under_rule7b1a}</h4>
                <div className="flex_input my-3">
                  <label>{strings.balance_sheet.total_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.business_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.agricultural_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
              </StyleFormBox>
              <StyleFormBox>
                <h4>{strings.income.income_under_rule8}</h4>
                <div className="flex_input my-3">
                  <label>{strings.balance_sheet.total_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.business_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
                <div className="flex_input my-3">
                  <label>{strings.income.agricultural_income} *</label>
                  <InputField error={""} value={""} handleChange={() => {}} />
                </div>
              </StyleFormBox>
            </AccordianBox>

            <button
              className="primary_button my-4"
              onClick={() => handleSubmit()}
            >
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </>
  );
}
