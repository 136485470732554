import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../styledComponent/Inputs/Input";
import { getCitiesByIdAction } from "../../../redux/platformSetting/action";
import {
  postAddDonationsAction,
  updateAddDonationsAction,
} from "../../../redux/itr/action";
import * as Yup from "yup";
import LoadingBox from "../../DialogBox/LoadingBox";
import { toast } from "react-toastify";
import { removeEmptyKeys } from "../../../utils/helpers";

export default function Donation80GGC({
  editData,
  organizations80g,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [activeState, setActiveState] = useState(1);
  const [citiesList, setCitiesList] = useState([]);
  const [loader, setLoader] = useState(false);
  const { strings, states } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();
  // API : Get citi by state id
  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };
  const handleSubmitForm = (values) => {
    if (values.id) {
      setLoader(true);
      dispatch(
        updateAddDonationsAction(removeEmptyKeys(values), (e) => {
          console.log(e);
          if (e.status == 200) {
            toast.success("Successfully update data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    } else {
      setLoader(true);
      let { id, ...newData } = values;
      dispatch(
        postAddDonationsAction(removeEmptyKeys(newData), (e) => {
          console.log(e);
          if (e.status == 200) {
            toast.success("Successfully upload data");
            setLoader(false);
            handleCallback(e.data);
          } else {
            setLoader(false);
          }
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    // organization: Yup.string().required(strings.validation_message.required),
    // qualifyingPercentage: Yup.number().required(
    //   strings.validation_message.required
    // ),
    // // noLimit: Yup.boolean().required(strings.validation_message.required),
    // // subjectToIncome: Yup.boolean().required(strings.validation_message.required),
    // doneeWithPanName: Yup.string().required(
    //   strings.validation_message.required
    // ),
    // doneePAN: Yup.string().required(strings.validation_message.required),
    // addrDetail: Yup.string().required(strings.validation_message.required),
    // cityId: Yup.number().required(strings.validation_message.required),
    // stateId: Yup.number().required(strings.validation_message.required),
    // pinCode: Yup.number().required(strings.validation_message.required),
    // donationAmtCash: Yup.number().required(strings.validation_message.required),
    // donationAmtOtherMode: Yup.number().required(strings.validation_message.required),
  });

  useEffect(() => {
    getCities();
  }, [activeState]);

  useEffect(() => {
    setLoader(true);
    setFormData({
      dateOfReceipt: editData?.dateOfReceipt || "",
      donationAmountCash: editData?.donationAmountCash || "",
      donationAmountNonCash: editData?.donationAmountNonCash || "",
      transactionDetails: editData?.transactionDetails || "",
      IFSCCode: editData?.IFSCCode || "",
      deductionId: editData?.deductionId || '',
      id: editData?.id || 0,
      itrId: ItrData.id,
    });
    setLoader(false);
  }, [editData]);

  console.log('formData =====>',editData)
  return (
    <>
      <LoadingBox open={loader} />

      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
        handleFormUpdate
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <StyleFormBox>
              <h5>{strings.tax_saving.details_contribution_made}</h5>
              <div className="flex_input mt-2">
                <label>{strings.income.date_of_receipt}</label>
                <Input
                  // inputType={"date"}
                  // name={"dateOfReceipt"}
                  value={'values.dateOfReceipt'}
                  // error={errors.dateOfReceipt}
                  // onChange={handleChange}
                />
              </div>

              <h5>{strings.tax_saving.amount_of_contribution}</h5>
              <div className="flex_input mt-2">
                <label>{strings.tax_saving.donation_amount_cash}</label>
                <Input
                  inputType={"currency"}
                  value={values.donationAmountCash}
                  error={errors.donationAmountCash}
                  name={"donationAmountCash"}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input mt-2">
                <label>{strings.tax_saving.donation_amount_non_cash} </label>
                <Input
                  inputType={"currency"}
                  name={"donationAmtOtherMode"}
                  value={values.donationAmtOtherMode}
                  error={errors.donationAmtOtherMode}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>
            <StyleFormBox>
              <div className="flex items-center justify-between my-3">
                <h5>{strings.tax_saving.eligible_amount_of_contribution}</h5>
                <div>{strings.label.rupee} 5000</div>
              </div>
            </StyleFormBox>
            <StyleFormBox>
              <h5>{strings.tax_saving.transaction_details}</h5>

              <div className="flex_input mt-2">
                <label>{strings.tax_saving.reference_number} </label>
                <Input
                  name={"transactionDetails"}
                  value={values.transactionDetails}
                  error={errors.transactionDetails}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input mt-2">
                <label>{strings.label.ifsc_code} </label>
                <Input
                  name={"IFSCCode"}
                  value={values.IFSCCode}
                  error={errors.IFSCCode}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <button className="primary_button mt-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </>
  );
}
