// Profile Constant
export const USER_REGISTRATION = "USER_REGISTRATION";
export const UPDATE_USER = "UPDATE_USER";

export const GET_ALL_DATA = "GET_ALL_DATA";
export const SEND_VERIFY_OTP = "SEND_VERIFY_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const GET_GLOSSARY = "GET_GLOSSARY";
export const GET_GLOSSARY_ID = "GET_GLOSSARY_ID";
export const GET_FAQ = "GET_FAQ";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const SUBMIT_QUESTIONS = "SUBMIT_QUESTIONS";
export const GET_TICKET_TAGS = "GET_TICKET_TAGS";
export const CREATE_TICKET = "CREATE_TICKET";
export const GET_SERVICE_LIST = "GET_SERVICE_LIST";
export const CONTACT_US = "CONTACT_US";
export const ITR_STATUS = "ITR_STATUS";
export const SET_ITR_ID = "SET_ITR_ID";

// User Login
export const USER_LOGIN = "USER_LOGIN";
export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SET_USER_DATA = "SET_USER_DATA";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const VERIFY_OTP_FORGOT_PASSWORD = "VERIFY_OTP_FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const LOGOUT_USER = "LOGOUT_USER";

// Platform settings constant
export const GET_ALL_SETTINGS = "GET_ALL_SETTINGS";
export const SET_PLATFORM_STRING = "SET_PLATFORM_STRING";
export const SET_PLATFORM_THEME = "SET_PLATFORM_THEME";
export const GET_ALL_STATES = "GET_ALL_STATES";
export const SET_ALL_STATES = "SET_ALL_STATES";
export const GET_CITIES_BY_ID = "GET_CITIES_BY_ID";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const GET_LANGUAGES = "GET_LANGUAGES";

// User theme
export const GET_USER_THEME = "GET_USER_THEME";

//  ITR
export const SET_PERSONAL_INFO = "SET_PERSONAL_INFO";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";

export const SET_GROSS_INCOME = "SET_GROSS_INCOME";
export const GET_GROSS_INCOME = "GET_GROSS_INCOME";
export const GET_USER_SALARY_BREAKUP = "GET_USER_SALARY_BREAKUP";

export const SET_OTHER_INCOME_SOURCE = "SET_OTHER_INCOME_SOURCE";
export const DELETE_OTHER_INCOME_SOURCE = "DELETE_OTHER_INCOME_SOURCE";
export const GET_OTHER_INCOME_SOURCE = "GET_OTHER_INCOME_SOURCE";

export const GET_HOUSE_INCOME_SOURCE = "GET_HOUSE_INCOME_SOURCE";
export const SET_HOUSE_INCOME_SOURCE = "SET_HOUSE_INCOME_SOURCE";
export const UPDATE_HOUSE_INCOME_SOURCE = "UPDATE_HOUSE_INCOME_SOURCE";
export const DELETE_HOUSE_INCOME_SOURCE = "DELETE_HOUSE_INCOME_SOURCE";

// TAX SAVING
export const ADD_DONATIONS = "ADD_DONATIONS";
export const UPDATE_DONATIONS = "UPDATE_DONATIONS";
export const DELETE_DONATIONS = "DELETE_DONATIONS";

// Self tax payments
export const SAVE_TAX_PAID_CHALLAN = "SAVE_TAX_PAID_CHALLAN";
export const UPDATE_TAX_PAID_CHALLAN = "UPDATE_TAX_PAID_CHALLAN";
export const DELETE_TAX_PAID_CHALLAN = "DELETE_TAX_PAID_CHALLAN";

export const GET_DEDUCTIONS = "GET_DEDUCTIONS";
export const SAVE_DEDUCTIONS = "SAVE_DEDUCTIONS";

// Non Salary TDS
export const SAVE_TDS_NON_SALARY = "SAVE_TDS_NON_SALARY";
export const UPDATE_TDS_NON_SALARY = "UPDATE_TDS_NON_SALARY";
export const DELETE_TDS_NON_SALARY = "DELETE_TDS_NON_SALARY";

// TDS on Sale/Rent of Immovable Property
export const SAVE_TDS_IMMOVABLE_PROPERTY = "SAVE_TDS_IMMOVABLE_PROPERTY";
export const UPDATE_TDS_IMMOVABLE_PROPERTY = "UPDATE_TDS_IMMOVABLE_PROPERTY";
export const DELETE_TDS_IMMOVABLE_PROPERTY = "DELETE_TDS_IMMOVABLE_PROPERTY";

// Taxes collected at source
export const SAVE_TAX_COLLECTED = "SAVE_TAX_COLLECTED";
export const UPDATE_TAX_COLLECTED = "UPDATE_TAX_COLLECTED";
export const DELETE_TAX_COLLECTED = "DELETE_TAX_COLLECTED";

//  CAPITAL GAIN  ====================

export const GET_CAPITAL_GAIN = "GET_CAPITAL_GAIN";

export const DELETE_CAPITAL_GAIN = "DELETE_CAPITAL_GAIN";

export const SAVE_STOCKS = "SAVE_STOCKS";
export const UPDATE_STOCKS = "UPDATE_STOCKS";

export const SAVE_SALE_OF_LAND = "SAVE_SALE_OF_LAND";
export const UPDATE_SALE_OF_LAND = "UPDATE_SALE_OF_LAND";

// Bonds & Debentures
export const SAVE_BONDS_DEBENTURES = "SAVE_BONDS_DEBENTURES";
export const UPDATE_BONDS_DEBENTURES = "UPDATE_BONDS_DEBENTURES";

// Deemed Capital Gains =======================================
export const SAVE_CAPITAL_GAIN = "SAVE_CAPITAL_GAIN";
export const UPDATE_CAPITAL_GAIN = "UPDATE_CAPITAL_GAIN";

// Add Any Other Assets =============================================
export const SAVE_OTHER_ASSETS = "SAVE_OTHER_ASSETS";
export const UPDATE_OTHER_ASSETS = "UPDATE_OTHER_ASSETS";

// Add Members ==============================================
export const ADD_MEMBERS = "ADD_MEMBERS";
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";
export const GET_MEMBERS = "GET_MEMBERS";
export const DELETE_MEMBERS = "DELETE_MEMBERS";

// =========== Professional Business =========================
export const GET_PROFESSIONAL_BUSINESS = "GET_PROFESSIONAL_BUSINESS";
export const GET_PROFESSIONAL_BUSINESS3 = "GET_PROFESSIONAL_BUSINESS3";

export const SAVE_DETAILOFPROFESSION_BUSINESS =
  "SAVE_DETAILOFPROFESSION_BUSINESS";
export const UPDATE_DETAILOFPROFESSION_BUSINESS =
  "UPDATE_DETAILOFPROFESSION_BUSINESS";
export const DELETE_DETAILOFPROFESSION_BUSINESS =
  "DELETE_DETAILOFPROFESSION_BUSINESS";
export const GET_DETAILOFPROFESSION_BUSINESS =
  "GET_DETAILOFPROFESSION_BUSINESS";

export const GET_BALANCE_SHEET = `GET_BALANCE_SHEET`;
export const SAVE_BALANCE_SHEET = `SAVE_BALANCE_SHEET`;
export const DEL_BALANCE_SHEET = `DEL_BALANCE_SHEET`;

export const GET_PROFIT_AND_LOSS = `GET_PROFIT_AND_LOSS`;
export const SAVE_PROFIT_AND_LOSS = `SAVE_PROFIT_AND_LOSS`;
export const DEL_PROFIT_AND_LOSS = `DEL_PROFIT_AND_LOSS`;

export const SAVE_INCOME_44ADA = "SAVE_INCOME_44ADA";
export const DELETE_INCOME_44ADA = "DELETE_INCOME_44ADA";

export const SAVE_INCOME_44AD = "SAVE_INCOME_44AD";
export const DELETE_INCOME_44AD = "DELETE_INCOME_44AD";

// ======== Business Details =========================

export const SAVE_BUSINESS_DETAILS_44AE = "SAVE_BUSINESS_DETAILS_44AE";
export const EDIT_BUSINESS_DETAILS_44AE = "EDIT_BUSINESS_DETAILS_44AE";
export const DELETE_BUSINESS_DETAILS_44AE = "DELETE_BUSINESS_DETAILS_44AE";

// ======== Business Details =========================

export const SAVE_GOODS_CARRIAGES = "SAVE_GOODS_CARRIAGES";
export const EDIT_GOODS_CARRIAGES = "EDIT_GOODS_CARRIAGES";
export const DELETE_GOODS_CARRIAGES = "DELETE_GOODS_CARRIAGES";

// ================================== Financial Particulars =====================

export const SAVE_LIABILITIES_ASSETS = "SAVE_LIABILITIES_ASSETS";
export const GET_LIABILITIES_ASSETS = "GET_LIABILITIES_ASSETS";
export const DEL_LIABILITIES_ASSETS = "DEL_LIABILITIES_ASSETS";

// ==================================== Add GST Details ============================

export const SAVE_GST_DETAILS = "SAVE_GST_DETAILS";
export const EDIT_GST_DETAILS = "EDIT_GST_DETAILS";
export const DELETE_GST_DETAILS = "DELETE_GST_DETAILS";

// ===================================  Carry Forward of Losses  =========================
export const GET_CARRY_FORWARD_LOSS = "GET_CARRY_FORWARD_LOSS";

export const SAVE_PREVIOUS_YEAR_LOSSES = "SAVE_PREVIOUS_YEAR_LOSSES";
export const DELETE_PREVIOUS_YEAR_LOSSES = "DELETE_PREVIOUS_YEAR_LOSSES";
export const UPDATE_PREVIOUS_YEAR_LOSSES = "UPDATE_PREVIOUS_YEAR_LOSSES";

export const SAVE_DEPRECIATION_LOSSE = "SAVE_DEPRECIATION_LOSSE";
export const DELETE_DEPRECIATION_LOSSE = "DELETE_DEPRECIATION_LOSSE";
export const UPDATE_DEPRECIATION_LOSSE = "UPDATE_DEPRECIATION_LOSSE";

export const SAVE_FILING_BUE_DATE = "SAVE_FILING_BUE_DATE";
export const DELETE_FILING_BUE_DATE = "DELETE_FILING_BUE_DATE";
export const UPDATE_FILING_BUE_DATE = "UPDATE_FILING_BUE_DATE";

// ============================= Professional, Freelancing and Business Income ===========================
export const GET_PROFESSIONAL_INCOME = "GET_PROFESSIONAL_INCOME";

// --- Remuneration from Partnership Firms
export const GET_REMUNERATION = "GET_REMUNERATION";
export const SAVE_REMUNERATION = "SAVE_REMUNERATION";
export const DELETE_REMUNERATION = "DELETE_REMUNERATION";
export const UPDATE_REMUNERATION = "UPDATE_REMUNERATION";
// --- Books of Account are Not Maintained
export const GET_BOOKS_ACCOUNT_NOT_MAINTAINED =
  "GET_BOOKS_ACCOUNT_NOT_MAINTAINED";
export const SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED =
  "SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED";
export const DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED =
  "DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED";
export const UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED =
  "UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED";

//  --- Speculative Income ---

export const SAVE_SPECULATIVE_INCOME = "SAVE_SPECULATIVE_INCOME";
export const GET_SPECULATIVE_INCOME = "GET_SPECULATIVE_INCOME";
export const DELETE_SPECULATIVE_INCOME = "DELETE_SPECULATIVE_INCOME";
export const EDIT_SPECULATIVE_INCOME = "EDIT_SPECULATIVE_INCOME";

// ------- Financial Statements & Schedules >  Schedule BP

export const SAVE_SCHEDULE_BP = "SAVE_SCHEDULE_BP";
export const GET_SCHEDULE_BP = "GET_SCHEDULE_BP";
export const EDIT_SCHEDULE_BP = "EDIT_SCHEDULE_BP";
export const DELETE_SCHEDULE_BP = "DELETE_SCHEDULE_BP";

// Financial Statements & Schedules >  Schedule BP > other sections > Additions to Profit and Loss Account

export const SAVE_ADDITION_TO_P_L = "SAVE_ADDITION_TO_P_L";
export const GET_ADDITION_TO_P_L = "GET_ADDITION_TO_P_L";
export const EDIT_ADDITION_TO_P_L = "EDIT_ADDITION_TO_P_L";
export const DELETE_ADDITION_TO_P_L = "DELETE_ADDITION_TO_P_L";

// Financial Statements & Schedules >  Schedule BP > other sections > Summary of Incomes under section 40 and 43B

export const SAVE_SECTION_40_AND_43B = "SAVE_SECTION_40_AND_43B";
export const GET_SECTION_40_AND_43B = "GET_SECTION_40_AND_43B";
export const EDIT_SECTION_40_AND_43B = "EDIT_SECTION_40_AND_43B";
export const DELETE_SECTION_40_AND_43B = "DELETE_SECTION_40_AND_43B";

// Other business income > Audit Information >  Audit Information

export const GET_AUDITOR_DETAILS = "GET_AUDITOR_DETAILS";
export const SAVE_AUDITOR_DETAILS = "SAVE_AUDITOR_DETAILS";
export const DELETE_AUDITOR_DETAILS = "DELETE_AUDITOR_DETAILS";

// Other business income > Audit Information >  Other audits
export const GET_OTHER_AUDITS_ACTION = "GET_OTHER_AUDITS_ACTION";
export const SAVE_OTHER_AUDITS_ACTION = "SAVE_OTHER_AUDITS_ACTION";
export const EDIT_OTHER_AUDITS_ACTION = "EDIT_OTHER_AUDITS_ACTION";
export const DELETE_OTHER_AUDITS_ACTION = "DELETE_OTHER_AUDITS_ACTION";

// Other business income > Audit Information > Audit under Act other than I-T Act
export const GET_AUDIT_UNDER_IT_ACT = "GET_AUDIT_UNDER_IT_ACT";
export const SAVE_AUDIT_UNDER_IT_ACT = "SAVE_AUDIT_UNDER_IT_ACT";
export const EDIT_AUDIT_UNDER_IT_ACT = "EDIT_AUDIT_UNDER_IT_ACT";
export const DELETE_AUDIT_UNDER_IT_ACT = "DELETE_AUDIT_UNDER_IT_ACT";

// Quantitative Details >  Quantitative Details

export const GET_TRADING_CONCERN = "GET_TRADING_CONCERN";
export const SAVE_TRADING_CONCERN = "SAVE_TRADING_CONCERN";
export const EDIT_TRADING_CONCERN = "EDIT_TRADING_CONCERN";
export const DELETE_TRADING_CONCERN = "DELETE_TRADING_CONCERN";

// Quantitative Details > Details of Manufacturing Concern (Raw Materials) and  (Finished Products)
export const GET_MANUFACTURING_CONCERN = "GET_MANUFACTURING_CONCERN";
export const SAVE_MANUFACTURING_CONCERN = "SAVE_MANUFACTURING_CONCERN";
export const EDIT_MANUFACTURING_CONCERN = "EDIT_MANUFACTURING_CONCERN";
export const DELETE_MANUFACTURING_CONCERN = "DELETE_MANUFACTURING_CONCERN";

