import React, { useEffect, useState } from "react";
import { IoLanguage } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import { getLanguagesAction } from "../../redux/platformSetting/action";
import styled from "styled-components";
export default function LanguageSelect() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("English");

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch();
  const languages = [
    {
        "id": 1,
        "createdAt": 1710323605707,
        "updatedAt": 1710323605707,
        "languageName": "English",
        "language": "en",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 2,
        "createdAt": 1710323605845,
        "updatedAt": 1710323605845,
        "languageName": "हिंदी",
        "language": "hi",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 3,
        "createdAt": 1710323605982,
        "updatedAt": 1710323605982,
        "languageName": "বাংলা",
        "language": "bn",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 4,
        "createdAt": 1710323606380,
        "updatedAt": 1710323606380,
        "languageName": "ગુજરાતી",
        "language": "gu",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 5,
        "createdAt": 1710323606675,
        "updatedAt": 1710323606675,
        "languageName": "ਪੰਜਾਬੀ",
        "language": "pa",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 6,
        "createdAt": 1710323607053,
        "updatedAt": 1710323607053,
        "languageName": "اردو",
        "language": "ur",
        "languageFlag": "",
        "isLeftToRight": 0,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 7,
        "createdAt": 1710323607340,
        "updatedAt": 1710323607340,
        "languageName": "தமிழ்",
        "language": "ta",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 8,
        "createdAt": 1710323607537,
        "updatedAt": 1710323607537,
        "languageName": "తెలుగు",
        "language": "te",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 9,
        "createdAt": 1710323607667,
        "updatedAt": 1710323607667,
        "languageName": "മലയാളം",
        "language": "ml",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 10,
        "createdAt": 1710323607735,
        "updatedAt": 1710323607735,
        "languageName": "ಕನ್ನಡ",
        "language": "kn",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 11,
        "createdAt": 1710323607774,
        "updatedAt": 1710323607774,
        "languageName": "অসমীয়া",
        "language": "as",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 12,
        "createdAt": 1710323607827,
        "updatedAt": 1710323607827,
        "languageName": "ଓଡ଼ିଆ",
        "language": "or",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    },
    {
        "id": 13,
        "createdAt": 1710323607878,
        "updatedAt": 1710323607878,
        "languageName": "मराठी",
        "language": "mr",
        "languageFlag": "",
        "isLeftToRight": 1,
        "createdBy": 0,
        "updatedBy": 0,
        "isActive": 1,
        "isDeleted": 0
    }
];

  useEffect(() => {
    // dispatch(
    //   getLanguagesAction((data) => console.log("getLanguagesAction ===>", data))
    // );
  }, []);

  useEffect(()=>{
    if(isOpen === true){
      document.body.style.overflow  ='hidden';
    }else{
      document.body.style.overflow =  'auto';
    }
  },[isOpen])

  return (
    <Root>
      <button
        onClick={() => setIsOpen(true)}
        className="block p-2 rounded"
        style={{ background: addOpacity(theme.primary_bg, 0.8) }}
      >
        <IoLanguage />
      </button>
      {/* <div></div> */}
      {isOpen && (
        <div>
          <div
            className="absolute right-2  z-20 rounded-md p-4 section_box"
            style={{ backgroundColor: theme.primary_bg }}
          >
            <div className="grid grid-cols-2 items-center space-x-2 gap-4">
              {languages.map((item) => {
                return (
                  <button className="inline-flex items-center p-2.5 rounded w-full cursor-pointer"
                      style={{
                        backgroundColor: addOpacity(theme.secandary_bg, 0.3),
                        margin: "0px",
                      }}>
                      <input
                        type="radio"
                        className="form-radio text-indigo-600 focus:ring-indigo-500 h-4 w-4 mb-0"
                        name="options"
                        value={item.name}
                        checked={selectedOption === item.name}
                        onChange={handleOptionChange}
                      />
                      <span className="">{item.languageName}</span>
                  </button>
                );
              })}
            </div>
          </div>
          <span
            className="fixed inset-0 bg-[#15141487] z-10"
            style={{ backdropFilter: 'blur(1px)' }}
            onClick={() => {
              setIsOpen(false);
            }}
          ></span>
        </div>
      )}
    </Root>
  );
}

const Root = styled.section`
position:relative;
.section_box{
  width:300px;
  top: 42px;
}
`