import { LOGOUT_USER, SET_USER_DATA } from "../constant";

const initialState = {
  userDetails: {},
};

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userDetails: action.payload,
      };
      case LOGOUT_USER:{
        localStorage.removeItem('token'); 
        return{
          ...state,userDetails:{}
        }
      }
    default:
      return state;
  }
};
