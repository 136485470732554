import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../../../styledComponent/Inputs/CheckBox";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import FormDialogBox from "../../../../DialogBox/FormDialogBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Select from "../../../../../styledComponent/Inputs/Select";
import {
  deleteAuditUnderITActAction,
  deleteOtherAuditsAction,
  editAuditUnderITActAction,
  editOtherAuditsAction,
  getAuditorDetailsAction,
  saveAuditorDetailsAction,
  saveAuditUnderITActAction,
  saveOtherAuditsAction,
} from "../../../../../redux/itr/action";
import { toast } from "react-toastify";
import Input from "../../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../../utils/helpers";

export default function AuditInformation({ data, handleCallback }) {
  const [editAuditDetails, setEditAuditDetails] = useState(false);
  const [editAuditUnderAct, setEditAuditUnderAct] = useState(false);
  const [loader, setLoader] = useState(true);
  const [otherAuditsData, setOtherAuditsData] = useState({
    section: "",
    date: "",
  });
  const [otherItActData, setOtherItActData] = useState({
    actCode: "",
    otherActDescription: "",
    section: "",
    date: "",
  });
  const [auditInfo, setAuditInfo] = useState({});

  const [auditorData, setAuditorData] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const { ItrData } = useSelector((s) => s.itr);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();

  const handleSubmitOtherAuditsApi = (data) => {
    data = { ...data, itrId: ItrData.id };
    if (data.id) {
      delete data.itrId;
    }
    const action = data.id ? editOtherAuditsAction : saveOtherAuditsAction;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          toast.success(`Successfully ${data.id ? "updated" : "added"} data`);
          setFormData({
            ...formData,
            otherAudits: data.id
              ? formData.otherAudits.map((item) =>
                  item.id === data.id ? e.data : item
                )
              : [...formData.otherAudits, e.data],
          });

          setOtherAuditsData({});
          setEditAuditDetails(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const handleSubmitITActApi = (data) => {
    data = { ...data, itrId: ItrData.id };
    if (data.id) {
      delete data.itrId;
    }
    const action = data.id
      ? editAuditUnderITActAction
      : saveAuditUnderITActAction;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          toast.success(`Successfully ${data.id ? "updated" : "added"} data`);

          setFormData({
            ...formData,
            auditUnderITAct: data.id
              ? formData.auditUnderITAct.map((item) =>
                  item.id === data.id ? e.data : item
                )
              : [...formData.auditUnderITAct, e.data],
          });

          setOtherItActData({});
          setEditAuditUnderAct(false);
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const handleSubmitAuditorDetailsApi = (data) => {
    data = {...data , itrId: ItrData.id,}
    dispatch(
      saveAuditorDetailsAction(data, (e) => {
        if (e.status === 200) {
          toast.success(`Successfully ${data.id ? "updated" : "added"} data`);
          handleCallback(e.data);
          setAuditInfo({});
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const handleDeleteData = (type, id) => {
    if (type == "OtherAudits") {
      dispatch(
        deleteOtherAuditsAction({ id }, (e) => {
          if (e.status === 200) {
            toast.success("Successfully delete Record");
            setFormData({
              ...formData,
              otherAudits: formData.otherAudits.filter((i) => i.id != id),
            });
          } else {
            toast.error("Something Went wrong");
          }
          setLoader(false);
        })
      );
    } else if (type == "auditUnderITAct") {
      dispatch(
        deleteAuditUnderITActAction({ id }, (e) => {
          if (e.status === 200) {
            toast.success("Successfully delete Record");
            setFormData({
              ...formData,
              auditUnderITAct: formData.auditUnderITAct.filter(
                (i) => i.id != id
              ),
            });
          } else {
            toast.error("Something Went wrong");
          }
          setLoader(false);
        })
      );
    }
  };

  const getAuditorDetailsApi = async () => {
    setLoading(true);
    let id = ItrData.id;
    dispatch(
      getAuditorDetailsAction(id, (e) => {
        if (e.status == 200) {
          setAuditorData(e.data);
          setFormData(e.data.data);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    getAuditorDetailsApi();
    setAuditInfo({
      liableSection44AA: data?.liableSection44AA || false,
      declaringIncomeUnder44Others: data?.declaringIncomeUnder44Others || false,
      saleTrunover1to10CR: data?.saleTrunover1to10CR || "",
      amountCash5Percent: data?.amountCash5Percent || false,
      paymentCash5Percent: data?.paymentCash5Percent || false,
      liableForAudit44AB: data?.liableForAudit44AB || false,
      liableAndAuditByAccountant44AB:
        data?.liableAndAuditByAccountant44AB || false,
      nameOfAuditor: data?.nameOfAuditor || "",
      auditorName: data?.auditorName || "",
      membershipNumAuditor: data?.membershipNumAuditor || "",
      auditorRegistrationNum: data?.auditorRegistrationNum || "",
      auditFirmPan: data?.auditFirmPan || "",
      aadharOfAuditor: data?.aadharOfAuditor || "",
      dateOfReport: data?.dateOfReport || "",
      dateOfFurnishing: data?.dateOfFurnishing || "",
      libleToFurnishUnder92E: data?.libleToFurnishUnder92E || false,
      nonResidentPE: data?.nonResidentPE || false,
      // id: data?.id || "",
      
    });
  }, [data]);

  return (
    <div>
      <Formik
        initialValues={auditInfo}
        enableReinitialize={true}
        // validationSchema={validationSchema}
      
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <h4>{strings.income.audit_information}</h4>
              <hr />
              <CheckBox
                value={values.liableSection44AA}
                label={strings.income.liable_maintain_account_section_44aa}
                handleChange={(e) =>
                  setValues({
                    ...values,
                    liableSection44AA: !values.liableSection44AA,
                  })
                }
              />

              <CheckBox
                value={values.declaringIncomeUnder44Others}
                label={
                  strings.income.declaring_income_under_section_44ae_44ad_44ada
                }
                handleChange={(e) =>
                  setValues({
                    ...values,
                    declaringIncomeUnder44Others:
                      !values.declaringIncomeUnder44Others,
                  })
                }
              />
              {values.declaringIncomeUnder44Others && (
                <StyleFormBox>
                  <label>
                    {strings.income.total_sale_of_business_in_between}
                  </label>
                  <CheckBox
                    value={values.saleTrunover1to10CR === "yes" ? true : false}
                    label={strings.placeholder.yes}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        saleTrunover1to10CR: "yes",
                      })
                    }
                  />
                  {values.saleTrunover1to10CR === "yes" && (
                    <StyleFormBox>
                      <CheckBox
                        value={values.amountCash5Percent}
                        label={strings.income.aggregate_amount_received}
                        handleChange={(e) =>
                          setValues({
                            ...values,
                            amountCash5Percent: !values.amountCash5Percent,
                            paymentCash5Percent: false,
                          })
                        }
                      />
                      <CheckBox
                        value={values.paymentCash5Percent}
                        label={
                          strings.income.aggregate_all_payment_mode_in_cash
                        }
                        handleChange={(e) =>
                          setValues({
                            ...values,
                            paymentCash5Percent: !values.paymentCash5Percent,
                            amountCash5Percent: false,
                          })
                        }
                      />
                    </StyleFormBox>
                  )}
                  <CheckBox
                    value={values.saleTrunover1to10CR == "under" ? true : false}
                    label={strings.income.no_turnover_does_not_exceed_1cr}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        saleTrunover1to10CR: "under",
                      })
                    }
                  />
                  <CheckBox
                    value={values.saleTrunover1to10CR == "above" ? true : false}
                    label={strings.income.no_turnover_exceeds_10cr}
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        saleTrunover1to10CR: "above",
                      })
                    }
                  />
                </StyleFormBox>
              )}

              <CheckBox
                value={values.liableForAudit44AB}
                label={strings.income.liable_for_audit_under_section_44ab}
                handleChange={(e) =>
                  setValues({
                    ...values,
                    liableForAudit44AB: !values.liableForAudit44AB,
                  })
                }
              />
              {values.liableForAudit44AB && (
                <StyleFormBox>
                  <CheckBox
                    value={values.liableAndAuditByAccountant44AB}
                    label={
                      strings.income
                        .liable_for_audit_under_section_44ab_by_accountant
                    }
                    handleChange={(e) =>
                      setValues({
                        ...values,
                        liableAndAuditByAccountant44AB:
                          !values.liableAndAuditByAccountant44AB,
                      })
                    }
                  />
                </StyleFormBox>
              )}
              <div className="flex_input my-3">
                <label>
                  {strings.income.name_of_auditor_proprietorship_firm}
                </label>
                <Input
                  name={"nameOfAuditor"}
                  error={errors.nameOfAuditor}
                  value={values.nameOfAuditor}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.auditor_name} </label>
                <Input
                  name={"auditorName"}
                  error={errors.auditorName}
                  value={values.auditorName}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.membership_number_of_auditor} </label>
                <Input
                  name={"membershipNumAuditor"}
                  inputType="number"
                  error={errors.membershipNumAuditor}
                  value={values.membershipNumAuditor}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.auditor_registration_number} </label>
                <Input
                  name={"auditorRegistrationNum"}
                  error={errors.auditorRegistrationNum}
                  value={values.auditorRegistrationNum}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.audit_firm_pan}</label>
                <Input
                  inputType={"pan"}
                  name={"auditFirmPan"}
                  error={errors.auditFirmPan}
                  value={values.auditFirmPan}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.aadhar_of_auditor} </label>
                <Input
                  inputType={"aadhaar"}
                  name={"aadharOfAuditor"}
                  error={errors.aadharOfAuditor}
                  value={values.aadharOfAuditor}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.date_of_report_the_audit}</label>
                <Input
                  inputType={"date"}
                  name={"dateOfReport"}
                  error={errors.dateOfReport}
                  value={values.dateOfReport}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.date_of_funishing_audit_report}</label>
                <Input
                  inputType={"date"}
                  name={"dateOfFurnishing"}
                  error={errors.dateOfFurnishing}
                  value={values.dateOfFurnishing}
                  onChange={handleChange}
                />
              </div>
              <CheckBox
                value={values.libleToFurnishUnder92E}
                label={strings.income.taxpayer_liable_furnish_under_section_92e}
                handleChange={(e) =>
                  setValues({
                    ...values,
                    libleToFurnishUnder92E: !values.libleToFurnishUnder92E,
                  })
                }
              />
              <CheckBox
                value={values.nonResidentPE}
                label={
                  strings.income.case_of_nonresident_permanent_establishment
                }
                handleChange={(e) =>
                  setValues({
                    ...values,
                    nonResidentPE: !values.nonResidentPE,
                  })
                }
              />
            </StyleFormBox>

            {/* Other audits  */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.other_audits}</h4>
                <button
                  onClick={() => {
                    setEditAuditDetails(true);
                  }}
                  className="add_button"
                >
                  {strings.button.add}
                </button>
              </div>
              <hr className="my-4" />
              <p>
                {strings.income.liable_furnish_audit_report_under_income_tax}
              </p>

              {formData?.otherAudits?.map((i) => (
                <StyleFormBox>
                  <div>
                    <div className="flex items-center justify-between">
                      <h4>Section : {i?.section?.name}</h4>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setEditAuditDetails(true);
                            setOtherAuditsData({
                              section: i.section.id,
                              date: i.date,
                              id: i.id,
                            });
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() => handleDeleteData("OtherAudits", i.id)}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                    <div>
                      <p>Date : {i.date}</p>
                    </div>
                  </div>
                </StyleFormBox>
              ))}
            </StyleFormBox>

            {/* Audit under Act other than I-T Act */}
            <StyleFormBox>
              <div className="flex items-center justify-between">
                <h4>{strings.income.audit_under_act_it_act}</h4>

                <button
                  onClick={() => setEditAuditUnderAct(true)}
                  className="add_button"
                >
                  {strings.button.add}
                </button>
              </div>
              <hr className="my-4" />
              <p>{strings.income.liable_furnish_audit_report_under_act}</p>

              {formData?.auditUnderITAct?.map((i) => (
                <StyleFormBox>
                  <div>
                    <div className="flex items-center justify-between">
                      <h4>{i?.actCode?.name}</h4>
                      <div className="action_buttons">
                        <button
                          className="edit_btn"
                          onClick={() => {
                            setEditAuditUnderAct(true);
                            setOtherItActData({
                              actCode: i.actCode.id,
                              otherActDescription: i.otherActDescription,
                              section: i.section.id,
                              date: i.date,
                              id: i.id,
                            });
                          }}
                        >
                          <FaRegEdit />
                        </button>
                        <button
                          className="delete_btn"
                          onClick={() =>
                            handleDeleteData("auditUnderITAct", i.id)
                          }
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    </div>
                    <div>
                      <p>Section : {i?.section?.name}</p>
                      <p>Date : {i.date}</p>
                      <p>Description : {i.otherActDescription}</p>
                    </div>
                  </div>
                </StyleFormBox>
              ))}
            </StyleFormBox>

            <button
              className="primary_button"
              onClick={() =>
                handleSubmitAuditorDetailsApi(removeEmptyKeys(values))
              }
            >
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>

      <FormDialogBox
        open={editAuditDetails}
        formTitle={strings.income.additional_audits}
        handleClose={() => {
          setEditAuditDetails(false);
          setOtherAuditsData({});
        }}
      >
        <Formik
          initialValues={otherAuditsData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitOtherAuditsApi(removeEmptyKeys(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex_input my-4">
                <label>{strings.income.section}</label>
                <Select
                  name={"section"}
                  error={errors.section}
                  value={values?.section}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {auditorData?.section?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input my-4">
                <label>{strings.profile.date}</label>
                <Input
                  inputType={"date"}
                  name={"date"}
                  error={errors.date}
                  value={values.date}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="primary_button">
                {strings.button.save_and_close}
              </button>
            </form>
          )}
        </Formik>
      </FormDialogBox>

      <FormDialogBox
        open={editAuditUnderAct}
        formTitle={strings.income.audit_under_act_it_act}
        handleClose={() => {
          setEditAuditUnderAct(false);
          setOtherItActData({});
        }}
      >
        <Formik
          initialValues={otherItActData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmitITActApi(removeEmptyKeys(values));
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setValues }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex_input my-4">
                <label>{strings.income.act_code}</label>
                <Select
                  name={"actCode"}
                  error={errors.actCode}
                  value={values?.actCode}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {auditorData?.actCode?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="flex_input my-4">
                <label>{strings.label.description}</label>
                <Input
                  inputType={"message"}
                  name={"otherActDescription"}
                  error={errors.otherActDescription}
                  value={values.otherActDescription}
                  onChange={handleChange}
                />
              </div>

              <div className="flex_input my-4">
                <label>{strings.income.section}</label>
                <Select
                  name={"section"}
                  error={errors.section}
                  value={values?.section}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {auditorData?.section?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="flex_input my-4">
                <label>{strings.profile.date}</label>
                <Input
                  inputType={"date"}
                  name={"date"}
                  error={errors.date}
                  value={values.date}
                  onChange={handleChange}
                />
              </div>
              <button type="submit" className="primary_button">
                {strings.button.save_and_close}
              </button>
            </form>
          )}
        </Formik>
      </FormDialogBox>
    </div>
  );
}
