import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { addOpacity } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import PrevYearLosses from "./PrevYearLosses";
import DepreciationLoss from "./DepreciationLoss";
import FilingDueDates from "./FilingDueDates";
import {
  deleteDepreciationLossAction,
  deleteFilingDueDateAction,
  deletePreviousYearLossesAction,
  getCarryForwardLossAction,
} from "../../../../redux/itr/action";
import { toast } from "react-toastify";

export default function CarryForwardLoss() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loading, setLoading] = useState(false);
  const [challanData, setChallanData] = useState([]);

  // Form Handle State
  const [preYearLossBox, setPreYearLossBox] = useState(false);
  const [depreciationLoss, setDepreciationLoss] = useState(false);
  const [filingDueDates, setFilingDueDates] = useState(false);

  const [formData, setFormData] = useState({});
  const [editData, setEditData] = useState({});

  const dispatch = useDispatch();

  const handleDeleteRecord = (id, type, deductionId) => {
    setLoading(true);
    if (type == "previousYearLosses") {
      dispatch(
        deletePreviousYearLossesAction({ id, deductionId }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              previousYearLosses: formData.previousYearLosses.filter(
                (i) => i.id !== id
              ),
            });
            toast.success("Successfully delete data ");
          }
        })
      );
    } else if (type == "depreciationLoss") {
      dispatch(
        deleteDepreciationLossAction({ id, deductionId }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              depreciationLoss: formData.depreciationLoss.filter(
                (i) => i.id !== id
              ),
            });
            toast.success("Successfully delete data ");
          }
        })
      );
    } else if (type == "filingDueDates") {
      dispatch(
        deleteFilingDueDateAction({ itrId: ItrData.id }, (e) => {
          if (e.status == 200) {
            setFormData({
              ...formData,
              filingDueDateLoss: [],
            });
            setEditData({});
            setFilingDueDates(false);
            toast.success("Successfully Clear data ");
          }
        })
      );
    }
    setLoading(false);
  };

  const getDeductionsData = () => {
    setLoading(true);
    dispatch(
      getCarryForwardLossAction(ItrData.id, (e) => {
        if (e.status == 200) {
          setFormData(e.data);
        }
        setLoading(false);
      })
    );
  };

  useEffect(() => {
    getDeductionsData();
  }, []);

  return (
    <AccordianBox
      title={strings.tax_saving.carry_forward_loss}
      description={
        strings.tax_saving.loss_from_capital_gains_declared_in_last_8years
      }
    >
      {/* ============== Details of Previous Years' Losses ========================= */}
      <StyleFormBox>
        <div className="flex_input">
          <div>
            <h5 className="my-3">
              {strings.tax_saving.details_previous_years_losses}
            </h5>
            <p>
              {
                strings.tax_saving
                  .losses_from_previous_years_against_income_of_ay
              }
            </p>
          </div>
          <button
            className="add_button"
            style={{ flex: 0 }}
            onClick={() => setPreYearLossBox(!preYearLossBox)}
          >
            {strings.button.add}
          </button>
        </div>

        {formData.previousYearLosses?.map((item, ix) => (
          <div
            className="flex gap-2 p-3 my-3 justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
            key={ix}
          >
            <div>
              <h4 className="mr-auto">
                {strings.tax_saving.assessment_year_loss} {item.assessmentYear}
              </h4>
              <p>
                {strings.tax_saving.loss_category} : {item?.lossCategory?.name}
              </p>
              <p>
                {strings.tax_saving.loss_amount} : {item.lossAmount}
              </p>
            </div>
            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setEditData(item);
                  setPreYearLossBox(true);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  handleDeleteRecord(
                    item.id,
                    "previousYearLosses",
                    item.deductionId
                  )
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}
      </StyleFormBox>

      {/*  ============== depreciation_loss_from_previous_years ==================  */}
      <StyleFormBox>
        <div className="flex_input">
          <div>
            <h5 className="my-3">
              {strings.tax_saving.depreciation_loss_from_previous_years}
            </h5>
            <p>{strings.tax_saving.add_depriciation_losses_from_prev_year}</p>
          </div>
          <button
            className="add_button"
            style={{ flex: 0 }}
            onClick={() => setDepreciationLoss(!depreciationLoss)}
          >
            {strings.button.add}
          </button>
        </div>

        {formData.depreciationLoss?.map((item, ix) => (
          <div
            className="flex gap-2 p-3 my-3 justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
            key={ix}
          >
            <div>
              <h4 className="mr-auto">
                {strings.tax_saving.assessment_year_loss} {item.assessmentYear}
              </h4>

              <p>
                {strings.tax_saving.loss_amount} : {item.amount}
              </p>
            </div>

            <div className="action_buttons">
              <button
                className="edit_btn"
                onClick={() => {
                  setEditData(item);
                  setDepreciationLoss(true);
                }}
              >
                <FaRegEdit />
              </button>
              <button
                className="delete_btn"
                onClick={() =>
                  handleDeleteRecord(
                    item.id,
                    "depreciationLoss",
                    item.deductionId
                  )
                }
              >
                <RiDeleteBin6Fill />
              </button>
            </div>
          </div>
        ))}
      </StyleFormBox>

      {/* ============== Filing and Due Dates for Loss Set-off in Previous Years =================== */}

      <StyleFormBox>
        <div className="flex_input">
          <div>
            <h5 className="my-3">
              {strings.tax_saving.filing_due_date_loss_in_previous_years}
            </h5>
          </div>
          <button
            className="add_button"
            style={{ flex: 0 }}
            onClick={() => {
              if (formData?.filingDueDateLoss?.length > 1) {
                setEditData(formData?.filingDueDateLoss);
              }
              setFilingDueDates(!filingDueDates);
            }}
          >
            {strings.button.edit}
          </button>
        </div>

        {formData.filingDueDateLoss?.length > 0 ? (
          <div
            className="flex gap-2 p-3 my-3 justify-between"
            style={{
              background: addOpacity(theme.primary_bg, 0.2),
              borderRadius: "10px",
            }}
          >
            <div>
              <h4 className="mr-auto">
                {strings.tax_saving.assessment_year_loss}
                {"  "}
                {
                  formData?.filingDueDateLoss[
                    formData?.filingDueDateLoss.length - 1
                  ]?.assessmentYear
                }{" "}
                - {formData?.filingDueDateLoss[0]?.assessmentYear}
              </h4>
            </div>
          </div>
        ) : (
          ""
        )}
      </StyleFormBox>

      {/* -----------------  Details of Previous Years' Losses form  ---------------------*/}
      <FormDialogBox
        open={preYearLossBox}
        formTitle={strings.tax_saving.details_previous_years_losses}
        handleClose={() => {
          setPreYearLossBox(false);
        }}
      >
        <PrevYearLosses
          handleClose={() => {
            setPreYearLossBox(false);
            setEditData({});
          }}
          editData={editData}
          AssessmentYear={formData.assessmentYearForLoss}
          LossCategory={formData.lossCategory}
          handleCallback={(type, data) => {
            if (data.status === 200) {
              const updatedLosses =
                type === "edit"
                  ? formData.previousYearLosses.map((item) =>
                      item.id === data.data[0].id ? data.data[0] : item
                    )
                  : [...formData.previousYearLosses, data.data];

              setFormData((prev) => ({
                ...prev,
                previousYearLosses: updatedLosses,
              }));
              setEditData({});
            }
            setPreYearLossBox(false);
          }}
        />
      </FormDialogBox>

      {/* -----------------  Depreciation Loss from Previous Years form  ---------------------*/}
      <FormDialogBox
        open={depreciationLoss}
        formTitle={strings.tax_saving.depreciation_loss_from_previous_years}
        handleClose={() => {
          setDepreciationLoss(false);
        }}
      >
        <DepreciationLoss
          editData={editData}
          AssessmentYear={formData.assessmentYearForLoss}
          handleCallback={(type, data) => {
            if (data.status === 200) {
              const updatedLosses =
                type === "edit"
                  ? formData.depreciationLoss.map((item) =>
                      item.id === data.data[0].id ? data.data[0] : item
                    )
                  : [...formData.depreciationLoss, data.data];

              setFormData((prev) => ({
                ...prev,
                depreciationLoss: updatedLosses,
              }));
              setEditData({});
            }
            setDepreciationLoss(false);
          }}
        />
      </FormDialogBox>

      {/* -----------------  Depreciation Loss from Previous Years form  ---------------------*/}
      <FormDialogBox
        open={filingDueDates}
        formTitle={strings.tax_saving.filing_due_date_loss_in_previous_years}
        handleClose={() => {
          setFilingDueDates(false);
        }}
      >
        <FilingDueDates
          editData={editData}
          AssessmentYear={formData.assessmentYearForLoss}
          handleCallback={(data) => {
            if (data.status === 200) {
              setFormData((prev) => ({
                ...prev,
                filingDueDateLoss: data.data,
              }));
              setEditData({});
            }
            setFilingDueDates(false);
          }}
          clearRecordCallback={() => {
            handleDeleteRecord("", "filingDueDates", "");
          }}
        />
      </FormDialogBox>
    </AccordianBox>
  );
}
