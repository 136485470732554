import React, { useEffect, useState } from "react";
import { FaBrush } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../../utils/helpers";
import { changeTheme, getUserThemeAction } from "../../redux/platformSetting/action";
import { MdDarkMode ,MdLightMode} from "react-icons/md";
import axios from "axios";

export default function ThemeSelect() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [themeType , setThemeType] = useState('')
  const { themeMode, theme,activeTheme } = useSelector((s) => s.platformSetting);
  const themes = ["Blue","Orange","Purple"];
  const dispatch = useDispatch();
  const handleCallback = (e)=>{
    console.log('hanccallback =====>',e)
  }
  const getUserThemeApi = async()=>{
    dispatch(getUserThemeAction())
    // try{
      
    //   const res = await axios.get('https://samosatax.sdnaprod.com/user/getUserTheme')
    //   console.log('res',res)
    // }catch(err){
    //   console.log(err)
    // }
  }
  const allTheme = {
    LightBlue: {
      primary_bg: '#2A8ED6',
      secandary_bg: '#FFFFFF',
      tertiary_bg: '#EBEBEB',
      primary_txt: '#151515',
      secandary_txt: '#FAFAFA',
      border_clr: '#DDDDDD',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
    DarkBlue: {
      primary_bg: '#2A8ED6',
      secandary_bg: '#1F1F1F',
      tertiary_bg: '#101010',
      primary_txt: '#FFFFFF',
      secandary_txt: '#121212',
      border_clr: '#2F2F2F',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
    DarkOrange: {
      primary_bg: '#FFA53B',
      secandary_bg: '#1F1F1F',
      tertiary_bg: '#101010',
      primary_txt: '#FFFFFF',
      secandary_txt: '#121212',
      border_clr: '#2F2F2F',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
    LightOrange: {
      primary_bg: '#FFA53B',
      secandary_bg: '#FFFFFF',
      tertiary_bg: '#EBEBEB',
      primary_txt: '#151515',
      secandary_txt: '#FAFAFA',
      border_clr: '#DDDDDD',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
    DarkPurple: {
      primary_bg: '#B938E7',
      secandary_bg: '#1F1F1F',
      tertiary_bg: '#101010',
      primary_txt: '#FFFFFF',
      secandary_txt: '#121212',
      border_clr: '#2F2F2F',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
    LightPurple: {
      primary_bg: '#B938E7',
      secandary_bg: '#FFFFFF',
      tertiary_bg: '#EBEBEB',
      primary_txt: '#151515',
      secandary_txt: '#FAFAFA',
      border_clr: '#DDDDDD',
      green_txt: '#58C232',
      red_txt: '#D03838',
    },
  };

  const handleChangeTheme = (value,type) => {
    let object = {}
    if(type == 'theme'){
      setSelectedTheme(value);
      object = allTheme[themeType+value];
      dispatch(changeTheme(object,themeType,value));
    }else{
      setThemeType(value)
      object = allTheme[value+selectedTheme];
      dispatch(changeTheme(object,value,selectedTheme));
    }
  };

useEffect(()=>{
if(themeMode && activeTheme){
  setThemeType(themeMode)
  setSelectedTheme(activeTheme)
}
},[themeMode,activeTheme])

  useEffect(()=>{
    getUserThemeApi()
  },[])
  return (
    <div className="flex gap-2">
      <button
        onClick={() => setIsOpen(true)}
        className="block p-2 rounded"
        style={{ background: addOpacity(theme.primary_bg, 0.8) }}
      >
      <FaBrush />
      </button>
      <button
        onClick={() => {handleChangeTheme(themeType == 'Dark' ? 'Light':'Dark' , 'mode')}}
        className="block p-2 rounded"
        style={{ background: addOpacity(theme.primary_bg, 0.8) }}
      >
      {themeType == 'Dark' ? <MdDarkMode /> :  <MdLightMode />}
      </button>
      {isOpen && (
        <>
          <div
            className="absolute right-2 top-16 z-20 rounded-md p-4"
            style={{ backgroundColor: theme.primary_bg }}
          >
            <div className="grid items-center space-x-2 gap-4">
              {themes.map((item,ix) => {
                return (
                  <label
                  key={ix}
                    className="inline-flex items-center p-2.5 rounded w-full cursor-pointer"
                    style={{ backgroundColor: addOpacity(theme.secandary_bg, 0.3), margin:'0px'}}
                  >
                    <input
                      type="radio"
                      className="form-radio text-indigo-600 focus:ring-indigo-500 h-4 w-4"
                      name="options"
                      value={item}
                      checked={selectedTheme === item}
                      onChange={(e)=>{handleChangeTheme(e.target.value , 'theme')}}
                    />
                    <span className="ml-2">{item}</span>
                  </label>
                );
              })}
            </div>
          </div>
          <span
            className="fixed inset-0 bg-[#00000045] z-10"
            onClick={() => {
              setIsOpen(false);
            }}
          ></span>
        </>
      )}
    </div>
  );
}
