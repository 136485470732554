// sagas.js

import { takeLatest, put } from "redux-saga/effects";
import {
  ADD_DONATIONS,
  ADD_MEMBERS,
  DEL_BALANCE_SHEET,
  DEL_PROFIT_AND_LOSS,
  DELETE_ADDITION_TO_P_L,
  DELETE_AUDIT_UNDER_IT_ACT,
  DELETE_AUDITOR_DETAILS,
  DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  DELETE_BUSINESS_DETAILS_44AE,
  DELETE_CAPITAL_GAIN,
  DELETE_DEPRECIATION_LOSSE,
  DELETE_DETAILOFPROFESSION_BUSINESS,
  DELETE_DONATIONS,
  DELETE_FILING_BUE_DATE,
  DELETE_GOODS_CARRIAGES,
  DELETE_GST_DETAILS,
  DELETE_HOUSE_INCOME_SOURCE,
  DELETE_INCOME_44AD,
  DELETE_INCOME_44ADA,
  DELETE_MANUFACTURING_CONCERN,
  DELETE_MEMBERS,
  DELETE_OTHER_AUDITS_ACTION,
  DELETE_OTHER_INCOME_SOURCE,
  DELETE_PREVIOUS_YEAR_LOSSES,
  DELETE_REMUNERATION,
  DELETE_SCHEDULE_BP,
  DELETE_SECTION_40_AND_43B,
  DELETE_SPECULATIVE_INCOME,
  DELETE_TAX_COLLECTED,
  DELETE_TAX_PAID_CHALLAN,
  DELETE_TDS_IMMOVABLE_PROPERTY,
  DELETE_TDS_NON_SALARY,
  DELETE_TRADING_CONCERN,
  EDIT_ADDITION_TO_P_L,
  EDIT_AUDIT_UNDER_IT_ACT,
  EDIT_BUSINESS_DETAILS_44AE,
  EDIT_GOODS_CARRIAGES,
  EDIT_GST_DETAILS,
  EDIT_MANUFACTURING_CONCERN,
  EDIT_OTHER_AUDITS_ACTION,
  EDIT_SCHEDULE_BP,
  EDIT_SECTION_40_AND_43B,
  EDIT_SPECULATIVE_INCOME,
  EDIT_TRADING_CONCERN,
  GET_ADDITION_TO_P_L,
  GET_AUDIT_UNDER_IT_ACT,
  GET_AUDITOR_DETAILS,
  GET_BALANCE_SHEET,
  GET_BOOKS_ACCOUNT_NOT_MAINTAINED,
  GET_CAPITAL_GAIN,
  GET_CARRY_FORWARD_LOSS,
  GET_DEDUCTIONS,
  GET_DETAILOFPROFESSION_BUSINESS,
  GET_GROSS_INCOME,
  GET_HOUSE_INCOME_SOURCE,
  GET_LIABILITIES_ASSETS,
  GET_MANUFACTURING_CONCERN,
  GET_MEMBERS,
  GET_OTHER_AUDITS_ACTION,
  GET_OTHER_INCOME_SOURCE,
  GET_PERSONAL_INFO,
  GET_PROFESSIONAL_BUSINESS,
  GET_PROFESSIONAL_BUSINESS3,
  GET_PROFESSIONAL_INCOME,
  GET_PROFIT_AND_LOSS,
  GET_REMUNERATION,
  GET_SCHEDULE_BP,
  GET_SECTION_40_AND_43B,
  GET_SPECULATIVE_INCOME,
  GET_TRADING_CONCERN,
  GET_USER_SALARY_BREAKUP,
  SAVE_ADDITION_TO_P_L,
  SAVE_AUDIT_UNDER_IT_ACT,
  SAVE_AUDITOR_DETAILS,
  SAVE_BALANCE_SHEET,
  SAVE_BONDS_DEBENTURES,
  SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  SAVE_BUSINESS_DETAILS_44AE,
  SAVE_CAPITAL_GAIN,
  SAVE_DEDUCTIONS,
  SAVE_DEPRECIATION_LOSSE,
  SAVE_DETAILOFPROFESSION_BUSINESS,
  SAVE_FILING_BUE_DATE,
  SAVE_GOODS_CARRIAGES,
  SAVE_GST_DETAILS,
  SAVE_INCOME_44AD,
  SAVE_INCOME_44ADA,
  SAVE_LIABILITIES_ASSETS,
  SAVE_MANUFACTURING_CONCERN,
  SAVE_OTHER_ASSETS,
  SAVE_OTHER_AUDITS_ACTION,
  SAVE_PREVIOUS_YEAR_LOSSES,
  SAVE_PROFIT_AND_LOSS,
  SAVE_REMUNERATION,
  SAVE_SALE_OF_LAND,
  SAVE_SCHEDULE_BP,
  SAVE_SECTION_40_AND_43B,
  SAVE_SPECULATIVE_INCOME,
  SAVE_STOCKS,
  SAVE_TAX_COLLECTED,
  SAVE_TAX_PAID_CHALLAN,
  SAVE_TDS_IMMOVABLE_PROPERTY,
  SAVE_TDS_NON_SALARY,
  SAVE_TRADING_CONCERN,
  SET_GROSS_INCOME,
  SET_HOUSE_INCOME_SOURCE,
  SET_OTHER_INCOME_SOURCE,
  SET_PERSONAL_INFO,
  UPDATE_BONDS_DEBENTURES,
  UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED,
  UPDATE_CAPITAL_GAIN,
  UPDATE_DEPRECIATION_LOSSE,
  UPDATE_DETAILOFPROFESSION_BUSINESS,
  UPDATE_DONATIONS,
  UPDATE_FILING_BUE_DATE,
  UPDATE_HOUSE_INCOME_SOURCE,
  UPDATE_MEMBERS,
  UPDATE_OTHER_ASSETS,
  UPDATE_PREVIOUS_YEAR_LOSSES,
  UPDATE_REMUNERATION,
  UPDATE_SALE_OF_LAND,
  UPDATE_STOCKS,
  UPDATE_TAX_COLLECTED,
  UPDATE_TAX_PAID_CHALLAN,
  UPDATE_TDS_IMMOVABLE_PROPERTY,
  UPDATE_TDS_NON_SALARY,
} from "../constant";
import Urls from "../../utils/Urls";
import { httpDelete, httpGet, httpPost, httpPut } from "../../utils/https";
import { toast } from "react-toastify";

// Personal Information
function* getPersonalInfoSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getPersonalInfo + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* setPersonalInfoSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.setPersonalInfo, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Gross Income
function* getGrossIncomeSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getGrossIncome + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message);
    }
    payload.handleCallback(err.response ? err.response.data : null);
  }
}

function* getUserSalaryBreakup({ payload }) {
  try {
    const res = yield httpGet(Urls.getSalaryBreakup + payload.id);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err.data);
  }
}

function* setGrossIncomeSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.setSalaryBreakup, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* setOtherIncomeSourceSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.setOtherIncomeSource, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getOtherIncomeSourceSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getOtherIncomeSource + payload.id);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteOtherIncomeSourceSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteOtherIncomeSource + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// House Income Source
function* getHousePropertyIncome({ payload }) {
  try {
    const res = yield httpGet(Urls.getHousePropertyIncome + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* setHousePropertyIncome({ payload }) {
  try {
    const res = yield httpPost(Urls.setHousePropertyIncome, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateHousePropertyIncome({ payload }) {
  try {
    const res = yield httpPost(Urls.updateHousePropertyIncome, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteHousePropertyIncome({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteHousePropertyIncome + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Tax Saving

function* getDeductionSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getDeduction + payload.id);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveDeductionsSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.saveDeduction, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Non Salary TDS

function* setTdsNonSalary({ payload }) {
  try {
    const res = yield httpPut(Urls.saveTdsNonSalary, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateTdsNonSalarySaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateTdsNonSalary, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteTdsNonSalarySaga({ payload }) {
  try {
    const res = yield httpPut(
      Urls.deleteTdsNonSalary +
        `?id=${payload.data.id}&itrId=${payload.data.ItrId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// TDS on Sale/Rent of Immovable Property

function* saveTdsImmovablePropertySaga({ payload }) {
  try {
    const res = yield httpPut(Urls.saveTDSImmovableProperty, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateTdsImmovablePropertySaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateTDSImmovableProperty, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteTdsImmovablePropertySaga({ payload }) {
  try {
    const res = yield httpPut(
      Urls.deleteTDSImmovableProperty +
        `?id=${payload.data.id}&itrId=${payload.data.ItrId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Taxes collected at source

function* saveTaxCollectedSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.saveTCS, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateTaxCollectedSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateTCS, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteTaxCollectedSaga({ payload }) {
  try {
    const res = yield httpPut(
      Urls.deleteTCS + `?id=${payload.data.id}&itrId=${payload.data.ItrId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Self tax payments
function* saveTaxPaidChallanSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.saveTaxPaidChallan, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* updateTaxPaidChallanSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateTaxPaidChallan, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteTaxPaidChallanSaga({ payload }) {
  try {
    const res = yield httpPut(
      Urls.deleteTaxPaidChallan +
        `?id=${payload.data.id}&itrId=${payload.data.ItrId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateDonationsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateDonations, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteDonationsSaga({ payload }) {
  try {
    const res = yield httpDelete(
      Urls.deleteDonations +
        `?deductionId=${payload.data.deductionId}&id=${payload.data.id}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* addDonationsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.addDonations, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// capital Gain =======================

function* getCapitalGainSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getCapitalGain + payload.id);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteCapitalGainSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteCapitalGain, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveStocksSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveStocks, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateStocksSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateStocks, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* addSaleOfLandSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.addSaleOfLand, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateSaleOfLandSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateSaleOfLand, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Bonds & Debentures ============================
function* addBondDebenturesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.addBondDebentures, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateBondDebenturesSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateBondDebentures, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Deemed Capital Gains =======================================

function* saveCapitalGainsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.addDeemedCapitalGain, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateCapitalGainsSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateDeemedCapitalGain, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Add Any Other Assets =============================================

function* saveOtherAssetsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.anyOtherAssets, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateOtherAssetsSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateAnyOtherAssets, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Add Members
function* getMembersSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getMembers + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteMembersSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteMembers + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* addMembersSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.addMembers, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateMembersSaga({ payload }) {
  try {
    const res = yield httpPut(Urls.updateMembers, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Professional, Freelancing and Business Income =====================================

function* getProfessionalBusinessSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getProfessionalBusiness + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* getProfessionalBusiness3Saga({ payload }) {
  try {
    const res = yield httpGet(Urls.getProfessionalBusiness3 + payload.parm);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

//---------- detail of profession and business---------

function* saveDetailOfProfessionAndBusiness({ payload }) {
  try {
    const res = yield httpPost(
      Urls.saveDetailOfProfessionBusiness,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* updateDetailOfProfessionAndBusiness({ payload }) {
  try {
    const res = yield httpPost(
      Urls.updateDetailOfProfessionBusiness,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* delDetailOfProfessionAndBusiness({ payload }) {
  try {
    const res = yield httpPost(
      Urls.delDetailOfProfessionBusiness,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getDetailOfProfessionAndBusiness({ payload }) {
  try {
    const res = yield httpGet(
      Urls.getDetailOfProfessionBusiness + payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getBalanceSheet({ payload }) {
  try {
    const res = yield httpGet(Urls.getBalanceSheet + payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveBalanceSheet({ payload }) {
  try {
    const res = yield httpPost(Urls.saveBalanceSheet, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* delBalanceSheet({ payload }) {
  try {
    const res = yield httpPost(Urls.delBalancesheet, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* getProfitAndLoss({ payload }) {
  try {
    const res = yield httpGet(Urls.getProfitAndLoss + payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveProfitAndLoss({ payload }) {
  try {
    const res = yield httpPost(Urls.saveProfitAndLoss, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* delProfitAndLoss({ payload }) {
  try {
    const res = yield httpPost(Urls.delProfitAndLoss, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveIncome44ADASaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveProfessionalIncome44ADA, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteIncome44ADASaga({ payload }) {
  console.log("payload ====.", payload.data);
  try {
    const res = yield httpDelete(Urls.delProfessionalIncome44ADA, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveIncome44ADSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveProfessionalIncome44AD, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteIncome44ADSaga({ payload }) {
  console.log("payload ====.", payload.data);
  try {
    const res = yield httpDelete(Urls.delProfessionalIncome44AD, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// ======== Business Details =========================
function* saveBusinessDetails44AESaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveBusinessDetails44AE, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editBusinessDetails44AESaga({ payload }) {
  try {
    const res = yield httpPost(Urls.editBusinessDetails44AE, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteBusinessDetails44AESaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delBusinessDetails44AE, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

//  ====================== Goods Carriage Details =================

function* saveGoodsCarriagesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveGoodsCarriage, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editGoodsCarriagesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.editEditGoodsCarriage, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteGoodsCarriagesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delDelGoodsCarriage, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// ================================== Financial Particulars =====================

function* saveLiabilitiesAndAssetsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveLiabilitiesAndAssets, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* getLiabilitiesAndAssetsSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getLiabilitiesAndAssets + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

// ==================================== Add GST Details ============================

function* saveGSTDetailsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveGSTDetails, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* editGSTDetailsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.editGstDetails, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteGSTDetailsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delGstDetails, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// ===================================  Carry Forward of Losses  =========================

function* getCarryForwardLossSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getBroughtFrowardLosses + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* savePreviousYearLossesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.savePreviousYearLosses, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deletePreviousYearLossesSaga({ payload }) {
  try {
    const res = yield httpPost(
      Urls.deletePreviousYearLosses +
        `?id=${payload.data.id}&deductionId=${payload.data.deductionId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editPreviousYearLossesSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.editPreviousYearLosses, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveDepreciationLossSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveDepreciationLoss, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteDepreciationLossSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteDepreciationLoss, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editDepreciationLossSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.editDepreciationLoss, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* saveFilingDueDateSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveFilingDueDate, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteFilingDueDateSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delFilingDueDate, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editFilingDueDateSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateFilingDueDate, payload.data);
    console.log("response ======>", res);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// ============================================= Professional, Freelancing and Business Income ===========================

function* getProfessionalIncomeSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getRemuneration + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* getRemunerationSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getRemuneration + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveRemunerationSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveRemuneration, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteRemunerationSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delRemuneration, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editRemunerationSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateRemuneration, payload.data);
    console.log("response ======>", res);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// --- Books of Account are Not Maintained
function* getBooksOfAccountNotMaintainedSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getBooksOfAccountNotMaintained + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveBooksOfAccountNotMaintainedSaga({ payload }) {
  try {
    const res = yield httpPost(
      Urls.saveBooksOfAccountNotMaintained,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteBooksOfAccountNotMaintainedSaga({ payload }) {
  try {
    const res = yield httpPost(
      Urls.delBooksOfAccountNotMaintained,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editBooksOfAccountNotMaintainedSaga({ payload }) {
  try {
    const res = yield httpPost(
      Urls.updateBooksOfAccountNotMaintained,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

//  --- Speculative Income ---

function* getSpeculativeIncomeSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getSpeculativeIncome + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveSpeculativeIncomeSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveSpeculativeIncome, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteSpeculativeIncomeSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.deleteSpeculativeIncome, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editSpeculativeIncomeSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateSpeculativeIncome, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// ------- Financial Statements & Schedules >  Schedule BP

function* getScheduleSpSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getScheduleBP + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveScheduleSpSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveScheduleBP, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteScheduleSpSaga({ payload }) {
  try {
    const res = yield httpPost(
      `${Urls.deleteScheduleBP}?id=${payload.data.id}&itrId=${payload.data.itrId}`
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editScheduleSpSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateScheduleBP, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Financial Statements & Schedules >  Schedule BP > other sections > Additions to Profit and Loss Account

function* getAdditionToPAndLSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getAdditionToPAndL + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveAdditionToPAndLSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveAdditionToPAndL, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteAdditionToPAndLSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delAdditionToPAndL, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editAdditionToPAndLSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateAdditionToPAndL, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Financial Statements & Schedules >  Schedule BP > other sections > Summary of Incomes under section 43B

function* getSection40And43BSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getScheduleBPOtherSection + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}

function* saveSection40And43BSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveDetailOfSection40And43B, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* deleteSection40And43BSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delDetailOfSection40And43B, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

function* editSection40And43BSaga({ payload }) {
  try {
    const res = yield httpPost(
      Urls.updateDetailOfSection40And43B,
      payload.data
    );
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Other business income > Audit Information >  Audit Information

function* getAuditorDetailsSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getAuditorDetails + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}
function* saveAuditorDetailsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveAuditorDetails, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteAuditorDetailsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delAuditorDetails, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Other business income > Audit Information >  Other audits
function* getOtherAuditsSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getOtherAudits + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}
function* saveOtherAuditsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveOtherAudits, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* editOtherAuditsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateOtherAudits, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteOtherAuditsSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delOtherAudits, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Other business income > Audit Information > Audit under Act other than I-T Act
function* getAuditUnderITActSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getAuditUnderITAct + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}
function* saveAuditUnderITActSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveAuditUnderITAct, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* editAuditUnderITActSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateAuditUnderITAct, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteAuditUnderITActSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delAuditUnderITAct, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Quantitative Details > Details of Trading Concern
function* getTradingConcernSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getTradingConcern + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}
function* saveTradingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveTradingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* editTradingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateTradingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteTradingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delTradingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Quantitative Details > Details of Manufacturing Concern (Raw Materials) and  (Finished Products)

function* getManufacturingConcernSaga({ payload }) {
  try {
    const res = yield httpGet(Urls.getManufacturingConcern + payload.id);
    if (res?.status === 200) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err?.data?.message) {
      toast.error(err?.data?.message);
    }
    payload.handleCallback(err);
  }
}
function* saveManufacturingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.saveManufacturingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* editManufacturingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.updateManufacturingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}
function* deleteManufacturingConcernSaga({ payload }) {
  try {
    const res = yield httpPost(Urls.delManufacturingConcern, payload.data);
    if (res.data) {
      payload.handleCallback(res.data);
    }
  } catch (err) {
    if (err.data.message) {
      toast.error(err.data.message);
    }
    payload.handleCallback(err.data);
  }
}

// Saga watcher
export function* ItrData() {
  yield takeLatest(GET_PERSONAL_INFO, getPersonalInfoSaga);
  yield takeLatest(SET_PERSONAL_INFO, setPersonalInfoSaga);
  yield takeLatest(SET_GROSS_INCOME, setGrossIncomeSaga);
  yield takeLatest(GET_GROSS_INCOME, getGrossIncomeSaga);

  yield takeLatest(SET_OTHER_INCOME_SOURCE, setOtherIncomeSourceSaga);
  yield takeLatest(GET_OTHER_INCOME_SOURCE, getOtherIncomeSourceSaga);
  yield takeLatest(DELETE_OTHER_INCOME_SOURCE, deleteOtherIncomeSourceSaga);
  yield takeLatest(GET_USER_SALARY_BREAKUP, getUserSalaryBreakup);

  // House Income Source
  yield takeLatest(SET_HOUSE_INCOME_SOURCE, setHousePropertyIncome);
  yield takeLatest(GET_HOUSE_INCOME_SOURCE, getHousePropertyIncome);
  yield takeLatest(UPDATE_HOUSE_INCOME_SOURCE, updateHousePropertyIncome);
  yield takeLatest(DELETE_HOUSE_INCOME_SOURCE, deleteHousePropertyIncome);

  // Tax Saving
  yield takeLatest(ADD_DONATIONS, addDonationsSaga);
  yield takeLatest(UPDATE_DONATIONS, updateDonationsSaga);
  yield takeLatest(DELETE_DONATIONS, deleteDonationsSaga);
  yield takeLatest(SAVE_DEDUCTIONS, saveDeductionsSaga);
  yield takeLatest(GET_DEDUCTIONS, getDeductionSaga);

  // Non Salary TDS
  yield takeLatest(SAVE_TDS_NON_SALARY, setTdsNonSalary);
  yield takeLatest(UPDATE_TDS_NON_SALARY, updateTdsNonSalarySaga);
  yield takeLatest(DELETE_TDS_NON_SALARY, deleteTdsNonSalarySaga);

  // TDS on Sale/Rent of Immovable Property
  yield takeLatest(SAVE_TDS_IMMOVABLE_PROPERTY, saveTdsImmovablePropertySaga);
  yield takeLatest(
    UPDATE_TDS_IMMOVABLE_PROPERTY,
    updateTdsImmovablePropertySaga
  );
  yield takeLatest(
    DELETE_TDS_IMMOVABLE_PROPERTY,
    deleteTdsImmovablePropertySaga
  );

  // SAVE_TAX_COLLECTED
  yield takeLatest(SAVE_TAX_COLLECTED, saveTaxCollectedSaga);
  yield takeLatest(UPDATE_TAX_COLLECTED, updateTaxCollectedSaga);
  yield takeLatest(DELETE_TAX_COLLECTED, deleteTaxCollectedSaga);

  // Self tax payments
  yield takeLatest(SAVE_TAX_PAID_CHALLAN, saveTaxPaidChallanSaga);
  yield takeLatest(UPDATE_TAX_PAID_CHALLAN, updateTaxPaidChallanSaga);
  yield takeLatest(DELETE_TAX_PAID_CHALLAN, deleteTaxPaidChallanSaga);

  // CAPITAL GAIN  ===================
  yield takeLatest(GET_CAPITAL_GAIN, getCapitalGainSaga);
  yield takeLatest(DELETE_CAPITAL_GAIN, deleteCapitalGainSaga);

  yield takeLatest(SAVE_STOCKS, saveStocksSaga);
  yield takeLatest(UPDATE_STOCKS, updateStocksSaga);

  yield takeLatest(SAVE_SALE_OF_LAND, addSaleOfLandSaga);
  yield takeLatest(UPDATE_SALE_OF_LAND, updateSaleOfLandSaga);

  // Bonds & Debentures =============================================
  yield takeLatest(SAVE_BONDS_DEBENTURES, addBondDebenturesSaga);
  yield takeLatest(UPDATE_BONDS_DEBENTURES, updateBondDebenturesSaga);

  // Deemed Capital Gains =======================================
  yield takeLatest(SAVE_CAPITAL_GAIN, saveCapitalGainsSaga);
  yield takeLatest(UPDATE_CAPITAL_GAIN, updateCapitalGainsSaga);

  // Add Any Other Assets =============================================
  yield takeLatest(SAVE_OTHER_ASSETS, saveOtherAssetsSaga);
  yield takeLatest(UPDATE_OTHER_ASSETS, updateOtherAssetsSaga);

  // Add Members
  yield takeLatest(GET_MEMBERS, getMembersSaga);
  yield takeLatest(DELETE_MEMBERS, deleteMembersSaga);
  yield takeLatest(ADD_MEMBERS, addMembersSaga);
  yield takeLatest(UPDATE_MEMBERS, updateMembersSaga);

  // Professional, Freelancing and Business Income =====================================
  yield takeLatest(GET_PROFESSIONAL_BUSINESS, getProfessionalBusinessSaga);
  yield takeLatest(GET_PROFESSIONAL_BUSINESS3, getProfessionalBusiness3Saga);

  // Detail of profession and business
  yield takeLatest(
    SAVE_DETAILOFPROFESSION_BUSINESS,
    saveDetailOfProfessionAndBusiness
  );
  yield takeLatest(
    UPDATE_DETAILOFPROFESSION_BUSINESS,
    updateDetailOfProfessionAndBusiness
  );
  yield takeLatest(
    DELETE_DETAILOFPROFESSION_BUSINESS,
    delDetailOfProfessionAndBusiness
  );
  yield takeLatest(
    GET_DETAILOFPROFESSION_BUSINESS,
    getDetailOfProfessionAndBusiness
  );

  yield takeLatest(GET_BALANCE_SHEET, getBalanceSheet);
  yield takeLatest(SAVE_BALANCE_SHEET, saveBalanceSheet);
  yield takeLatest(DEL_BALANCE_SHEET, delBalanceSheet);

  yield takeLatest(GET_PROFIT_AND_LOSS, getProfitAndLoss);
  yield takeLatest(SAVE_PROFIT_AND_LOSS, saveProfitAndLoss);
  yield takeLatest(DEL_PROFIT_AND_LOSS, delProfitAndLoss);

  // 44ADA
  yield takeLatest(SAVE_INCOME_44ADA, saveIncome44ADASaga);
  yield takeLatest(DELETE_INCOME_44ADA, deleteIncome44ADASaga);

  // 44AD
  yield takeLatest(SAVE_INCOME_44AD, saveIncome44ADSaga);
  yield takeLatest(DELETE_INCOME_44AD, deleteIncome44ADSaga);

  // ======== Business Details =========================
  yield takeLatest(SAVE_BUSINESS_DETAILS_44AE, saveBusinessDetails44AESaga);
  yield takeLatest(EDIT_BUSINESS_DETAILS_44AE, editBusinessDetails44AESaga);
  yield takeLatest(DELETE_BUSINESS_DETAILS_44AE, deleteBusinessDetails44AESaga);

  //  ====================== Goods Carriage Details =================

  yield takeLatest(SAVE_GOODS_CARRIAGES, saveGoodsCarriagesSaga);
  yield takeLatest(EDIT_GOODS_CARRIAGES, editGoodsCarriagesSaga);
  yield takeLatest(DELETE_GOODS_CARRIAGES, deleteGoodsCarriagesSaga);

  // ================================== Financial Particulars =====================
  yield takeLatest(SAVE_LIABILITIES_ASSETS, saveLiabilitiesAndAssetsSaga);
  yield takeLatest(GET_LIABILITIES_ASSETS, getLiabilitiesAndAssetsSaga);

  // =============================== Add GST Details ============================
  yield takeLatest(SAVE_GST_DETAILS, saveGSTDetailsSaga);
  yield takeLatest(EDIT_GST_DETAILS, editGSTDetailsSaga);
  yield takeLatest(DELETE_GST_DETAILS, deleteGSTDetailsSaga);

  // =============================== Carry Forward of Losses  =========================

  yield takeLatest(GET_CARRY_FORWARD_LOSS, getCarryForwardLossSaga);

  yield takeLatest(SAVE_PREVIOUS_YEAR_LOSSES, savePreviousYearLossesSaga);
  yield takeLatest(DELETE_PREVIOUS_YEAR_LOSSES, deletePreviousYearLossesSaga);
  yield takeLatest(UPDATE_PREVIOUS_YEAR_LOSSES, editPreviousYearLossesSaga);

  yield takeLatest(SAVE_DEPRECIATION_LOSSE, saveDepreciationLossSaga);
  yield takeLatest(DELETE_DEPRECIATION_LOSSE, deleteDepreciationLossSaga);
  yield takeLatest(UPDATE_DEPRECIATION_LOSSE, editDepreciationLossSaga);

  yield takeLatest(SAVE_FILING_BUE_DATE, saveFilingDueDateSaga);
  yield takeLatest(DELETE_FILING_BUE_DATE, deleteFilingDueDateSaga);
  yield takeLatest(UPDATE_FILING_BUE_DATE, editFilingDueDateSaga);

  // ============================================= Professional, Freelancing and Business Income ===========================

  yield takeLatest(GET_PROFESSIONAL_INCOME, getProfessionalIncomeSaga);
  // --- Remuneration from Partnership Firms
  yield takeLatest(GET_REMUNERATION, getRemunerationSaga);
  yield takeLatest(SAVE_REMUNERATION, saveRemunerationSaga);
  yield takeLatest(DELETE_REMUNERATION, deleteRemunerationSaga);
  yield takeLatest(UPDATE_REMUNERATION, editRemunerationSaga);
  // --- Books of Account are Not Maintained

  yield takeLatest(
    GET_BOOKS_ACCOUNT_NOT_MAINTAINED,
    getBooksOfAccountNotMaintainedSaga
  );
  yield takeLatest(
    SAVE_BOOKS_ACCOUNT_NOT_MAINTAINED,
    saveBooksOfAccountNotMaintainedSaga
  );
  yield takeLatest(
    DELETE_BOOKS_ACCOUNT_NOT_MAINTAINED,
    deleteBooksOfAccountNotMaintainedSaga
  );
  yield takeLatest(
    UPDATE_BOOKS_ACCOUNT_NOT_MAINTAINED,
    editBooksOfAccountNotMaintainedSaga
  );

  //  --- Speculative Income ---
  yield takeLatest(GET_SPECULATIVE_INCOME, getSpeculativeIncomeSaga);
  yield takeLatest(SAVE_SPECULATIVE_INCOME, saveSpeculativeIncomeSaga);
  yield takeLatest(DELETE_SPECULATIVE_INCOME, deleteSpeculativeIncomeSaga);
  yield takeLatest(EDIT_SPECULATIVE_INCOME, editSpeculativeIncomeSaga);

  // ------- Financial Statements & Schedules >  Schedule BP
  yield takeLatest(GET_SCHEDULE_BP, getScheduleSpSaga);
  yield takeLatest(SAVE_SCHEDULE_BP, saveScheduleSpSaga);
  yield takeLatest(DELETE_SCHEDULE_BP, deleteScheduleSpSaga);
  yield takeLatest(EDIT_SCHEDULE_BP, editScheduleSpSaga);

  // Financial Statements & Schedules >  Schedule BP > other sections > Additions to Profit and Loss Account
  yield takeLatest(GET_ADDITION_TO_P_L, getAdditionToPAndLSaga);
  yield takeLatest(SAVE_ADDITION_TO_P_L, saveAdditionToPAndLSaga);
  yield takeLatest(EDIT_ADDITION_TO_P_L, editAdditionToPAndLSaga);
  yield takeLatest(DELETE_ADDITION_TO_P_L, deleteAdditionToPAndLSaga);

  // Financial Statements & Schedules >  Schedule BP > other sections > Summary of Incomes under section 43B
  yield takeLatest(DELETE_SECTION_40_AND_43B, deleteSection40And43BSaga);
  yield takeLatest(GET_SECTION_40_AND_43B, getSection40And43BSaga);
  yield takeLatest(EDIT_SECTION_40_AND_43B, editSection40And43BSaga);
  yield takeLatest(SAVE_SECTION_40_AND_43B, saveSection40And43BSaga);

  // Other business income > Audit Information >  Audit Information
  yield takeLatest(GET_AUDITOR_DETAILS, getAuditorDetailsSaga);
  yield takeLatest(SAVE_AUDITOR_DETAILS, saveAuditorDetailsSaga);
  yield takeLatest(DELETE_AUDITOR_DETAILS, deleteAuditorDetailsSaga);

  // Other business income > Audit Information >  Other audits
  yield takeLatest(GET_OTHER_AUDITS_ACTION, getOtherAuditsSaga);
  yield takeLatest(SAVE_OTHER_AUDITS_ACTION, saveOtherAuditsSaga);
  yield takeLatest(EDIT_OTHER_AUDITS_ACTION, editOtherAuditsSaga);
  yield takeLatest(DELETE_OTHER_AUDITS_ACTION, deleteOtherAuditsSaga);

  // Other business income > Audit Information > Audit under Act other than I-T Act
  yield takeLatest(GET_AUDIT_UNDER_IT_ACT, getAuditUnderITActSaga);
  yield takeLatest(SAVE_AUDIT_UNDER_IT_ACT, saveAuditUnderITActSaga);
  yield takeLatest(EDIT_AUDIT_UNDER_IT_ACT, editAuditUnderITActSaga);
  yield takeLatest(DELETE_AUDIT_UNDER_IT_ACT, deleteAuditUnderITActSaga);

  // Quantitative Details > Details of Trading Concern

  yield takeLatest(GET_TRADING_CONCERN, getTradingConcernSaga);
  yield takeLatest(SAVE_TRADING_CONCERN, saveTradingConcernSaga);
  yield takeLatest(EDIT_TRADING_CONCERN, editTradingConcernSaga);
  yield takeLatest(DELETE_TRADING_CONCERN, deleteTradingConcernSaga);

  // Quantitative Details > Details of Manufacturing Concern (Raw Materials) and  (Finished Products)
  yield takeLatest(GET_MANUFACTURING_CONCERN, getManufacturingConcernSaga);
  yield takeLatest(SAVE_MANUFACTURING_CONCERN, saveManufacturingConcernSaga);
  yield takeLatest(EDIT_MANUFACTURING_CONCERN, editManufacturingConcernSaga);
  yield takeLatest(
    DELETE_MANUFACTURING_CONCERN,
    deleteManufacturingConcernSaga
  );
}
