import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../styledComponent/Inputs/Input";
import Select from "../../../styledComponent/Inputs/Select";
import ToggelButton from "../../../styledComponent/Button/ToggelButton";
import LoadingBox from "../../DialogBox/LoadingBox";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  saveTDSImmovablePropertyAction,
  updateTDSImmovablePropertyAction,
} from "../../../redux/itr/action";

export default function TdsImmovableProperty({
  FinancialYear,
  IncomeAgainstTdsDeducted,
  TdsRelatedTo,
  handleClose,
  editData,
  handleCallback,
}) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);

  const dispatch = useDispatch();

  const handleSubmitForm = (values) => {
    console.log('values=====> ',values)
    if (values.id) {
      dispatch(
        updateTDSImmovablePropertyAction(values, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback(e.data);
          }
        })
      );
    } else {
      let { id, ...newData } = values;
      dispatch(
        saveTDSImmovablePropertyAction(newData, (e) => {
          console.log(e);
          if (e.status == 200) {
            handleCallback(e.data);
          }
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    tanOfDeductor: Yup.string().required(strings.validation_message.required),
    nameOfDeductor: Yup.string().required(strings.validation_message.required),
    taxDeducted: Yup.string().required(strings.validation_message.required),
    // tdsRelatingTo: Yup.string().when('tdsAnotherAssessee', {
    //   is: true,
    //   then: Yup.string().required(strings.validation_message.required),
    // }),
    // tdsCredit: Yup.string().required(strings.validation_message.required),
    // incomeRelatingTdsCredit: Yup.string().required(
    //   strings.validation_message.required
    // ),
    // panOtherPerson: Yup.string().required(strings.validation_message.required),
    // taxDeductedClaimedForThisYear: Yup.string().required(
    //   strings.validation_message.required
    // ),
    // incomeAgainstTdsDeducted: Yup.string().required(
    //   strings.validation_message.required
    // ),
    // financialYearTdsDeducted: Yup.string().required(
    //   strings.validation_message.required
    // ),
  });

  useEffect(() => {
    // setLoader(true);
    setFormData({
      buyerPanNumber: editData?.buyerPanNumber || "",
      buyerName: editData?.buyerName || "",
      totalTaxDeducted: editData?.totalTaxDeducted || "",
      creditsFromAnotherAssessee:
        editData?.creditsFromAnotherAssessee || false,
      tdsRelatingTo: editData?.tdsRelatingTo || 1,
      tdsCredit: editData?.tdsCredit || "",
      incomeRelatingTdsCredit: editData?.incomeRelatingTdsCredit || 0,
      panOfOtherPerson: editData?.panOfOtherPerson || "",
      tdsDeducted: editData?.tdsDeducted || "",
      tdsClaimed: editData?.tdsClaimed || 0,
      incomeAgainstTdsDeducted: editData?.incomeAgainstTdsDeducted || "",
      financialYearTdsDeducted: editData?.financialYearTdsDeducted || "",
      deductionId: editData?.deductionId || "",
      id: editData?.id || "",
      itrId: ItrData?.id,
    });
    setLoader(false);
  }, [editData]);


  return (
    <>
      {!loader ? (
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          // validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log("=================")
            handleSubmitForm(values);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
          }) => (
            <div>
              <StyleFormBox>
                <div className="flex gap-10 justify-between mt-3">
                  <h5>{strings.tax_saving.tax_deducted_at_source}</h5>
                </div>
                <p>{strings.tax_saving.provide_this_information_for_tds}</p>
                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.pan_of_property_buyer}</label>
                  <Input
                    placeholder={strings.placeholder.name_of_buyer}
                    name={"buyerPanNumber"}
                    value={values.buyerPanNumber}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.name_of_property_buyer}</label>

                  <Input
                    placeholder={strings.placeholder.name_of_buyer}
                    name={"buyerName"}
                    value={values.buyerName}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <div>
                    <label>{strings.tax_saving.total_tax_deducted}</label>
                    <p className="note_message">
                      {strings.message.leave_if_not_deducted_in_name}
                    </p>
                  </div>
                  <Input
                    inputType={"currency"}
                    name={"totalTaxDeducted"}
                    value={values.totalTaxDeducted}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.claim_transfer_tds_credits}</label>
                  <ToggelButton
                    value={values.creditsFromAnotherAssessee}
                    handleClick={() =>
                      setFieldValue(
                        "creditsFromAnotherAssessee",
                        !values.creditsFromAnotherAssessee
                      )
                    }
                  />
                </div>

                {values.creditsFromAnotherAssessee === true ? (
                  <>
                    <div className="flex_input mt-2">
                      <label>{strings.tax_saving.tds_credit_relating_to}</label>
                      <Select
                        name={"tdsRelatingTo"}
                        value={values.tdsRelatingTo}
                        error={errors.tdsRelatingTo}
                        onChange={handleChange}
                      >
                        <option value="">{strings.label.choose_option}</option>
                        {TdsRelatedTo?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="flex_input mt-2">
                      <div>
                        <label>{strings.tax_saving.tds_credit}</label>
                        <p className="note_message">
                          {strings.message.amount_tds_received_transfered}
                        </p>
                      </div>
                      <Input
                        inputType={"currency"}
                        name={"tdsCredit"}
                        value={values.tdsCredit}
                        error={errors.tdsCredit}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input mt-2">
                      <label>
                        {
                          strings.tax_saving
                            .income_against_which_tds_was_deducted
                        }
                      </label>
                      <Input
                        inputType={"currency"}
                        name={"incomeRelatingTdsCredit"}
                        value={values.incomeRelatingTdsCredit}
                        error={errors.incomeRelatingTdsCredit}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input mt-2">
                      <label>{strings.tax_saving.pan_other_person}</label>
                      <Input
                        name={"panOtherPerson"}
                        value={values.panOtherPerson}
                        error={errors.panOtherPerson}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="flex_input mt-2">
                  <label>{strings.tax_saving.tax_deducted_claimed}</label>
                  <Input
                    inputType={"currency"}
                    name={"tdsDeducted"}
                    value={values.tdsDeducted}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex_input mt-2">
                  <label>
                    {strings.tax_saving.income_against_which_tds_was_deducted}
                  </label>
                  <Input
                    inputType={"currency"}
                    name={"incomeAgainstTdsDeducted"}
                    value={values.incomeAgainstTdsDeducted}
                    onChange={handleChange}
                  />
                </div>

                <div className="flex_input mt-2">
                  <label>
                    {strings.tax_saving.income_against_which_tds_was_deducted}
                  </label>
                  <Select
                    value={values.incomeAgainstTdsDeducted}
                    name={"incomeAgainstTdsDeducted"}
                    onChange={handleChange}
                  >
                    {" "}
                    <option value="">{strings.label.choose_option}</option>
                    {IncomeAgainstTdsDeducted?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex_input mt-2">
                  <label>
                    {strings.tax_saving.financial_year_tds_was_deducted}
                  </label>
                  <Select
                    value={values.financialYearTdsDeducted}
                    name={"financialYearTdsDeducted"}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {FinancialYear?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <span className="note_message">
                  {strings.message.this_is_same_as_financial_year}
                </span>

                <div className="flex_input">
                  <button className="secandary_button" onClick={handleClose}>
                    {strings.button.cancel}
                  </button>
                  <button className="primary_button" onClick={handleSubmit}>
                    {strings.button.save_and_close}
                  </button>
                </div>
              </StyleFormBox>
            </div>
          )}
        </Formik>
      ) : (
        <LoadingBox open={!loader} />
      )}
    </>
  );
}
