import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";




export default function EmiCalculator() {
    return (
        <Root>
            <div className="emi-section">
                <div className="container_box">
                    <div className="emi-main flex items-center">
                        <div className="emi-left">
                            <h6>Know more about</h6>
                            <h2>EMI CALCULATOR</h2>
                        </div>
                        <div className="emi-right">
                            <img src="Images/calculator.jpg"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="loan-amount-section">
                <div className="container_box">
                    <div className="boxes-m flex ">  
                        <div className="left-amount">
                            <div className="left-box">
                                <div className="content">
                                    <h4>Loan Amount</h4>
                                    <h3>₹ 20,00,000</h3>
                                </div>
                                <div className="slidecontainer"><input type="range" min="1" max="100" className="slider" id="myRange" value="50"></input></div>
                                <div className="value flex"><h6>0</h6><h6>10cr</h6></div>
                                <div className="btn-group">
                                    <button>Yearly</button>
                                    <button>Monthly</button>

                                </div>
                                <div className="slidecontainer">Years<input type="range" min="1" max="100" className="slider" id="myRange" value="50"></input></div>
                                <div className="value flex"><h6>0</h6><h6>10cr</h6></div>
                                <div className="slidecontainer">%<input type="range" min="1" max="100" className="slider" id="myRange" value="50"></input></div>
                                <div className="value flex"><h6>0</h6><h6>10cr</h6></div>
                                <p>This is preliminary calculation and is not the final offer</p>

                            </div>
                        </div>
                        <div className="right-amount">
                            <div className="right-box">
                                <div className="content">
                                    <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut"><circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="#fff"></circle><circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#93db89" stroke-width="6"></circle><circle className="donut-segment" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke="#59BA4B" stroke-width="6" stroke-dasharray="100 0" stroke-dashoffset="25"></circle>
                                        <g className="donut-text">

                                            <text y="42%" transform="translate(0, 2)">
                                                <tspan x="50%" text-anchor="middle" className="donut-data">
                                                    Total Re-payment
                                                </tspan>
                                            </text>
                                            <text y="52%" transform="translate(0, 2)">
                                                <tspan x="50%" text-anchor="middle" className="donut-value" id="secondOutput">₹ NaN</tspan>
                                            </text>
                                        </g>
                                    </svg>
                                </div>
                                <div className="interst flex">
                                    <h4> Interest Payable</h4>
                                    <h4> Loan Amount</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Root>
    );
}


const Root = styled.section`
.emi-section {
    width:100%;
    .emi-main {
        width:100%;
        .emi-left {
            width:50%;
            h2 {
                font-size:32px;
                font-weight:700;
            }
            h6 {
                font-size:18px;
                font-weight:700;
            }
        }
        .emi-right {
            width:50%;
            img {
                border-radius: 0 60px;
            }
        }
    }
}
.loan-amount-section {
    width:100%;
    margin:50px 0;
    
    .left-amount {
        width:70%;
        .left-box {
            width:100%;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            padding:40px 20px;
            .content {
                h4 {
                    font-size:20px;
                    font-weight:700;
                }
            }
            .slidecontainer {
                margin-top: 20px;
                width: 100%;
                .slider {
                    width: 100%;
                }
            }
            .value {
                justify-content:space-between;
            }
            .btn-group{
                width:100%;
                display:flex;
                justify-content:center;
                 button {
                    background-color: #04AA6D; /* Green background */
                    border: 1px solid green; /* Green border */
                    color: white; /* White text */
                     padding: 10px 24px; /* Some padding */
                    cursor: pointer; /* Pointer/hand icon */
                    float: left; /* Float the buttons side by side */
                }
            }
        }
    }
   
} 
    .right-amount {
        width:30%;
        .right-box {
           width:100%;
           box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
           padding:59px 20px;
           .content {
                .donut-text {
                    font-size:2px;
                }
           }
           
        }
        .interst {
            justify-content:space-between;
            h4 {
                font-size:12px;
                font-weight:700;
            }
        }
    }
    



`