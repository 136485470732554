import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { useSelector } from "react-redux";

export default function Accommodation() {
    const {theme } = useSelector((s) => s.platformSetting);
    return (
        <Root theme={theme}>

            <div className="header-section">
                <div className="header-content flex justify-center items-center">
                    <div className="text-line">
                        <h5>Filing for AY 2024-25 is coming soon</h5>
                    </div>
                    <div className="pre-button">
                        <button type="button">Pre-Book Now</button>
                        <span className="off_coupon">Grab 25% OFF</span>
                    </div>
                </div>
            </div>
            <div className="compound-section">
                <div className="container_box">
                    <div className="compound-main">
                        <div className="compound-left">
                            <div className="compound-left-content">
                                <h4>Rent free accomodation calculator</h4>
                                <p>Calculate tax exemptions on accomodation provided by employer</p>
                            </div>
                            <div className="range-bar-main">
                                <div className="range-le">
                                    <div className="range-data flex">
                                        <h5>Financial year</h5>
                                        <select type="text">
                                            <option>year</option>
                                            <option>2020-21</option>
                                            <option>2022-23</option>
                                            <option>2023-24</option>

                                        </select>
                                    </div>

                                    <div className="range-data flex mt-4">
                                        <h5>Accomodation type</h5>
                                        <input type="radio" id="css" name="fav_language" value="CSS"></input>
                                        <input type="radio" id="css" name="fav_language" value="CSS"></input>
                                    </div>

                                    <div className="range-data flex">
                                        <h5>Basic Salary</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>DA Forming part of Salary</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Commission (as % of turnover achieved by the employee)</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Any Other Commission</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Fees</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Bonous</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Monetary Payment which is taxable</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>All Taxable Allowances</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Total of Above</h5>
                                        <p>0</p>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Rent Paid / Payable to Hotel</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Any Amount recovered from employee</h5>
                                        <input type="text" id="css" name="fav_language" value="0" ></input>

                                    </div>
                                    <div className="range-data mt-5 flex">
                                        <h5>Taxable value of Perquisites</h5>
                                        <p>0</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="compound-right">
                            <img src="Images/advisor.webp"></img>
                        </div>
                    </div>
                </div>
            </div>
            <div className="btm-content">
                <div className="container_box">
                    <div className="text">
                        <h2>Rent Free Accommodation Calculator</h2><br></br>
                        <h4>What is Considered Accommodation?</h4><br></br>
                        <p>The phrase "accommodation" refers to the following: a flat, a farmhouse or a portion of one, a hotel or guest house, an apartment for service personnel, etc.</p><br></br>
                        <h4>What is Rent-Free Accommodation?</h4><br></br>
                        <p>It refers to a place of residence provided by an employer for an employee to live in. RFA can be provided either for free or at a reduced fee. Rent-free accommodations are counted as prerequisites. When calculating the taxpayer's total income for the purpose of submitting an Income Tax return, perquisites are added under the heading Salaries. Perquisites comprise the value of rent-free housing granted by each employer to the assessee. The taxpayer may work for more than one employer at the same time. In such cases, the total value of all rent-free lodgings given to the taxpayer will be taxed under the Act.</p><br></br>
                        <h4>What are the Types of Accommodations Given to an Employee?</h4><br></br>
                        <p>Accommodation given to an employee is divided into the following categories and tax treatment of each category is separate:-</p><br></br>
                        <h4>1.Accommodation given by Govt:</h4>
                        <p>1.Unfurnished Accommodation</p>
                        <p>2.Furnished Accommodation</p><br></br>
                        <h4>2.Accommodation given by Non-Govt:</h4>
                        <p>Unfurnished Accommodation</p>
                        <p>Furnished Accommodation</p><br></br>
                        <h4>How to Calculate the Value of Rent-Free Accommodation?</h4><br></br>
                        <p>The population of the city where rent-free housing is available</p>
                        <p>Nature of the furnishings used in the lodging</p>
                        <p>In the instance of rent-free housing offered to an employee when the property is held by the employer, the value of the perquisite would be computed based on the city's population as of the 2001 census.</p>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="3">Case 1: Unfurnished and owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Licence Fees determined by Government</td>
                                        <td>50,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Less:</strong></td>
                                        <td>Value recovered from employee</td>
                                        <td>24,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case I</td>
                                        <td>26,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 2: Furnished and owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite as in Case I</td>
                                        <td>26,000</td>
                                        <td></td>
                                        <td>Cost of furniture:</td>
                                        <td>20,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Add:</strong></td>
                                        <td>10% p.a. of the cost of furniture</td>
                                        <td>2,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case II</td>
                                        <td>28,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 3: Furnished and not owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite as in Case I</td>
                                        <td>26,000</td>
                                        <td></td>
                                        <td>Hire Charge:</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Add:</strong></td>
                                        <td>Actual hire charges payable</td>
                                        <td>5,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>31,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-heading my-5">
                            <h4>Employer: Government</h4>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 1: Unfurnished and owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Cities having population exceeding 25 lakhs as per 2001 census</td>
                                        <td>15% of salary</td>
                                        <td>1,50,000</td>
                                        <td>Salary</td>
                                        <td>10,00,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Cities having population exceeding 10 lakhs but not exceeding 25 lakhs as per 2001 census</td>
                                        <td>10% of salary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Cities having population not exceeding 10 lakhs as per 2001 census</td>
                                        <td>7.5% of salary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Less:</strong></td>
                                        <td>Any amount receovered from employee</td>
                                        <td>XXXX</td>
                                        <td>30,000</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Cities having population not exceeding 10 lakhs as per 2001 census</td>
                                        <td>7.5% of salary</td>
                                        <td>1,20,000</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 2: Furnished and owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite as in Case I</td>
                                        <td>1,20,000</td>
                                        <td></td>
                                        <td>Cost of furniture:</td>
                                        <td>20,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Add:</strong></td>
                                        <td>10% p.a. of the cost of furniture</td>
                                        <td>2,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case II</td>
                                        <td>1,22,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 3: Furnished and not owned by Government</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite as in Case I</td>
                                        <td>1,20,000</td>
                                        <td></td>
                                        <td>Hire Charge:</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Add:</strong></td>
                                        <td>Actual hire charges payable</td>
                                        <td>5,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case III</td>
                                        <td>1,25,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="2">Case 4: Unfurnished and accommodation is not owned by the employer</th>
                                    </tr>
                                    <tr>
                                        <td>Actual Rent paid by the employer</td>
                                        <td>1,20,000</td>
                                    </tr>
                                    <tr>
                                        <td>15% of salary</td>
                                        <td>1,50,000</td>
                                    </tr>
                                    <tr>
                                        <td>Taxable Value of Perquisite in Case IV
                                            (whichever is lower</td>
                                        <td>1,20,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 5: Furnished and accommodation is not owned by the employer</th>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case IV</td>
                                        <td>1,20,000</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>10% p.a. of the cost of furniture (Furniture owned by employer)</td>
                                        <td>2,000</td>
                                        <td></td>
                                        <td>Cost of Furniture:</td>
                                        <td>20,000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Add:</strong></td>
                                        <td>Actual hire charges payable (Furniture taken on lease by employer)</td>
                                        <td>5,000</td>
                                        <td></td>
                                        <td>Hire Charge:</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Taxable Value of Perquisite in Case V</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="6">Case 6: Unfurnished accommodation provided in Hotel</th>
                                    </tr>
                                    <tr>
                                        <td>Not Taxable</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive">
                            <table className="table custom_tbl">
                                <tbody>
                                    <tr className="heading">
                                        <th colspan="2">Case 7: Furnished Accommodation provided in Hotel</th>
                                    </tr>
                                    <tr>
                                        <td>Actual charges paid or payable for such hotel</td>
                                        <td>30,000</td>
                                    </tr>
                                    <tr>
                                        <td>24% of the salary</td>
                                        <td>9,863</td>
                                    </tr>
                                    <tr>
                                        <td>Amount recovered from employee</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td>Amount recovered from employee</td>
                                        <td>4,863</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        

                    </div>
                </div>
            </div>




        </Root>
    );
}


const Root = styled.section`
.header-section {
    width:100%;
    background:linear-gradient(90.09deg, #fbd63f 1.01%, #fc466b 99.06%);
    padding:20px 0;
    .header-content {
        width:100%;
        .text-line {
            width:30%;
            h5 {
                color:#fff;
                text-align:center;
            }
            @media (max-width:576px) {
                margin-bottom:10px;
                width:100%;
            }
        }
        .pre-button {
            button {
                width: 150px;
                background: ${(p) => p.theme.secandary_bg};
                padding: 12px;
                border-radius: 5px;
            }
            .off_coupon {
                border: dashed 2px #fff;
                font-weight: 600;
                background: rgba(255, 255, 255, .1);
                margin-left: 1rem;
                border-radius: .5rem;
                padding: .5rem 1rem;
                color:#fff;
            }
        }
        @media (max-width:576px) {
            flex-direction:column;
        }
    }
}
.compound-section {
    width:100%;
    /* background:#fff; */
    padding:40px 0;
    .compound-main {
        width:100%;
        display:flex;
        .compound-left {
            width:70%;
            .compound-left-content {
                h4 {
                    font-size:24px;
                    font-weight:700;
                }
                p {
                    font-size:14px;
                    line-height:23px;
                }
            }
            .range-bar-main {
                width:100%;
                display:flex;
                padding:40px 20px;
                margin-top:10px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                .range-le {
                    width:100%;
                .range-data {
                    align-items:center;
                    gap:20px;
                    justify-content:space-between;
                    margin-bottom:30px;
                    h5 {
                        font-size:14px;
                        font-weight:500;
                    }
                    input {
                        display: inline-block;
                        font-size: 18px;
                        border: none;
                        outline: 0;
                        text-align: left;
                        padding: 10px;
                        font-weight: 500;
                        border-radius: 8px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(246, 246, 246, 0) 0px 0px 0px 3px;
                    }
                    select {
                        display: inline-block;
                        font-size: 18px;
                        border: none;
                        outline: 0;
                        height: 44px;
                        text-align: left;
                        padding: 0 15px 0 35px;
                        font-weight: 500;
                        border-radius: 8px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(246, 246, 246, 0) 0px 0px 0px 3px;
                    }
                }
                
                @media (max-width:768px) {
                    width:100%;
                  }

              }
              @media (max-width:768px) {
                flex-direction:column;
              }
              
            }
            
            @media (max-width:1024px) {
                width:100%;
            }
        }
        .compound-right {
            width:40%;
        }
        @media (max-width:1024px) {
            flex-direction:column;
        }
    }
}  

.btm-content {
    width:100%;
    /* background:#fff; */
    margin:40px 0;
    padding:40px 0;
    .text {
        width:100%;
        h2 {
            font-size:24px;
            font-weight:700;
        }
        h4 {
            font-size:20px;
            font-weight:700;
        }
        p {
            font-size:14px;
            line-height:23px;
        }
        table {
            width:80%;
            .heading {
               background:#59ba4b;
                th {
                   color: #fff;
                   padding: 16px;
                   vertical-align: middle;
                   text-align: center;
                   font-weight: 700;
                   font-size: 14px;
                   border: solid 1px #dedede;
                }
                
            }
             td {
                border: solid 1px #dedede;
                 padding: 8px 16px;
                vertical-align: middle;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
   

`