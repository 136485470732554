import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import Select from "../../../../styledComponent/Inputs/Select";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function InterestIncome({ handleCallback, incomeData }) {
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(true);
  const { strings, theme, categories } = useSelector((s) => s.platformSetting);
  const categoriesEmployeeList = categories?.filter(
    (item) => item.tag === "employee"
  );

  const validationSchema = Yup.object().shape({
    interestIncomeFromSB: Yup.array().of(
      Yup.object().shape({
        bankName: Yup.string().required(strings.validation_message.required),
        interestIncome: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    interestIncomeFromDeposits: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    anyOtherInterestIncome: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      typeOfIncome: 103,
      interestIncomeFromSB: incomeData?.interestIncomeFromSB || [
        { bankName: "", interestIncome: "" },
      ],
      interestIncomeFromDeposits: incomeData?.interestIncomeFromDeposits || [
        { description: "", amount: "" },
      ],
      interestIncomeFromPF: incomeData?.interestIncomeFromPF || "",
      interestIncomeFromPFType: incomeData?.interestIncomeFromPFType || "",
      interestIncomeOnTaxRefund: incomeData?.interestIncomeOnTaxRefund || "",
      anyOtherInterestIncome: incomeData?.anyOtherInterestIncome || [],
    });
    setLoader(false);
  }, [incomeData]);

  return (  
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <div>
            <FieldArray name="interestIncomeFromSB">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 style={{ color: theme.primary_txt }}>
                    {strings.income.interest_income_from_saving_bank}{" "}
                  </h4>
                  <p>{strings.income.interest_income_from_saving_bank}</p>
                  <div className="flex_input my-2 items-center">
                    <label>{strings.income.bank_name}</label>
                    <label>{strings.income.amount}</label>
                  </div>
                  {values?.interestIncomeFromSB?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <Input
                        name={`interestIncomeFromSB.${index}.bankName`}
                        placeholder={strings.income.bank_name}
                        value={item.bankName}
                        error={
                          errors?.interestIncomeFromSB &&
                          errors?.interestIncomeFromSB[index]?.bankName
                        }
                        onChange={handleChange}
                      />
                      <Input
                        inputType={"currency"}
                        name={`interestIncomeFromSB.${index}.interestIncome`}
                        value={item.interestIncome}
                        error={
                          errors?.interestIncomeFromSB &&
                          errors?.interestIncomeFromSB[index]?.interestIncome
                        }
                        onChange={handleChange}
                      />
                      <button
                        className="delete_button mb-2"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ bankName: "", interestIncome: "" });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <FieldArray name="interestIncomeFromDeposits">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 style={{ color: theme.primary_txt }}>
                    {strings.income.interest_from_deposit}{" "}
                  </h4>
                  <p>{strings.income.interest_income_from_depoits}</p>
                  <div className="flex_input my-2 items-center">
                    <label>{strings.label.description}</label>
                    <label>{strings.income.amount}</label>
                  </div>
                  {values?.interestIncomeFromDeposits?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <Input
                        name={`interestIncomeFromDeposits.${index}.description`}
                        placeholder={strings.label.description}
                        value={item.description}
                        error={
                          errors?.interestIncomeFromDeposits &&
                          errors?.interestIncomeFromDeposits[index]?.description
                        }
                        onChange={handleChange}
                      />

                      <Input
                        inputType={"currency"}
                        name={`interestIncomeFromDeposits.${index}.amount`}
                        value={item.amount}
                        error={
                          errors?.interestIncomeFromDeposits &&
                          errors?.interestIncomeFromDeposits[index]?.amount
                        }
                        onChange={handleChange}
                      />

                      <button
                        className="delete_button mb-2"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ description: "", amount: "" });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <StyleFormBox>
              <h4 style={{ color: theme.primary_txt }}>
                {strings.income.interest_income_on_pf}{" "}
              </h4>
              <p>
                {strings.income.please_add_details_of_taxable_interest_from_pf}
              </p>
              <div className="flex_input my-2">
                <div>
                  <label>{strings.label.type}</label>
                  <Select
                    name={"interestIncomeFromPFType"}
                    value={values.interestIncomeFromPFType}
                    onChange={handleChange}
                  >
                    <option value={""}>{strings.label.choose_option}</option>
                    {categoriesEmployeeList?.map((i, ix) => (
                      <option value={i.type} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>{strings.income.amount} </label>
                  <Input
                    inputType={"currency"}
                    name={"interestIncomeFromPF"}
                    value={values.interestIncomeFromPF}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </StyleFormBox>

            <StyleFormBox>
              <h4 style={{ color: theme.primary_txt }}>
                {strings.income.interest_on_income_tax_refund}
              </h4>
              <p>{strings.income.income_tax_refund_last_year}</p>

              <div className="flex_input my-2">
                <label>{strings.income.enter_the_interest_amount}</label>
                <Input
                  name={"interestIncomeOnTaxRefund"}
                  inputType={"currency"}
                  value={values.interestIncomeOnTaxRefund}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>

            <FieldArray name="anyOtherInterestIncome">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 style={{ color: theme.primary_txt }}>
                    {strings.income.any_other_interest_income}{" "}
                  </h4>
                  <p>
                    {strings.income.bonds_unsecured_loans_national_certificates}
                  </p>

                  <div className="flex_input my-2 items-center">
                    <label>{strings.label.description}</label>
                    <label>{strings.income.amount}</label>
                  </div>
                  {values?.anyOtherInterestIncome?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <Input
                        name={`anyOtherInterestIncome.${index}.description`}
                        placeholder={strings.income.description}
                        value={item.description}
                        error={
                          errors?.anyOtherInterestIncome &&
                          errors?.anyOtherInterestIncome[index]?.description
                        }
                        onChange={handleChange}
                      />

                      <Input
                        inputType={"currency"}
                        name={`anyOtherInterestIncome.${index}.amount`}
                        value={item.amount}
                        error={
                          errors?.anyOtherInterestIncome &&
                          errors?.anyOtherInterestIncome[index]?.amount
                        }
                        onChange={handleChange}
                      />

                      <button
                        className="delete_button mb-2"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ description: "", amount: "" });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <button className="primary_button my-4" onClick={handleSubmit}>
              {strings.button.save_and_close}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
