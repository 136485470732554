import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import InterestIncome from "./InterestIncome";
import {
  deleteOtherIncomeSource,
  getOtherIncomeSource,
  setOtherIncomeSource,
} from "../../../../redux/itr/action";
import LoadingBox from "../../../DialogBox/LoadingBox";
import DividendIncome from "./DividendIncome";
import { toast } from "react-toastify";
import ExemptIncome from "./ExemptIncome";
import AnyOtherIncome from "./AnyOtherIncome";
import { addOpacity, removeEmptyKeys } from "../../../../utils/helpers";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
export default function OtherSourceIncome({ getData,handleRefresh }) {
  const [incomeSourceData, setIncomeSourceData] = useState({});
  const [interestIncomeBox, setInterestIncomeBox] = useState(false);
  const [dividendIncome, setDividendIncome] = useState(false);
  const [exemptIncome, setExemptIncome] = useState(false);
  const [otherIncome, setOtherIncome] = useState(false);
  const [incomeData, setIncomeData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData} = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  //   API Call
  const handleSubmitIncomeSourceApi = async (values) => {
    let data = removeEmptyKeys(values)
    setLoading(true);
    dispatch(
      setOtherIncomeSource({ ...data, itrId: ItrData.id }, (e) => {
        if (e.status == 200) {
          setIncomeSourceData({
            ...incomeSourceData,
            otherSourceIncome: e.data.otherSourceIncome[0],
          });
          setInterestIncomeBox(false);
          setDividendIncome(false);
          setExemptIncome(false);
          setOtherIncome(false);
          setLoading(false);
          toast.success('Successfully Submit Data')
          handleRefresh()
        } else {
          setLoading(false);
        }
      })
    );
  };

  const getIncomeSourceApi = async () => {
    setLoading(true);
    dispatch(
      getOtherIncomeSource(ItrData.id, (e) => {
        if (e.status == 200) {
          setIncomeSourceData(e.data);
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  const handleDeleteIncomeRecord = (incomeId, id, type) => {
    let parm = `?itrId=${ItrData.id}&typeOfIncome=${incomeId}&id=${id}`;
    setLoading(true);
    dispatch(
      deleteOtherIncomeSource(parm, (e) => {
        if (e.status == 200) {
          let newData = { ...incomeSourceData };
          delete newData.otherSourceIncome[type];
          setIncomeSourceData(newData);
          setIncomeData(
            incomeData.filter((item) => item.typeOfIncome !== incomeId)
          );
          setLoading(false);
        } else {
          toast.error(e.message);
          setLoading(false);
        }
      })
    );
  };

  // useEffect(() => {
  //   getIncomeSourceApi();
  // }, []);

  useEffect(() => {
    if (getData) {
      getIncomeSourceApi();
      setIncomeData(getData);
    }
  }, [getData]);


  return (
    <>
      <LoadingBox open={loading} />
      <AccordianBox
        title={strings.tax.other_source_income}
        description={
          strings.tax.fixed_deposite_savings_bank_interest_agriculture
        }
      >
        <StyleFormBox>
          <div className="flex justify-between items-center">
            <div>
              <h4>Interest income</h4>
              <p>
                Interest from Saving Bank, Deposits, P2P, Bonds, Income tax
                refund etc.
              </p>
            </div>
            <button
              className="secandary_button"
              style={{ width: "auto" }}
              onClick={() => {
                if (
                  incomeData.filter((i) => i.typeOfIncome === 103).length > 0
                ) {
                  toast.warning("Not allowed to add more than one entry");
                } else {
                  setInterestIncomeBox(true);
                }
              }}
            >
              {strings.button.add_entry}
            </button>
          </div>

          {incomeData
            .filter((i) => i.typeOfIncome === 103)
            .map((item, ix) => (
              <div
                className="flex gap-2 p-3 my-3 align-center"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
                key={ix}
              >
                <h4 className="mr-auto">{item.title}</h4>

                <h4>{item?.total}</h4>
                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => setInterestIncomeBox(true)}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      handleDeleteIncomeRecord(103, item.id, "interestIncome")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>

        <StyleFormBox>
          <div className="flex justify-between items-center">
            <div>
              <h4>Dividend Income</h4>
              <p>
                Dividend from investments in Equities, Stocks, Mutual Funds,
                ULIPs, UTI etc.
              </p>
            </div>
            <button
              className="secandary_button"
              style={{ width: "auto" }}
              onClick={() => {
                if (
                  incomeData.filter((i) => i.typeOfIncome === 104).length > 0
                ) {
                  toast.warning("Not allowed to add more than one entry");
                } else {
                  setDividendIncome(true);
                }
              }}
            >
              {strings.button.add_entry}
            </button>
          </div>
          {incomeData
            .filter((i) => i.typeOfIncome === 104)
            .map((item, ix) => (
              <div
                className="flex gap-2 p-3 my-3 align-center"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
                key={ix}
              >
                <h4 className="mr-auto">{item.title}</h4>

                <h4>{item?.total}</h4>
                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => setDividendIncome(true)}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      handleDeleteIncomeRecord(104, item.id, "dividendIncome")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>

        <StyleFormBox>
          <div className="flex justify-between items-center">
            <div>
              <h4>Exempt Income</h4>
              <p>
                Any income from interests from PPF or any other income which is
                exempt.
              </p>
            </div>
            <button
              className="secandary_button"
              style={{ width: "auto" }}
              onClick={() => {
                if (
                  incomeData.filter((i) => i.typeOfIncome === 106).length > 0
                ) {
                  toast.warning("Not allowed to add more than one entry");
                } else {
                  setExemptIncome(true);
                }
              }}
            >
              {strings.button.add_entry}
            </button>
          </div>
          {incomeData
            .filter((i) => i.typeOfIncome === 106)
            .map((item, ix) => (
              <div
                className="flex gap-2 p-3 my-3 align-center"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
                key={ix}
              >
                <h4 className="mr-auto">{item.title}</h4>

                <h4>{item?.total}</h4>
                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => setExemptIncome(true)}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      handleDeleteIncomeRecord(106, item.id, "exemptIncome")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>

        <StyleFormBox>
          <div className="flex justify-between items-center">
            <div>
              <h4>Any Other Income</h4>
              <p>
                Invoice Discounting, Online Gaming, Puzzles, Lottery Winnings
                etc.
              </p>
            </div>
            <button
              className="secandary_button"
              style={{ width: "auto" }}
              onClick={() => {
                if (
                  incomeData.filter((i) => i.typeOfIncome === 107).length > 0
                ) {
                  toast.warning("Not allowed to add more than one entry");
                } else {
                  setOtherIncome(true);
                }
              }}
            >
              {strings.button.add_entry}
            </button>
          </div>
          {incomeData
            .filter((i) => i.typeOfIncome === 107)
            .map((item, ix) => (
              <div
                className="flex gap-2 p-3 my-3 align-center"
                style={{
                  background: addOpacity(theme.primary_bg, 0.2),
                  borderRadius: "10px",
                }}
                key={ix}
              >
                <h4 className="mr-auto">{item.title}</h4>

                <h4>{item?.total}</h4>
                <div className="action_buttons">
                  <button
                    className="edit_btn"
                    onClick={() => setOtherIncome(true)}
                  >
                    <FaRegEdit />
                  </button>
                  <button
                    className="delete_btn"
                    onClick={() =>
                      handleDeleteIncomeRecord(107, item.id, "anyOtherIncome")
                    }
                  >
                    <RiDeleteBin6Fill />
                  </button>
                </div>
              </div>
            ))}
        </StyleFormBox>

        {/* Intrest Income */}
        <FormDialogBox
          open={interestIncomeBox}
          formTitle={strings.income.interest_income}
          handleClose={() => {
            setInterestIncomeBox(false);
          }}
        >
          <InterestIncome
            handleCallback={(data) => handleSubmitIncomeSourceApi(data)}
            incomeData={incomeSourceData?.otherSourceIncome?.interestIncome}
          />
        </FormDialogBox>

        {/* Dividend Income  */}
        <FormDialogBox
          open={dividendIncome}
          formTitle={strings.income.dividend_income}
          handleClose={() => {
            setDividendIncome(false);
          }}
        >
          <DividendIncome
            handleCallback={(data) => handleSubmitIncomeSourceApi(data)}
            incomeData={incomeSourceData?.otherSourceIncome?.dividendIncome}
          />
        </FormDialogBox>

        {/* Exempt income */}
        <FormDialogBox
          open={exemptIncome}
          formTitle={strings.income.exempt_income}
          handleClose={() => {
            setExemptIncome(false);
          }}
        >
          <ExemptIncome
            incomeSourceOption={incomeSourceData?.exemptIncomeSource}
            handleCallback={(data) => handleSubmitIncomeSourceApi(data)}
            incomeData={incomeSourceData?.otherSourceIncome?.exemptIncome}
          />
        </FormDialogBox>

        {/* Other income */}
        <FormDialogBox
          open={otherIncome}
          formTitle={strings.income.any_other_income}
          handleClose={() => {
            setOtherIncome(false);
          }}
        >
          <AnyOtherIncome
            waterSource={incomeSourceData?.agrWaterSource}
            countryList={incomeSourceData?.retirementAccountCountry}
            otherIncome={incomeSourceData?.otherIncome}
            handleCallback={(data) => handleSubmitIncomeSourceApi(data)}
            incomeData={incomeSourceData?.otherSourceIncome?.anyOtherIncome}
          />
        </FormDialogBox>
      </AccordianBox>
    </>
  );
}
