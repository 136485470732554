import React from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import PrimaryButton from "../../../../styledComponent/Button/PrimaryButton";

export default function ScheduleEsr() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const data = [
    {
      id: 1,
      section: strings.balance_sheet.section_35_1_i,
      value: "",
    },
    {
      id: 2,
      section: strings.balance_sheet.section_35_1_ii,
      value: "",
    },
    {
      id: 3,
      section: strings.balance_sheet.section_35_1_iii,
      value: "",
    },
    {
      id: 4,
      section: strings.balance_sheet.section_35_1_iv,
      value: "",
    },
    {
      id: 5,
      section: strings.balance_sheet.section_35_1_2aa,
      value: "",
    },
    {
      id: 6,
      section: strings.balance_sheet.section_35_1_2ab,
      value: "",
    },
  ];
  return (
    <div>
      <h4>{strings.balance_sheet.esr_details}</h4>

      {data.map((item, ix) => {
        return (
          <StyleFormBox>
            <h4>{item.section}</h4>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.amount_debited}</label>
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
            <div className="flex_input my-2">
              <label>{strings.balance_sheet.amount_deduction_allowable}</label>
              <CurrencyField error={""} value={""} handleChange={() => {}} />
            </div>
          </StyleFormBox>
        );
      })}

      <PrimaryButton
        title={strings.button.save_and_close}
        handleClick={() => {}}
      />
    </div>
  );
}
