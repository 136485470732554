import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import FaqAccordianBox from "../styledComponent/FaqAccordianBox";
import { getFaqAction } from "../redux/profile/action";
export default function Faq() {
  const [activeTab, setActiveTab] = useState("");
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const dispatch = useDispatch()
  useEffect(() => {
    setActiveTab("Who should file an Income Tax Return?");
    dispatch(getFaqAction((data)=>{
      console.log('faqs data ===>',data)
    }))
  }, []);
  return (
    <FaqBox theme={theme} className="container_box">
      <h4>FAQ Section</h4>
      <div className="tab_section">
        <button
          onClick={() => {
            setActiveTab("Who should file an Income Tax Return?");
          }}
          className={
            activeTab == "Who should file an Income Tax Return?" ? "active" : ""
          }
        >
          Who should file an Income Tax Return?
        </button>
        <button
          onClick={() => {
            setActiveTab("Where can I file an Income Tax Return in India?");
          }}
          className={
            activeTab == "Where can I file an Income Tax Return in India?"
              ? "active"
              : ""
          }
        >
          Where can I file an Income Tax Return in India?
        </button>
        <button
          onClick={() => {
            setActiveTab(
              "What if I miss the due date of filing the Income Tax Return?"
            );
          }}
          className={
            activeTab ==
            "What if I miss the due date of filing the Income Tax Return?"
              ? "active"
              : ""
          }
        >
          What if I miss the due date of filing the Income Tax Return?
        </button>
        <button
          onClick={() => {
            setActiveTab("How to file an Income Tax Return online?");
          }}
          className={
            activeTab == "How to file an Income Tax Return online?"
              ? "active"
              : ""
          }
        >
          How to file an Income Tax Return online?
        </button>
      </div>
      <hr className="opacity-20 my-4"/>
      {activeTab == "Who should file an Income Tax Return?" ? (
       <div>
       <FaqAccordianBox
          title={"Can I collaborate under the free pIan?"}
          description={
            "Yes! You can share any files in your personal space with many people as you want. Also, if you want to try outthe team features, you can create a team of two editors for. You can share any files in your personal space with  as people as you want. "
          }
        />
         <FaqAccordianBox
          title={"Can I collaborate under the free pIan?"}
          description={
            "Yes! You can share any files in your personal space with many people as you want. Also, if you want to try outthe team features, you can create a team of two editors for. You can share any files in your personal space with  as people as you want. "
          }
        />
       </div>
      ) : (
       ''
      )}
    </FaqBox>
  );
}
const FaqBox = styled.div`
  background-color: ${(p) => p.theme.secandary_bg};
  padding: 20px;
  margin: 30px auto 0px;
  border-radius: 10px;
  .tab_section {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    > button {
      display: block;
      text-align: left;
      border: 1px solid ${(p) => p.theme.border_clr};
      padding: 10px;
      border-radius: 10px;
      margin: 10px 0px;
      transition: all 0.5s;
      &:hover {
        background-color: ${(p) => p.theme.tertiary_bg};
      }
      &.active {
        background-color: ${(p) => p.theme.tertiary_bg};
      }
    }
  }
`;
