import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import Input from "../../../../styledComponent/Inputs/Input";
import { RiDeleteBin6Fill } from "react-icons/ri";
import Select from "../../../../styledComponent/Inputs/Select";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";
import LoadingBox from "../../../DialogBox/LoadingBox";

export default function AnyOtherIncome({
  waterSource,
  countryList,
  otherIncome,
  handleCallback,
  incomeData,
}) {
  const [formData, setFormData] = useState();

  const [loader, setLoader] = useState(true);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const validationSchema = Yup.object().shape({
    onlineGamingLottery: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required(strings.validation_message.required),
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    incomeFromOtherSources: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required(strings.validation_message.required),
        description: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    incomeFromRetirementAccounts: Yup.array().of(
      Yup.object().shape({
        retirementAccountCountry: Yup.string().required(strings.validation_message.required),
        timePeriod: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
    deductibleExpensesDepreciation: Yup.array().of(
      Yup.object().shape({
        source: Yup.string().required(strings.validation_message.required),
        amount: Yup.number()
          .required(strings.validation_message.required)
          .moreThan(0, strings.validation_message.amount_greater_than_0),
      })
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      typeOfIncome: 107,
      onlineGamingLottery: [
        {
          source: "",
          description: "",
          amount: "",
        },
      ],
      incomeFromOtherSources: [
        {
          source: "",
          description: "",
          amount: "",
        },
      ],
      incomeFromRetirementAccounts: [
        {
          retirementAccountCountry: "",
          timePeriod: "",
          amount: "",
        },
      ],
      incomeClaimedForReliefFromTaxation89a: "",
      incomeRetirementAccountNonNotifiedCountry: "",
      incomeTaxableDuringPreviousYear: "",
      deductibleExpensesDepreciation: [
        {
          source: "",
          amount: "",
        },
      ],
    });
    setLoader(false);
  }, [incomeData]);

  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleCallback(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="onlineGamingLottery">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 className="mt-1">
                    {strings.income.online_gaming_lottery_gifts_and_more}
                  </h4>
                  <p>
                    {
                      strings.income
                        .income_lottery_winnings_games_crossword_puzzles_horse_races
                    }
                  </p>

                  {values?.onlineGamingLottery?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <div>
                        <label>{strings.income.source} </label>
                        <Select
                          value={item.source}
                          name={`onlineGamingLottery.${index}.source`}
                          error={
                            errors?.onlineGamingLottery &&
                            errors?.onlineGamingLottery[index]?.source
                          }
                          onChange={handleChange}
                        >
                          <option value={""} disabled>
                            {strings.label.choose_option}
                          </option>
                          {otherIncome?.map((i, ix) => (
                            <option value={i.id} key={ix}>
                              {i.name}
                            </option>
                          ))}
                        </Select>
                      </div>

                      <div>
                        <label>{strings.label.description}</label>
                        <Input
                          value={item.description}
                          name={`onlineGamingLottery.${index}.description`}
                          placeholder={strings.label.description}
                          error={
                            errors?.onlineGamingLottery &&
                            errors?.onlineGamingLottery[index]?.description
                          }
                          onChange={handleChange}
                        />
                      </div>

                      <div>
                        <label>{strings.income.amount}</label>
                        <Input
                          type={"number"}
                          inputType={"currency"}
                          name={`onlineGamingLottery.${index}.amount`}
                          value={item.amount}
                          error={
                            errors?.onlineGamingLottery &&
                            errors?.onlineGamingLottery[index]?.amount
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <button
                        className="delete_button mt-6"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ source: "", description: "", amount: '' });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <FieldArray name="incomeFromOtherSources">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 className="mt-1">
                    {strings.income.income_from_other_source}
                  </h4>
                  <p>
                    {
                      strings.income
                        .report_other_income_which_not_part_of_income_from_salary
                    }
                  </p>

                  {values?.incomeFromOtherSources?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <div>
                        <label>{strings.income.source} </label>
                        <Select
                          value={item.source}
                          name={`incomeFromOtherSources.${index}.source`}
                          onChange={handleChange}
                          error={
                            errors?.incomeFromOtherSources &&
                            errors?.incomeFromOtherSources[index]?.source
                          }
                        >
                          <option value={""} disabled>
                            {strings.label.choose_option}
                          </option>
                          {otherIncome?.map((i, ix) => (
                            <option value={i.id} key={ix}>
                              {i.name}
                            </option>
                          ))}
                        </Select>
                      </div>

                      <div>
                        <label>{strings.label.description}</label>
                        <Input
                          value={item.description}
                          name={`incomeFromOtherSources.${index}.description`}
                          placeholder={strings.label.description}
                          error={
                            errors?.incomeFromOtherSources &&
                            errors?.incomeFromOtherSources[index]?.description
                          }
                          onChange={handleChange}
                        />
                      </div>

                      <div>
                        <label>{strings.income.amount}</label>
                        <Input
                          type={"number"}
                          inputType={"currency"}
                          name={`incomeFromOtherSources.${index}.amount`}
                          value={item.amount}
                          error={
                            errors?.incomeFromOtherSources &&
                            errors?.incomeFromOtherSources[index]?.amount
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <button
                        className="delete_button mt-6"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ source: "", description: "", amount: '' });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <FieldArray name="incomeFromRetirementAccounts">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 className="mt-1">
                    {
                      strings.income
                        .income_from_retirement_account_usa_uk_canada
                    }
                  </h4>
                  <p>
                    {
                      strings.income
                        .applicable_for_claiming_relief_under_section_89a
                    }
                  </p>

                  {values?.incomeFromRetirementAccounts?.map((item, index) => (
                    <div className="flex_input my-2 items-center" key={index}>
                      <div>
                        <label>{strings.address.country}</label>
                        <Select
                          value={item.retirementAccountCountry}
                          name={`incomeFromRetirementAccounts.${index}.retirementAccountCountry`}
                          onChange={handleChange}
                          error={
                            errors?.incomeFromRetirementAccounts &&
                            errors?.incomeFromRetirementAccounts[index]
                              ?.retirementAccountCountry
                          }
                        >
                          <option value={""} disabled>
                            {strings.label.choose_option}
                          </option>
                          {countryList?.map((i, ix) => (
                            <option value={i.id} key={ix}>
                              {i.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <label>{strings.income.amount}</label>
                        <Input
                          type={"number"}
                          inputType={"currency"}
                          name={`incomeFromRetirementAccounts.${index}.amount`}
                          value={item.amount}
                          error={
                            errors?.incomeFromRetirementAccounts &&
                            errors?.incomeFromRetirementAccounts[index]?.amount
                          }
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        <label>{strings.label.time_period}</label>
                        <Input
                          type={"number"}
                          name={`incomeFromRetirementAccounts.${index}.timePeriod`}
                          value={item.timePeriod}
                          error={
                            errors?.incomeFromRetirementAccounts &&
                            errors?.incomeFromRetirementAccounts[index]
                              ?.timePeriod
                          }
                          onChange={handleChange}
                        />
                      </div>

                      <button
                        className="delete_button mt-6"
                        onClick={() => remove(index)}
                      >
                        <RiDeleteBin6Fill />
                      </button>
                    </div>
                  ))}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({
                          retirementAccountCountry: "",
                          amount: '',
                          timePeriod: '',
                        });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>

                  <hr />

                  <div className="flex_input my-2">
                    <div>
                      <label>
                        {
                          strings.income
                            .income_claimed_for_relief_from_taxtation
                        }
                      </label>
                      <p>
                        {
                          strings.income
                            .note_that_you_should_submit_form_10ef_the_income_tax_department_order
                        }
                      </p>
                    </div>
                    <Input
                      type={"number"}
                      inputType={"currency"}
                      name={"incomeClaimedForReliefFromTaxation89a"}
                      value={values?.incomeClaimedForReliefFromTaxation89a}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .income_from_retirement_benefit_account_maintained
                      }
                    </label>
                    <Input
                      type={"number"}
                      inputType={"currency"}
                      name={"incomeRetirementAccountNonNotifiedCountry"}
                      value={values?.incomeRetirementAccountNonNotifiedCountry}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input my-2">
                    <label>
                      {
                        strings.income
                          .income_taxable_during_the_previous_year_which_relief_89a_was_claimed
                      }
                    </label>
                    <Input
                      type={"number"}
                      inputType={"currency"}
                      name={"incomeTaxableDuringPreviousYear"}
                      value={values?.incomeTaxableDuringPreviousYear}
                      onChange={handleChange}
                    />
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <FieldArray name="deductibleExpensesDepreciation">
              {({ insert, remove, push }) => (
                <StyleFormBox>
                  <h4 className="mt-1">
                    {strings.income.deductible_expenses_and_depreciation}
                  </h4>
                  <p>
                    {
                      strings.income
                        .claim_deductions_for_expenses_related_to_income
                    }
                  </p>

                  {values?.deductibleExpensesDepreciation?.map(
                    (item, index) => (
                      <div className="flex_input my-2 items-center" key={index}>
                        <div>
                          <label>{strings.income.source} </label>
                          <Select
                            value={item.source}
                            name={`deductibleExpensesDepreciation.${index}.source`}
                            error={
                              errors?.deductibleExpensesDepreciation &&
                              errors?.deductibleExpensesDepreciation[index]?.source
                            }
                            onChange={handleChange}
                          >
                            <option value={""} disabled>
                              {strings.label.choose_option}
                            </option>
                            {otherIncome?.map((i, ix) => (
                              <option value={i.id} key={ix}>
                                {i.name}
                              </option>
                            ))}
                          </Select>
                        </div>

                        <div>
                          <label>{strings.income.amount}</label>
                          <Input
                            type={"number"}
                            inputType={"currency"}
                            name={`deductibleExpensesDepreciation.${index}.amount`}
                            value={item.amount}
                            error={
                              errors?.deductibleExpensesDepreciation &&
                              errors?.deductibleExpensesDepreciation[index]?.amount
                            }
                            onChange={handleChange}
                          />
                        </div>

                        <button
                          className="delete_button mt-6"
                          onClick={(e) => {
                            e.preventDefault();
                            remove(index);
                          }}
                        >
                          <RiDeleteBin6Fill />
                        </button>
                      </div>
                    )
                  )}

                  <div className="text-right mt-5">
                    <button
                      className="add_item_button"
                      onClick={(e) => {
                        e.preventDefault();
                        push({ source: "", amount: '' });
                      }}
                    >
                      {strings.button.add_more_item}
                    </button>
                  </div>
                </StyleFormBox>
              )}
            </FieldArray>

            <button
              className="primary_button my-4"
              type="submit"
              onClick={handleSubmit}
            >
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
