import axios from "axios";
import { toast } from "react-toastify";
import { store } from "../redux/store";
import { userLogoutAction } from "../redux/profile/action";

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 500) {
      toast.error("Token Expire");
      store.dispatch(userLogoutAction());
      window.location.href = "/login";
    } else if (error.response && error.response.status === 401) {
      store.dispatch(userLogoutAction());
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export const httpPost = (url, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

export const httpGet = async (url, params) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

/* PUT Request you will get exect response you have to extrect data from it in the saga itself */
export const httpPut = (url, data) =>
  new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        withCredentials: true,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error.response);
      });
  });

/* PATCH Request */
// export const httpPatch = (url, data, params = {}) =>
// new Promise((resolve, reject) => {
//   axios
//     .patch(formatUrl(url, params), { withCredentials: true }, data)
//     .then((res) => {
//       resolve(res?.data);
//     })
//     .catch((error) => {
//       reject(error.response);
//     });
// });

/* DELETE Request */
export const httpDelete = (url = {}) =>
  new Promise((resolve, reject) => {
    axios
      .delete(url)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
