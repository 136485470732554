import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import { getCitiesByIdAction } from "../../../../redux/platformSetting/action";
import styled from "styled-components";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import Phone from "../../../../styledComponent/Inputs/Phone";
import * as Yup from "yup";

import { Formik } from "formik";
import LoadingBox from "../../../DialogBox/LoadingBox";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";

export default function BadDebts() {
  const { strings, theme, states } = useSelector((s) => s.platformSetting);
  const [citiesList, setCitiesList] = useState([]);
  const [activeState, setActiveState] = useState(1);
  const [loading, setLoading] = useState(false);
  const { ItrData } = useSelector((s) => s.itr);
  const [formData, setFormData] = useState({});

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(strings.validation_message.required),
    amount: Yup.number().required(strings.validation_message.required),
    address: Yup.object().shape({
      flatDoorNo: Yup.string().required(strings.validation_message.required),
      premiseName: Yup.string().required(strings.validation_message.required),
      street: Yup.string().required(strings.validation_message.required),
      area: Yup.string().required(strings.validation_message.required),
      city: Yup.string().required(strings.validation_message.required),
      state: Yup.string().required(strings.validation_message.required),
      country: Yup.string().required(strings.validation_message.required),
      pinCode: Yup.string().required(strings.validation_message.required),
    }),
  });

  const getCities = () => {
    setCitiesList([]);
    dispatch(
      getCitiesByIdAction(activeState, (e) => {
        setCitiesList(e);
      })
    );
  };

  const handleFormSubmit = async (data) => {
    let updateData = {
      ...data,
      mobileNo: data.mobileNo?.slice(data.countryCodeMobile.length),
      itrId: ItrData.id,
      address: {
        ...data.address,
        mobileNo: data.address.mobileNo?.slice(
          data.address?.countryCodeMobile?.length
        ),
      },
    };
    if (updateData?.aadhaarCardNo?.length > 12) {
      delete updateData.aadhaarEnrollmentNo;
    }
  };

  useEffect(() => {
    getCities();
  }, [activeState]);

  return (
    <Root>
      <LoadingBox open={loading} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleFormSubmit(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <>
            <StyleFormBox>
              <h4 className="mb-5 mt-5" style={{ color: theme.primary_txt }}>
                {strings.detail_balance_sheet.fillDetails}*
              </h4>
              <div className="flex_input">
                <div>
                  <label>{strings.detail_balance_sheet.name} *</label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.detail_balance_sheet.name}*`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values.address && values.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.detail_balance_sheet.amount} *</label>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.detail_balance_sheet.amount}
                    error={errors.address && errors.address.premiseName}
                    value={values.address && values.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex_input">
                <div>
                  <label>{strings.detail_balance_sheet.flatDoorBlock}</label>
                  <Input
                    name={"address.flatDoorNo"}
                    placeholder={`${strings.detail_balance_sheet.flatDoorBlock}`}
                    error={errors.address && errors.address.flatDoorNo}
                    value={values.address && values.address.flatDoorNo}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.detail_balance_sheet.premiseName}</label>
                  <p style={{ marginTop: "-15px" }}>
                    {strings.detail_balance_sheet.optionalMsg}
                  </p>
                  <Input
                    name={"address.premiseName"}
                    placeholder={strings.address.premise_name}
                    error={errors.address && errors.address.premiseName}
                    value={values.address && values.address.premiseName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.detail_balance_sheet.roadStreet}</label>
                  <p style={{ marginTop: "-15px" }}>
                    {strings.detail_balance_sheet.optionalMsg}
                  </p>
                  <Input
                    name={"address.street"}
                    placeholder={`${strings.detail_balance_sheet.roadStreet}`}
                    error={errors.address && errors.address.street}
                    value={values.address && values.address.street}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label>{strings.detail_balance_sheet.areaLocality}</label>
                  <Input
                    name={"address.area"}
                    placeholder={strings.detail_balance_sheet.areaLocality}
                    error={errors.address && errors.address.area}
                    value={values.address && values.address.area}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.detail_balance_sheet.pincode}</label>
                  <Input
                    name={"address.pinCode"}
                    placeholder={strings.detail_balance_sheet.pincode}
                    error={errors.address && errors.address.pinCode}
                    value={values.address && values.address.pinCode}
                    onChange={(e) => {
                      if (e.target.value.length < 7) {
                        handleChange(e);
                      }
                    }}
                  />
                </div>

                <div>
                  <label>{strings.detail_balance_sheet.country}</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values.address && values.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          state: e.target.value,
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value={101}>
                      {strings.detail_balance_sheet.india}
                    </option>
                  </Select>
                </div>
              </div>

              <div className="flex_input">
                <div>
                  <label>{strings.detail_balance_sheet.state}</label>
                  <Select
                    name="address.state"
                    error={errors.address && errors.address.state}
                    value={values.address && values.address.state}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        address: {
                          ...values.address,
                          state: e.target.value,
                        },
                      });
                      setActiveState(Number(e.target.value));
                    }}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {states?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div>
                  <label>{strings.detail_balance_sheet.townCity}</label>
                  <Select
                    name={"address.city"}
                    error={errors.address && errors.address.city}
                    value={values.address && values.address.city}
                    onChange={handleChange}
                  >
                    <option value="">{strings.label.choose_option}</option>
                    {citiesList?.map((i, ix) => (
                      <option value={i.id} key={ix}>
                        {i.name}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </StyleFormBox>
            <div className="flex gap-4">
              <button className="secandary_button mt-5" onClick={handleSubmit}>
                {strings.button.save}
              </button>
              <button className="primary_button mt-5" onClick={handleSubmit}>
                {strings.button.save_and_close}
              </button>
            </div>
          </>
        )}
      </Formik>

      <StyleFormBox>
        <div className="edit_box">
          <h2>Box</h2>
          <div className="action_buttons">
            <button className="edit_btn">
              <FaRegEdit />
            </button>
            <button className="delete_btn">
              <RiDeleteBin6Fill />
            </button>
          </div>
        </div>
        <label>{strings.detail_balance_sheet.name}: Ankit Sharma</label>
        <label>{strings.detail_balance_sheet.amount}: 500</label>
      </StyleFormBox>
    </Root>
  );
}
const Root = styled.section`
  .edit_box {
    display: flex;
    justify-content: space-between;
  }
`;
