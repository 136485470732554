import React, { useState } from "react";
import AccordianBox from "../../../../styledComponent/Form/AccordianBox";
import { useSelector } from "react-redux";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import CurrencyField from "../../../../styledComponent/Inputs/CurrencyField";
import Input from "../../../../styledComponent/Inputs/Input";
import AddActionButton from "../../../../styledComponent/Button/AddActionButton";
import { RiDeleteBin6Fill } from "react-icons/ri";
import FormDialogBox from "../../../DialogBox/FormDialogBox";
import { FaGalacticSenate } from "react-icons/fa6";
import BadDebts from "./BadDebts";

export default function DetailBalanceSheet() {
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const str = strings?.detail_balance_sheet;
  const [badDebtPop, setBadDebtPop] = useState(false);

  const [sourcesFunds, setSourcesOfFund] = useState({
    proprietorCapital: "",
    revaluationReserve: "",
    capitalReserve: "",
    statutoryReserve: "",
    anyOtherReserve: "",
    foreignCurrency: "",
    rupeeLoansFromBank: "",
    rupeeLoanFromOthers: "",
    unsecuredLoanBank: "",
    unsecuredLoanOthers: "",
    deferredTaxLiability: "",
    advancesFromPerson: "",
    advancesFromOthers: "",
  });

  const [applicationFunds, setApplicationFunds] = useState({
    fixedAssetGross: "",
    fixedAssetDepreciation: "",
    capitalWorkInProgress: "",

    longTermQuoted: "",
    longTermUnquoted: "",

    shortTermEquityShares: "",
    shortTermPreferenceShares: "",
    shortTermDebentures: "",

    currentAssetStore: "",
    currentAssetRawMaterial: "",
    currentAssetStock: "",
    currentAssetFinishedGoods: "",

    sundryDebtors: "",
    cashInHand: "",
    balanceWithBanks: "",
    otherCurrentAssets: "",

    loansAdvancesRecoverable: "",
    loansDeposits: "",
    loansBalanceWithRevenue: "",

    currentLiabilitiesSundry: "",
    LiabilityForLeased: "",
    interestAccruedAbove: "",
    interestAccruedNotDue: "",

    provisionForIncomeTax: "",
    provisionForLeave: "",
    otherProvisions: "",

    miscellaneousExpenditure: "",
    deferredTaxAsset: "",
    profitAndLossAccount: "",
  });

  const [manufactureAccount, setManufactureAccount] = useState({
    openingStockRawMaterial: "",
    openingStockWrokInProgress: "",

    purchasesRefunds: "",
    directWages: "",
    carriageInward: "",
    powerAndFuel: "",
    otherDirectExpenses: "",

    indirectWages: "",
    factoryRent: "",
    factoryInsurance: "",
    factoryFuelAndPower: "",
    factoryGeneralExpenses: "",
    depreciationOfFactory: "",

    closingRawMaterial: "",
    closingWorkInProgress: "",
  });

  const [tradingAccount, setTradingAccount] = useState({
    revenueOperationSaleOfGoods: "",
    revenueOperationSaleOfServices: "",
    otherOperatingRevenue: [
      {
        name: "",
        amount: "",
      },
    ],
    grossReceiptProfession: "",
    unionExciseDuties: "",
    serviceTaxSupplied: "",
    vatSalesTaxSupplied: "",
    centralGoodsServiceSupplied: "",
    stateGoodsServiceSupplied: "",
    integratedGoodsServiceSupplied: "",
    unionTerritoryServiceSupplied: "",
    anyOtherTaxSupplied: "",

    closingStockOfFinishedStocks: "",

    openingStocksOfFinishedGoods: "",
    purchasesNetOfRefundAndDuty: "",

    directExpensesCarriage: "",
    directExpensesPowerAndFuel: "",
    otherDirectExpenses2: [
      {
        name: "",
        amount: "",
      },
    ],

    customDuty: "",
    counterWellingDuty: "",
    specialAdditionalDuty: "",
    unionExciseDuty: "",
    serviceTaxPurchased: "",
    vatSalesTaxPurchased: "",
    centralGoodsServicePurchased: "",
    stateGoodsServicePurchased: "",
    integratedGoodsServicePurchased: "",
    unionTerritoryServicePurchased: "",
    anyOtherTaxPurchased: "",

    turnoverFromIntradayTrading: "",
    incomeFromIntradayTrading: "",
  });

  const [profitAndLoss, setProfitAndLoss] = useState({
    otherIncomeRent: "",
    otherIncomeCommission: "",
    otherIncomeDivident: "",
    otherIncomeInterest: "",
    otherIncomeProfitFixedAsset: "",
    otherIncomeSTT: "",
    otherIncomeOtherInvestment: "",
    otherIncomeGain43AA: "",
    otherIncomeProfit28: "",
    otherIncomeAgricultural: "",
    otherIncomeAnyOther: [
      {
        name: "",
        amount: "",
      },
    ],

    freightOutward: "",
    consumptionOfStoresAndSpare: "",
    powerAndFuelPAndL: "",
    rentsPAndL: "",
    repairsToBuilding: "",
    repairsToMachinery: "",

    compSalariesAndWages: "",
    compBonus: "",
    compReimbursementOfMedical: "",
    compLeaveEncashment: "",
    compLeaveTravel: "",
    compSuperannuationFund: "",
    compRecognisedPF: "",
    compGratuityFund: "",
    compOtherFund: "",
    anyOtherBenefitToEmp: "",

    amountPaidToNonResidents: "",

    medicalInsurance: "",
    lifeInsurance: "",
    keyManInsurance: "",
    OtherInsurance: "",

    workmenAndStaffWelfare: "",
    entertainment: "",
    hospitality: "",
    conference: "",
    salesPromotion: "",
    advertisement: "",

    commissionPaidOutsideIndia: "",
    commissionOthers: "",

    royaltyPainOutside: "",
    royaltyOthers: "",

    profConsFeePaidOutsideIndia: "",
    profConsOthers: "",

    hotelBoarding: "",
    travelingExpenses: "",
    foreignTravelling: "",
    conveyanceExp: "",
    telephoneExp: "",
    guestHouseExp: "",
    clubExp: "",
    festivalCel: "",
    scholarship: "",
    gift: "",
    donation: "",

    ratesTaxesUnionExcise: "",
    ratesTaxesServiceTax: "",
    ratesTaxesVatSalesTax: "",
    ratesTaxesCess: "",
    ratesTaxesCGST: "",
    ratesTaxesSGST: "",
    ratesTaxesIGST: "",
    ratesTaxesUTGST: "",
    ratesTaxesSTTAndCTT: "",
    auditFee: "",
    OtherExpenses: [
      {
        name: "",
        amount: "",
      },
    ],
    badDebtsWithPan: [
      {
        pan: "",
        amount: "",
      },
    ],
    otherAmountDownOneLakh: "",

    provisionForBadAndDoubtful: "",
    otherProvisions: "",

    interestPainOutsideIndia: "",
    interestToOthers: "",

    depreciationAndAmortisation: "",
    provisionForCurrentTax: "",
    provisionForFeferredTax: "",
    BalanceBroughtForwardFromPreviousYear: "",
    TransferredToReservesAndSurplus: "",
  });

  let total1bv =
    Number(sourcesFunds.revaluationReserve) +
    Number(sourcesFunds.capitalReserve) +
    Number(sourcesFunds.statutoryReserve) +
    Number(sourcesFunds.anyOtherReserve);

  let total1c = Number(sourcesFunds.proprietorCapital) + total1bv;
  let totalaiic =
    Number(sourcesFunds.rupeeLoansFromBank) +
    Number(sourcesFunds.rupeeLoanFromOthers);
  let total2aii = totalaiic + Number(sourcesFunds.foreignCurrency);
  let total2biii =
    Number(sourcesFunds.unsecuredLoanBank) +
    Number(sourcesFunds.unsecuredLoanOthers);
  let total2c = total2aii + total2biii;
  let total4iii =
    Number(sourcesFunds.advancesFromPerson) +
    Number(sourcesFunds.advancesFromOthers);
  let sourcesFund5 =
    total1c + total2c + Number(sourcesFunds.deferredTaxLiability) + total4iii;

  let netBlock1c =
    Number(applicationFunds.fixedAssetGross) -
    Number(applicationFunds.fixedAssetDepreciation);
  let total1e = netBlock1c + Number(applicationFunds.capitalWorkInProgress);
  let total2aiii =
    Number(applicationFunds.longTermQuoted) +
    Number(applicationFunds.longTermUnquoted);
  let total2biv =
    Number(applicationFunds.shortTermEquityShares) +
    Number(applicationFunds.shortTermPreferenceShares) +
    Number(applicationFunds.shortTermDebentures);
  let totalInvestment2c = total2biv + total2aiii;
  let total3aiE =
    Number(applicationFunds.currentAssetStore) +
    Number(applicationFunds.currentAssetRawMaterial) +
    Number(applicationFunds.currentAssetStock) +
    Number(applicationFunds.currentAssetFinishedGoods);
  let total3aiiiC =
    Number(applicationFunds.cashInHand) +
    Number(applicationFunds.balanceWithBanks);
  let totalCurrentAssets3av =
    total3aiiiC +
    Number(applicationFunds.otherCurrentAssets) +
    total3aiE +
    Number(applicationFunds.sundryDebtors);
  let total3biv =
    Number(applicationFunds.loansAdvancesRecoverable) +
    Number(applicationFunds.loansDeposits) +
    Number(applicationFunds.loansBalanceWithRevenue);
  let totalOfCurrentAsssetAdvances3c = totalCurrentAssets3av + total3biv;
  let total3diE =
    Number(applicationFunds.currentLiabilitiesSundry) +
    Number(applicationFunds.LiabilityForLeased) +
    Number(applicationFunds.interestAccruedAbove) +
    Number(applicationFunds.interestAccruedNotDue);
  let total3diiD =
    Number(applicationFunds.provisionForIncomeTax) +
    Number(applicationFunds.provisionForLeave) +
    Number(applicationFunds.otherProvisions);
  let total3diii = total3diE + total3diiD;
  let netCurrentAssets3e = totalOfCurrentAsssetAdvances3c - total3diii;
  let total4d =
    Number(applicationFunds.miscellaneousExpenditure) +
    Number(applicationFunds.deferredTaxAsset) +
    Number(applicationFunds.profitAndLossAccount);
  let totalApplicationOfFunds5 =
    total1e + totalInvestment2c + netCurrentAssets3e + total4d;

  let total1aiii =
    Number(manufactureAccount.openingStockRawMaterial) +
    Number(manufactureAccount.openingStockWrokInProgress);
  let directExpenses1D =
    Number(manufactureAccount.carriageInward) +
    Number(manufactureAccount.powerAndFuel) +
    Number(manufactureAccount.otherDirectExpenses);
  let total1evii =
    Number(manufactureAccount.indirectWages) +
    Number(manufactureAccount.factoryRent) +
    Number(manufactureAccount.factoryInsurance) +
    Number(manufactureAccount.factoryFuelAndPower) +
    Number(manufactureAccount.factoryGeneralExpenses) +
    Number(manufactureAccount.depreciationOfFactory);
  let totalEdbitsToManufacturingiF =
    total1aiii +
    directExpenses1D +
    Number(manufactureAccount.purchasesRefunds) +
    Number(manufactureAccount.directWages) +
    total1evii;
  let totalManufacturing2aiii =
    Number(manufactureAccount.closingRawMaterial) +
    Number(manufactureAccount.closingWorkInProgress);
  let costOfGoodsProduced3 =
    totalEdbitsToManufacturingiF - totalManufacturing2aiii;

  let total4Aiiia = tradingAccount.otherOperatingRevenue.reduce(
    (a, b) => a + Number(b.amount),
    0
  );

  let total4aiv =
    total4Aiiia +
    Number(tradingAccount.revenueOperationSaleOfGoods) +
    Number(tradingAccount.revenueOperationSaleOfServices);

  let total4cix =
    Number(tradingAccount.unionExciseDuties) +
    Number(tradingAccount.serviceTaxSupplied) +
    Number(tradingAccount.vatSalesTaxSupplied) +
    Number(tradingAccount.centralGoodsServiceSupplied) +
    Number(tradingAccount.stateGoodsServiceSupplied) +
    Number(tradingAccount.integratedGoodsServiceSupplied) +
    Number(tradingAccount.unionTerritoryServiceSupplied) +
    Number(tradingAccount.anyOtherTaxSupplied);

  let totalRevenue4d =
    total4aiv + total4cix + Number(tradingAccount.grossReceiptProfession);
  let totalCredits6 =
    totalRevenue4d + Number(tradingAccount.closingStockOfFinishedStocks);
  let total9Aiiia = tradingAccount.otherDirectExpenses2?.reduce(
    (a, b) => a + Number(b.amount),
    0
  );
  let directExpenses9 =
    total9Aiiia +
    Number(tradingAccount.directExpensesCarriage) +
    Number(tradingAccount.directExpensesPowerAndFuel);
  let total10Axii =
    Number(tradingAccount.customDuty) +
    Number(tradingAccount.counterWellingDuty) +
    Number(tradingAccount.specialAdditionalDuty) +
    Number(tradingAccount.unionExciseDuty) +
    Number(tradingAccount.serviceTaxPurchased) +
    Number(tradingAccount.vatSalesTaxPurchased) +
    Number(tradingAccount.centralGoodsServicePurchased) +
    Number(tradingAccount.stateGoodsServicePurchased) +
    Number(tradingAccount.integratedGoodsServicePurchased) +
    Number(tradingAccount.unionTerritoryServicePurchased) +
    Number(tradingAccount.anyOtherTaxPurchased);

  let costOfGoods11 = costOfGoodsProduced3;
  let grossProfitFromBusiness12 =
    totalCredits6 -
    Number(tradingAccount.openingStocksOfFinishedGoods) -
    Number(tradingAccount.purchasesNetOfRefundAndDuty) -
    directExpenses9 -
    total10Axii -
    costOfGoods11;

  let grossProfit13 = grossProfitFromBusiness12;
  let totalOFOtherIncome14xii =
    Number(profitAndLoss.otherIncomeRent) +
    Number(profitAndLoss.otherIncomeCommission) +
    Number(profitAndLoss.otherIncomeDivident) +
    Number(profitAndLoss.otherIncomeInterest) +
    Number(profitAndLoss.otherIncomeProfitFixedAsset) +
    Number(profitAndLoss.otherIncomeSTT) +
    Number(profitAndLoss.otherIncomeOtherInvestment) +
    Number(profitAndLoss.otherIncomeGain43AA) +
    Number(profitAndLoss.otherIncomeProfit28) +
    Number(profitAndLoss.otherIncomeAgricultural) +
    profitAndLoss.otherIncomeAnyOther?.reduce(
      (a, b) => a + Number(b.amount),
      0
    );

  let totalOfCredits15 = grossProfit13 + totalOFOtherIncome14xii;
  let totalCompensation22xi =
    Number(profitAndLoss.compSalariesAndWages) +
    Number(profitAndLoss.compBonus) +
    Number(profitAndLoss.compReimbursementOfMedical) +
    Number(profitAndLoss.compLeaveEncashment) +
    Number(profitAndLoss.compLeaveTravel) +
    Number(profitAndLoss.compSuperannuationFund) +
    Number(profitAndLoss.compRecognisedPF) +
    Number(profitAndLoss.compGratuityFund) +
    Number(profitAndLoss.compOtherFund) +
    Number(profitAndLoss.anyOtherBenefitToEmp);

  let totalExpenditure23v =
    Number(profitAndLoss.medicalInsurance) +
    Number(profitAndLoss.lifeInsurance) +
    Number(profitAndLoss.keyManInsurance) +
    Number(profitAndLoss.OtherInsurance);

  let total30iii =
    Number(profitAndLoss.commissionPaidOutsideIndia) +
    Number(profitAndLoss.commissionOthers);
  let total31iii =
    Number(profitAndLoss.royaltyPainOutside) +
    Number(profitAndLoss.royaltyOthers);
  let total32iii =
    Number(profitAndLoss.profConsFeePaidOutsideIndia) +
    Number(profitAndLoss.profConsOthers);

  let totalRates44x =
    Number(profitAndLoss.ratesTaxesUnionExcise) +
    Number(profitAndLoss.ratesTaxesServiceTax) +
    Number(profitAndLoss.ratesTaxesVatSalesTax) +
    Number(profitAndLoss.ratesTaxesCess) +
    Number(profitAndLoss.ratesTaxesCGST) +
    Number(profitAndLoss.ratesTaxesSGST) +
    Number(profitAndLoss.ratesTaxesIGST) +
    Number(profitAndLoss.ratesTaxesUTGST) +
    Number(profitAndLoss.ratesTaxesSTTAndCTT);

  let total46i = profitAndLoss.OtherExpenses?.reduce(
    (a, b) => a + Number(b.amount),
    0
  );
  let others47ii = 500;
  let totalBadDebts47iv =
    profitAndLoss.badDebtsWithPan?.reduce((a, b) => a + Number(b.amount), 0) +
    others47ii +
    Number(profitAndLoss.otherAmountDownOneLakh);
  let ProfitBeforeInterest50 =
    totalOfCredits15 -
    (Number(profitAndLoss.freightOutward) +
      Number(profitAndLoss.consumptionOfStoresAndSpare) +
      Number(profitAndLoss.powerAndFuelPAndL) +
      Number(profitAndLoss.rentsPAndL) +
      Number(profitAndLoss.repairsToBuilding) +
      Number(profitAndLoss.repairsToMachinery) +
      totalCompensation22xi +
      totalExpenditure23v +
      Number(profitAndLoss.workmenAndStaffWelfare) +
      Number(profitAndLoss.entertainment) +
      Number(profitAndLoss.hospitality) +
      Number(profitAndLoss.conference) +
      Number(profitAndLoss.salesPromotion) +
      Number(profitAndLoss.advertisement) +
      total30iii +
      total31iii +
      total32iii +
      Number(profitAndLoss.hotelBoarding) +
      Number(profitAndLoss.travelingExpenses) +
      Number(profitAndLoss.foreignTravelling) +
      Number(profitAndLoss.conveyanceExp) +
      Number(profitAndLoss.telephoneExp) +
      Number(profitAndLoss.guestHouseExp) +
      Number(profitAndLoss.clubExp) +
      Number(profitAndLoss.festivalCel) +
      Number(profitAndLoss.scholarship) +
      Number(profitAndLoss.gift) +
      Number(profitAndLoss.donation) +
      totalRates44x +
      Number(profitAndLoss.auditFee) +
      total46i +
      totalBadDebts47iv +
      Number(profitAndLoss.provisionForBadAndDoubtful) +
      Number(profitAndLoss.otherProvisions));

  let total51iii =
    Number(profitAndLoss.interestPainOutsideIndia) +
    Number(profitAndLoss.interestToOthers);
  let netProfit53 =
    ProfitBeforeInterest50 -
    total51iii -
    Number(profitAndLoss.depreciationAndAmortisation);
  let profitAfterTax56 =
    netProfit53 -
    Number(profitAndLoss.provisionForCurrentTax) -
    Number(profitAndLoss.provisionForFeferredTax);
  let amountAvailable58 =
    profitAfterTax56 +
    Number(profitAndLoss.BalanceBroughtForwardFromPreviousYear);
  let balanceCarriedtoBalanceSheet60 =
    amountAvailable58 - Number(profitAndLoss.TransferredToReservesAndSurplus);

  let totals = {
    total1bv,
    total1c,
    totalaiic,
    total2aii,
    total2biii,
    total2c,
    total4iii,
    sourcesFund5,
    netBlock1c,
    total1e,
    total2aiii,
    total2biv,
    totalInvestment2c,
    total3aiE,
    total3aiiiC,
    totalCurrentAssets3av,
    total3biv,
    totalOfCurrentAsssetAdvances3c,
    total3diE,
    total3diiD,
    total3diii,
    netCurrentAssets3e,
    total4d,
    totalApplicationOfFunds5,
    total1aiii,
    directExpenses1D,
    total1evii,
    totalEdbitsToManufacturingiF,
    totalManufacturing2aiii,
    costOfGoodsProduced3,
    total4Aiiia,
    total4aiv,
    total4cix,
    totalRevenue4d,
    totalCredits6,
    total9Aiiia,
    directExpenses9,
    total10Axii,
    costOfGoods11,
    grossProfitFromBusiness12,

    grossProfit13,
    totalOFOtherIncome14xii,
    totalOfCredits15,
    totalCompensation22xi,
    totalExpenditure23v,
    total30iii,
    total31iii,
    total32iii,
    totalRates44x,
    total46i,
    others47ii,
    totalBadDebts47iv,
    ProfitBeforeInterest50,
    total51iii,
    netProfit53,
    profitAfterTax56,
    amountAvailable58,
    balanceCarriedtoBalanceSheet60,
  };

  const sourceOfFundObj = [
    {
      title: str.proprietorTitle,
      subCategory: [
        {
          title: str.proprietorCapital,
          input: true,
          key: "proprietorCapital",
        },
        {
          title: str.reservesAndSurplus1b,
          subCategory: [
            {
              title: str.reserveAndSurplus1bi,
              input: true,
              key: "revaluationReserve",
            },
            {
              title: str.capitalReserve,
              input: true,
              key: "capitalReserve",
            },
            {
              title: str.statutoryReserve,
              input: true,
              key: "statutoryReserve",
            },
            {
              title: str.anyOtherReserve,
              input: true,
              key: "anyOtherReserve",
            },
            {
              title: str.total1bv,
              input: true,
              key: "total1bv",
              total: true,
            },
          ],
        },
        {
          title: str.total1c,
          key: "total1c",
          input: true,
          total: true,
        },
      ],
    },
    {
      title: str.loanFund,
      subCategory: [
        {
          title: str.securedLoan2a,
          subCategory: [
            {
              title: str.foreignCurrencyLoan,
              input: true,
              key: "foreignCurrency",
            },
          ],
        },
        {
          title: str.rupeeLoans2aii,
          subCategory: [
            {
              title: str.formBank,
              input: true,
              key: "rupeeLoansFromBank",
            },
            {
              title: str.fromOther2aiiB,
              input: true,
              key: "rupeeLoanFromOthers",
            },
            {
              title: str.totalaiic,
              key: "totalaiic",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.total2aii,
          key: "total2aii",
          input: true,
          total: true,
        },
        {
          title: str.unsecuredLoanDeposits,
          subCategory: [
            {
              title: str.fromBank2bi,
              input: true,
              key: "unsecuredLoanBank",
            },
            {
              title: str.fromOthers2bii,
              input: true,
              key: "unsecuredLoanOthers",
            },
            {
              title: str.total2biii,
              key: "total2biii",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.total2c,
          key: "total2c",
          input: true,
          total: true,
        },
      ],
    },
    {
      title: str.deferredTaxLiability3,
      input: true,
      key: "deferredTaxLiability",
    },
    {
      title: str.adavances4,
      subCategory: [
        {
          title: "",
          total: false,
          subCategory: [
            {
              title: str.fromPerson4i,
              input: true,
              key: "advancesFromPerson",
            },
            {
              title: str.fromOther4ii,
              input: true,
              key: "advancesFromOthers",
            },
            {
              title: str.total4iii,
              key: "total4iii",
              input: true,
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.sourcesFund5,
      input: true,
      total: true,
      key: "sourcesFund5",
    },
  ];

  const applicationOfFundObj = [
    {
      title: str.fixedAssets1,
      subCategory: [
        {
          title: str.grossBlock1a,
          key: "fixedAssetGross",
          input: true,
        },
        {
          title: str.depreciation1b,
          key: "fixedAssetDepreciation",
          input: true,
        },
        {
          title: str.netBlock1c,
          key: "netBlock1c",
          input: true,
          total: true,
        },
        {
          title: str.capitalWorkInProgress1d,
          key: "capitalWorkInProgress",
          input: true,
        },
        {
          title: str.total1e,
          key: "total1e",
          input: true,
          total: true,
        },
      ],
    },
    {
      title: str.investments2,
      subCategory: [
        {
          title: str.longTermInvestment2a,
          subCategory: [
            {
              title: str.govtAndOtherQuoted2ai,
              key: "longTermQuoted",
              input: true,
            },
            {
              title: str.govtAndOtherUnquoted2aii,
              key: "longTermUnquoted",
              input: true,
            },
            {
              title: str.total2aiii,
              key: "total2aiii",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.shortTermInvestments2b,
          subCategory: [
            {
              title: str.equityShares2bi,
              key: "shortTermEquityShares",
              input: true,
            },
            {
              title: str.preferenceShares2bii,
              key: "shortTermPreferenceShares",
              input: true,
            },
            {
              title: str.debentures2biii,
              key: "shortTermDebentures",
              input: true,
            },
            {
              title: str.total2biv,
              key: "total2biv",
              input: true,
              total: true,
            },
            {
              title: str.totalInvestment2c,
              key: "totalInvestment2c",
              input: true,
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.currentAssets3,
      subCategory: [
        {
          title: str.currentAssets3a,
        },
        {
          title: str.inventories3ai,
          subCategory: [
            {
              title: str.storesConsumables3aiA,
              key: "currentAssetStore",
              input: true,
            },
            {
              title: str.rawMaterials3aiB,
              key: "currentAssetRawMaterial",
              input: true,
            },
            {
              title: str.stockInProgress3aiC,
              key: "currentAssetStock",
              input: true,
            },
            {
              title: str.finishedGoods3aiD,
              key: "currentAssetFinishedGoods",
              input: true,
            },
            {
              title: str.total3aiE,
              key: "total3aiE",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.sundryDebtorseaii,
          input: true,
          key: "sundryDebtors",
        },
        {
          title: str.cashAndBankBalance3aiii,
          subCategory: [
            {
              title: str.cashInHand3aiiiA,
              key: "cashInHand",
              input: true,
            },
            {
              title: str.balanceWithBanks3aiiiB,
              key: "balanceWithBanks",
              input: true,
            },
            {
              title: str.total3aiiiC,
              key: "total3aiiiC",
              input: true,
              total: true,
            },
            {
              title: str.otherCurrentAssets3aiv,
              key: "otherCurrentAssets",
              input: true,
            },
            {
              title: str.totalCurrentAssets3av,
              key: "totalCurrentAssets3av",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.loansAndAdvances3b,
          subCategory: [
            {
              title: str.advancesRecoverable3bi,
              key: "loansAdvancesRecoverable",
              input: true,
            },
            {
              title: str.depositsLoans3bii,
              key: "loansDeposits",
              input: true,
            },
            {
              title: str.balanceWithRevenue3biii,
              key: "loansBalanceWithRevenue",
              input: true,
            },
            {
              title: str.total3biv,
              key: "total3biv",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.totalOfCurrentAsssetAdvances3c,
          input: true,
          key: "totalOfCurrentAsssetAdvances3c",
          total: true,
        },
        {
          title: str.currentLiabilities3di,
          subCategory: [
            {
              title: str.sundryCreditors3diA,
              key: "currentLiabilitiesSundry",
              input: true,
            },
            {
              title: str.liabilityLeased3diB,
              key: "LiabilityForLeased",
              input: true,
            },
            {
              title: str.interestAccrued3diC,
              key: "interestAccruedAbove",
              input: true,
            },
            {
              title: str.interestAccruedNotLoan,
              key: "interestAccruedNotDue",
              input: true,
            },
            {
              title: str.total3diE,
              key: "total3diE",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.provisions,
          subCategory: [
            {
              title: str.proForIncome3diiA,
              key: "provisionForIncomeTax",
              input: true,
            },
            {
              title: str.proLeaveSuperannuation3diiB,
              key: "provisionForLeave",
              input: true,
            },
            {
              title: str.otherProvisions3diiC,
              key: "otherProvisions",
              input: true,
            },
            {
              title: str.total3diiD,
              key: "total3diiD",
              input: true,
              total: true,
            },
          ],
        },
        {
          title: str.total3diii,
          key: "total3diii",
          input: true,
          total: true,
        },
        {
          title: str.netCurrentAssets3e,
          input: true,
          total: true,
          key: "netCurrentAssets3e",
        },
        {
          title: str.miscellaneous4a,
          key: "miscellaneousExpenditure",
          input: true,
        },
        {
          title: str.defferedTaxAsset4b,
          key: "deferredTaxAsset",
          input: true,
        },
        {
          title: str.profitAndLossAccount4c,
          key: "profitAndLossAccount",
          input: true,
        },
        {
          title: str.total4d,
          key: "total4d",
          total: true,
          input: true,
        },
        {
          title: str.totalApplicationOfFunds5,
          key: "totalApplicationOfFunds5",
          total: true,
          input: true,
        },
      ],
    },
  ];

  const manufacturingAccountObj = [
    {
      title: str.openingInventory1,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.openingStockRaw1ai,
              key: "openingStockRawMaterial",
            },
            {
              title: str.openingStockWork1aii,
              key: "openingStockWrokInProgress",
            },
            {
              title: str.total1aiii,
              key: "total1aiii",
              total: true,
            },
          ],
        },
        {
          title: str.purchasesNet1b,
          input: true,
          key: "purchasesRefunds",
        },
        {
          title: str.directWages1C,
          input: true,
          key: "directWages",
        },
        {
          title: str.directExpenses1D,
          input: true,
          total: true,
          key: "directExpenses1D",
          subCategory: [
            {
              title: str.carriageInward1di,
              key: "carriageInward",
            },
            {
              title: str.powerAndFuel1dii,
              key: "powerAndFuel",
            },
            {
              title: str.otherDirectExpenses1diii,
              key: "otherDirectExpenses",
            },
          ],
        },
        {
          title: str.factoryOverheads1E,
          subCategory: [
            {
              title: str.indirectWages1ei,
              key: "indirectWages",
            },
            {
              title: str.factoryRent1eii,
              key: "factoryRent",
            },
            {
              title: str.factoryInsurance1eiii,
              key: "factoryInsurance",
            },
            {
              title: str.factoryFuekAndPower1eiv,
              key: "factoryFuelAndPower",
            },
            {
              title: str.factoryGeneralExpenses1ev,
              key: "factoryGeneralExpenses",
            },
            {
              title: str.depreciationOfFactory1evi,
              key: "depreciationOfFactory",
            },
            {
              title: str.total1evii,
              key: "total1evii",
              total: true,
            },
          ],
        },
        {
          title: str.totalEdbitsToManufacturingiF,
          total: true,
          key: "totalEdbitsToManufacturingiF",
          input: true,
        },
      ],
    },
    {
      title: str.closingStock2,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.rawMaterial2ai,
              key: "closingRawMaterial",
            },
            {
              title: str.workInProgress2aii,
              key: "closingWorkInProgress",
            },
            {
              title: str.totalManufacturing2aiii,
              key: "totalManufacturing2aiii",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.costOfGoodsProduced3,
      total: true,
      input: true,
      key: "costOfGoodsProduced3",
    },
  ];

  const tradingAccountObj = [
    {
      title: str.revenueFromOperations4,
      subCategory: [
        {
          title: str.salesGrossReceipts4A,
          subCategory: [
            {
              title: str.saleOfGoods4ai,
              key: "revenueOperationSaleOfGoods",
            },
            {
              title: str.saleOfService4aii,
              key: "revenueOperationSaleOfServices",
            },
          ],
        },
        {
          title: str.otherOperatingRevenues4Aiii,
          addMore: true,
          key: "otherOperatingRevenue",
        },
        {
          title: "",
          subCategory: [
            {
              title: str.total4Aiiia,
              total: true,
              key: "total4Aiiia",
            },
            {
              title: str.total4aiv,
              total: true,
              key: "total4aiv",
            },
          ],
        },
        {
          title: str.grossReceipts4B,
          input: true,
          key: "grossReceiptProfession",
        },
        {
          title: str.dutiesTaxesAndCess4C,
          subCategory: [
            {
              title: str.unionExcise4ci,
              key: "unionExciseDuties",
            },
            {
              title: str.serviceTax4cii,
              key: "serviceTaxSupplied",
            },
            {
              title: str.vatSales4ciii,
              key: "vatSalesTaxSupplied",
            },
            {
              title: str.centralGoods4civ,
              key: "centralGoodsServiceSupplied",
            },
            {
              title: str.stateGoodsServices4cv,
              key: "stateGoodsServiceSupplied",
            },
            {
              title: str.integratedGoods4cvi,
              key: "integratedGoodsServiceSupplied",
            },
            {
              title: str.unionTerritoryGoods4cvii,
              key: "unionTerritoryServiceSupplied",
            },
            {
              title: str.anyOtherDuty4cviii,
              key: "anyOtherTaxSupplied",
            },
            {
              title: str.total4cix,
              key: "total4cix",
              total: true,
            },
          ],
        },
        {
          title: str.totalRevenue4d,
          input: true,
          total: true,
          key: "totalRevenue4d",
        },
        {
          title: str.closingStock5,
          input: true,
          key: "closingStockOfFinishedStocks",
        },
        {
          title: str.totalCredits6,
          input: true,
          key: "totalCredits6",
          total: true,
        },
        {
          title: str.openingStock7,
          input: true,
          key: "openingStocksOfFinishedGoods",
        },
        {
          title: str.purchases8,
          input: true,
          key: "purchasesNetOfRefundAndDuty",
        },
        {
          title: str.directExpenses9,
          input: true,
          total: true,
          key: "directExpenses9",
          subCategory: [
            {
              title: str.carriageInward9ai,
              key: "directExpensesCarriage",
            },
            {
              title: str.powerAndFuel9aii,
              key: "directExpensesPowerAndFuel",
            },
            {
              title: str.otherDirectExpenses9Aiii,
              key: "otherDirectExpenses2",
              input: false,
              addMore: true,
            },
            {
              title: str.total9Aiiia,
              key: "total9Aiiia",
              total: true,
            },
          ],
        },
        {
          title: str.dutiesAndTaxes10,
          subCategory: [
            {
              title: str.customDugy10ai,
              key: "customDuty",
            },
            {
              title: str.counterVeiling10aii,
              key: "counterWellingDuty",
            },
            {
              title: str.speicalAdditional10aiii,
              key: "specialAdditionalDuty",
            },
            {
              title: str.unionExcise10aiv,
              key: "unionExciseDuty",
            },
            {
              title: str.serviceTax10av,
              key: "serviceTaxPurchased",
            },
            {
              title: str.vatSales10avi,
              key: "vatSalesTaxPurchased",
            },
            {
              title: str.centralGoods10avii,
              key: "centralGoodsServicePurchased",
            },
            {
              title: str.stateGoods10aviii,
              key: "stateGoodsServicePurchased",
            },
            {
              title: str.integratedGoods10aix,
              key: "integratedGoodsServicePurchased",
            },
            {
              title: str.unionTerritory10ax,
              key: "unionTerritoryServicePurchased",
            },
            {
              title: str.anyOtherTax10axi,
              key: "anyOtherTaxPurchased",
            },
            {
              title: str.total10Axii,
              total: true,
              key: "total10Axii",
            },
          ],
        },
      ],
    },
    {
      title: str.costOfGoods11,
      input: true,
      total: true,
      key: "costOfGoods11",
    },
    {
      title: str.grossProfitFromBusiness12,
      input: true,
      total: true,
      key: "grossProfitFromBusiness12",
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.turnoverFromIntraday12a,
              input: true,
              key: "turnoverFromIntradayTrading",
            },
            {
              title: str.incomeFromIntraday12b,
              input: true,
              key: "incomeFromIntradayTrading",
            },
          ],
        },
      ],
    },
  ];

  const profitAndLossObj = [
    {
      title: str.grossProfit13,
      input: true,
      key: "grossProfit13",
      total: true,
    },
    {
      title: str.otherIncome14,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.rent14i,
              input: true,
              key: "otherIncomeRent",
            },
            {
              title: str.commission14ii,
              input: true,
              key: "otherIncomeCommission",
            },
            {
              title: str.devidendIncome14iii,
              input: true,
              key: "otherIncomeDivident",
            },
            {
              title: str.interestIncome14iv,
              input: true,
              key: "otherIncomeInterest",
            },
            {
              title: str.profitFixedAssets14v,
              input: true,
              key: "otherIncomeProfitFixedAsset",
            },
            {
              title: str.profitInvestment14vi,
              input: true,
              key: "otherIncomeSTT",
            },
            {
              title: str.profitOtherInvestment,
              input: true,
              key: "otherIncomeOtherInvestment",
            },
            {
              title: str.gainLossAccount14viii,
              input: true,
              key: "otherIncomeGain43AA",
            },
            {
              title: str.profitConversionInventory14ix,
              input: true,
              key: "otherIncomeProfit28",
            },
            {
              title: str.agriculturalIncome14x,
              input: true,
              key: "otherIncomeAgricultural",
            },
            {
              title: str.anyOtherIncome14xi,
              key: "otherIncomeAnyOther",
              addMore: true,
            },
            {
              title: str.totalOFOtherIncome14xii,
              key: "totalOFOtherIncome14xii",
              input: true,
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.totalOfCredits15,
      input: true,
      key: "totalOfCredits15",
      total: true,
    },
    {
      title: str.freightOUtward16,
      input: true,
      key: "freightOutward",
    },
    {
      title: str.consumptionOfStores17,
      input: true,
      key: "consumptionOfStoresAndSpare",
    },
    {
      title: str.powerAndFuel18,
      input: true,
      key: "powerAndFuelPAndL",
    },
    {
      title: str.rents19,
      input: true,
      key: "rentsPAndL",
    },
    {
      title: str.repairstoBuilding20,
      input: true,
      key: "repairsToBuilding",
    },
    {
      title: str.repairstoMachinery21,
      input: true,
      key: "repairsToMachinery",
    },
    {
      title: str.compensationToEmp22,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.salariesAndWages22i,
              input: true,
              key: "compSalariesAndWages",
            },
            {
              title: str.bonus22ii,
              input: true,
              key: "compBonus",
            },
            {
              title: str.reimbursementOfMed22iii,
              input: true,
              key: "compReimbursementOfMedical",
            },
            {
              title: str.leaveEncashment22iv,
              input: true,
              key: "compLeaveEncashment",
            },
            {
              title: str.leaveTravel22v,
              input: true,
              key: "compLeaveTravel",
            },
            {
              title: str.contriApprovedSuper22vi,
              input: true,
              key: "compSuperannuationFund",
            },
            {
              title: str.contriRecognisedPF22vii,
              input: true,
              key: "compRecognisedPF",
            },
            {
              title: str.contriRecognisedGratuity22viii,
              input: true,
              key: "compGratuityFund",
            },
            {
              title: str.contriAnyOther22ix,
              input: true,
              key: "compOtherFund",
            },
            {
              title: str.anyOtherBenefit22x,
              input: true,
              key: "anyOtherBenefitToEmp",
            },
            {
              title: str.totalCompensation22xi,
              input: true,
              key: "totalCompensation22xi",
              total: true,
            },
            {
              title: str.compensationIncluded22xi22xii,
              input: false,
            },
            {
              title: str.yesAmountPaid22xiii,
              input: true,
              key: "amountPaidToNonResidents",
            },
          ],
        },
      ],
    },
    {
      title: str.insurance23,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.medicalInsurance23i,
              input: true,
              key: "medicalInsurance",
            },
            {
              title: str.lifeInsurance23ii,
              input: true,
              key: "lifeInsurance",
            },
            {
              title: str.keyManInsurance23iii,
              input: true,
              key: "keyManInsurance",
            },
            {
              title: str.otherInsurance23iv,
              input: true,
              key: "OtherInsurance",
            },
            {
              title: str.totalExpenditure23v,
              input: true,
              key: "totalExpenditure23v",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.workmenAndStaff24,
      input: true,
      key: "workmenAndStaffWelfare",
    },
    {
      title: str.entertainment25,
      input: true,
      key: "entertainment",
    },
    {
      title: str.hospitality26,
      input: true,
      key: "hospitality",
    },
    {
      title: str.conference27,
      input: true,
      key: "conference",
    },
    {
      title: str.salesPromotion28,
      input: true,
      key: "salesPromotion",
    },
    {
      title: str.advertisement29,
      input: true,
      key: "advertisement",
    },
    {
      title: str.commission30,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.paidOutsideIndia30i,
              input: true,
              key: "commissionPaidOutsideIndia",
            },
            {
              title: str.toOthers30ii,
              input: true,
              key: "commissionOthers",
            },
            {
              title: str.total30iii,
              input: true,
              key: "total30iii",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.royalty31,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.paidOutsideIndia31i,
              input: true,
              key: "royaltyPainOutside",
            },
            {
              title: str.toOthers31ii,
              input: true,
              key: "royaltyOthers",
            },
            {
              title: str.total31iii,
              input: true,
              key: "total31iii",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.professionalConsultancy31,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.paidOutsideIndia32i,
              input: true,
              key: "profConsFeePaidOutsideIndia",
            },
            {
              title: str.toOthers32ii,
              input: true,
              key: "profConsOthers",
            },
            {
              title: str.total32iii,
              input: true,
              key: "total32iii",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.hotelBoarding33,
      input: true,
      key: "hotelBoarding",
    },
    {
      title: str.travelingExpenses34,
      input: true,
      key: "travelingExpenses",
    },
    {
      title: str.foreignTravelling35,
      input: true,
      key: "foreignTravelling",
    },
    {
      title: str.conveyanceExp36,
      input: true,
      key: "conveyanceExp",
    },
    {
      title: str.telephoneExpenses37,
      input: true,
      key: "telephoneExp",
    },
    {
      title: str.guestHouseExp38,
      input: true,
      key: "guestHouseExp",
    },
    {
      title: str.glubExpenses39,
      input: true,
      key: "clubExp",
    },
    {
      title: str.festivalCelebrationExp40,
      input: true,
      key: "festivalCel",
    },
    {
      title: str.scholarship41,
      input: true,
      key: "scholarship",
    },
    {
      title: str.gift42,
      input: true,
      key: "gift",
    },
    {
      title: str.donation43,
      input: true,
      key: "donation",
    },
    {
      title: str.ratesAndTaxes44,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.union44i,
              input: true,
              key: "ratesTaxesUnionExcise",
            },
            {
              title: str.serviceTax44ii,
              input: true,
              key: "ratesTaxesServiceTax",
            },
            {
              title: str.vatSales44iii,
              input: true,
              key: "ratesTaxesVatSalesTax",
            },
            {
              title: str.cess44iv,
              input: true,
              key: "ratesTaxesCess",
            },
            {
              title: str.centralGoods44v,
              input: true,
              key: "ratesTaxesCGST",
            },
            {
              title: str.stateGoods44vi,
              input: true,
              key: "ratesTaxesSGST",
            },
            {
              title: str.integratedGoods44vii,
              input: true,
              key: "ratesTaxesIGST",
            },
            {
              title: str.unionTerritoryGoods44viii,
              input: true,
              key: "ratesTaxesUTGST",
            },
            {
              title: str.anyOtherRate44ix,
              input: true,
              key: "ratesTaxesSTTAndCTT",
            },
            {
              title: str.totalRates44x,
              input: true,
              key: "totalRates44x",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.auditFee45,
      input: true,
      key: "auditFee",
    },
    {
      title: str.otherExpenses46,
      subCategory: [
        {
          title: "",
          key: "OtherExpenses",
          addMore: true,
        },
        {
          title: "",
          subCategory: [
            {
              title: str.total46i,
              key: "total46i",
              input: true,
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.badDebts47,
      button: true,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.badDebts47i,
              key: "badDebtsWithPan",
              addMore: true,
              type: "pan",
            },
            {
              title: str.others47ii,
              input: true,
              key: "others47ii",
              total: true,
            },
            {
              title: str.otherLess47iii,
              input: true,
              key: "otherAmountDownOneLakh",
            },
            {
              title: str.totalBadDebts47iv,
              input: true,
              key: "totalBadDebts47iv",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.provisionBadDoutful48,
      input: true,
      key: "provisionForBadAndDoubtful",
    },
    {
      title: str.otherProvision49,
      input: true,
      key: "otherProvisions",
    },
    {
      title: str.ProfitBeforeInterest50,
      input: true,
      key: "ProfitBeforeInterest50",
      total: true,
    },
    {
      title: str.interest51,
      subCategory: [
        {
          title: "",
          subCategory: [
            {
              title: str.paidOutsideIndia51i,
              input: true,
              key: "interestPainOutsideIndia",
            },
            {
              title: str.toOthers51ii,
              input: true,
              key: "interestToOthers",
            },
            {
              title: str.total51iii,
              input: true,
              key: "total51iii",
              total: true,
            },
          ],
        },
      ],
    },
    {
      title: str.depreciation52,
      input: true,
      key: "depreciationAndAmortisation",
    },
    {
      title: str.netProfit53,
      input: true,
      total: true,
      key: "netProfit53",
    },
    {
      title: str.provisionCurrentTax54,
      input: true,
      key: "provisionForCurrentTax",
    },
    {
      title: str.provisionDeferredTax55,
      input: true,
      key: "provisionForFeferredTax",
    },
    {
      title: str.profitAfterTax56,
      input: true,
      total: true,
      key: "profitAfterTax56",
    },
    {
      title: str.balanceBroughtForward57,
      input: true,
      key: "BalanceBroughtForwardFromPreviousYear",
    },
    {
      title: str.amountAvailable58,
      input: true,
      total: true,
      key: "amountAvailable58",
    },
    {
      title: str.transferredToReserves59,
      input: true,
      key: "TransferredToReservesAndSurplus",
    },
    {
      title: str.balanceCarriedtoBalanceSheet60,
      input: true,
      total: true,
      key: "balanceCarriedtoBalanceSheet60",
    },
  ];

  console.log("1545254", tradingAccount);

  return (
    <div className="container_box">
      <AccordianBox title={strings.balance_sheet.sources_of_funds}>
        <StyleFormBox>
          {sourceOfFundObj?.map((items) => {
            return (
              <>
                <div className="py-2">
                  <div className="flex_input my-2">
                    <h2>{items.title}</h2>
                    {items.input && (
                      <div style={{ paddingLeft: "15px" }}>
                        <CurrencyField
                          error={""}
                          value={
                            items.total
                              ? totals[items.key]
                              : sourcesFunds[items?.key]
                          }
                          handleChange={(e) => {
                            !items.total &&
                              setSourcesOfFund({
                                ...sourcesFunds,
                                [items.key]: e.target.value,
                              });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {items?.subCategory?.map((sub) => (
                    <div className="">
                      <div className="flex_input my-2">
                        {sub.title.length > 0 && <label>{sub.title}</label>}
                        {sub.input && (
                          <div style={{ paddingLeft: "15px" }}>
                            <CurrencyField
                              error={""}
                              value={
                                sub.total
                                  ? totals[sub.key]
                                  : sourcesFunds[sub?.key]
                              }
                              handleChange={(e) => {
                                !sub.total &&
                                  setSourcesOfFund({
                                    ...sourcesFunds,
                                    [sub.key]: e.target.value,
                                  });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {sub?.subCategory?.map((child) => (
                        <div className="flex_input my-2">
                          <div style={{ paddingLeft: "15px" }}>
                            <label>{child.title}</label>
                          </div>
                          <CurrencyField
                            error={""}
                            value={
                              child.total
                                ? totals[child.key]
                                : sourcesFunds[child?.key]
                            }
                            handleChange={(e) => {
                              !child.total &&
                                setSourcesOfFund({
                                  ...sourcesFunds,
                                  [child?.key]: e.target.value,
                                });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <hr />
              </>
            );
          })}
        </StyleFormBox>
      </AccordianBox>
      <AccordianBox title={strings.detail_balance_sheet.applicationsOfFunds}>
        <StyleFormBox>
          {applicationOfFundObj?.map((items) => {
            return (
              <>
                <div className="py-2">
                  <div className="flex_input my-2">
                    <h2>{items.title}</h2>
                    {items.input && (
                      <div style={{ paddingLeft: "15px" }}>
                        <CurrencyField
                          error={""}
                          value={
                            items.total
                              ? totals[items.key]
                              : applicationFunds[items?.key]
                          }
                          handleChange={(e) => {
                            !items.total &&
                              setApplicationFunds({
                                ...applicationFunds,
                                [items.key]: e.target.value,
                              });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {items?.subCategory?.map((sub) => (
                    <div className="">
                      <div className="flex_input my-2">
                        {sub.title.length > 0 && <label>{sub.title}</label>}
                        {sub.input && (
                          <div style={{ paddingLeft: "15px" }}>
                            <CurrencyField
                              error={""}
                              value={
                                sub.total
                                  ? totals[sub.key]
                                  : applicationFunds[sub?.key]
                              }
                              handleChange={(e) => {
                                !sub.total &&
                                  setApplicationFunds({
                                    ...applicationFunds,
                                    [sub.key]: e.target.value,
                                  });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {sub?.subCategory?.map((child) => (
                        <div className="flex_input my-2">
                          <div style={{ paddingLeft: "15px" }}>
                            <label>{child.title}</label>
                          </div>
                          <CurrencyField
                            error={""}
                            value={
                              child.total
                                ? totals[child.key]
                                : applicationFunds[child?.key]
                            }
                            handleChange={(e) => {
                              !child.total &&
                                setApplicationFunds({
                                  ...applicationFunds,
                                  [child?.key]: e.target.value,
                                });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <hr />
              </>
            );
          })}
        </StyleFormBox>
      </AccordianBox>
      <AccordianBox title={strings.detail_balance_sheet.ManufacturingAccount}>
        <StyleFormBox>
          {manufacturingAccountObj?.map((items) => {
            return (
              <>
                <div className="py-2">
                  <div className="flex_input my-2">
                    <h2>{items.title}</h2>
                    {items?.input && (
                      <div style={{ paddingLeft: "15px" }}>
                        <CurrencyField
                          error={""}
                          value={
                            items.total
                              ? totals[items.key]
                              : manufactureAccount[items?.key]
                          }
                          handleChange={(e) => {
                            !items.total &&
                              setManufactureAccount({
                                ...manufactureAccount,
                                [items.key]: e.target.value,
                              });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {items?.subCategory?.map((sub) => (
                    <div className="">
                      <div className="flex_input my-2">
                        {sub.title.length > 0 && <label>{sub.title}</label>}
                        {sub.input && (
                          <div style={{ paddingLeft: "15px" }}>
                            <CurrencyField
                              error={""}
                              value={
                                sub.total
                                  ? totals[sub.key]
                                  : manufactureAccount[sub?.key]
                              }
                              handleChange={(e) => {
                                !sub.total &&
                                  setManufactureAccount({
                                    ...manufactureAccount,
                                    [sub.key]: e.target.value,
                                  });
                              }}
                            />
                          </div>
                        )}
                      </div>
                      {sub?.subCategory?.map((child) => (
                        <div className="flex_input my-2">
                          <div style={{ paddingLeft: "15px" }}>
                            <label>{child.title}</label>
                          </div>
                          <CurrencyField
                            error={""}
                            value={
                              child.total
                                ? totals[child.key]
                                : manufactureAccount[child?.key]
                            }
                            handleChange={(e) => {
                              !child.total &&
                                setManufactureAccount({
                                  ...manufactureAccount,
                                  [child?.key]: e.target.value,
                                });
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <hr />
              </>
            );
          })}
        </StyleFormBox>
      </AccordianBox>
      <AccordianBox title={strings.detail_balance_sheet.tradingAccount}>
        <StyleFormBox>
          {tradingAccountObj?.map((items) => {
            return (
              <>
                <div className="py-2">
                  <div className="flex_input my-2">
                    <h2>{items.title}</h2>
                    {items.input && (
                      <div style={{ paddingLeft: "15px" }}>
                        <CurrencyField
                          error={""}
                          value={
                            items.total
                              ? totals[items.key]
                              : tradingAccount[items?.key]
                          }
                          handleChange={(e) => {
                            !items.total &&
                              setTradingAccount({
                                ...tradingAccount,
                                [items.key]: e.target.value,
                              });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {items?.subCategory?.map((sub) =>
                    sub.addMore ? (
                      <div style={{ padding: "0 15px 0 15px" }}>
                        <label>{sub.title}</label>
                        {tradingAccount[sub["key"]].map((info, ix) => (
                          <div className="flex gap-5 items-center	">
                            {ix + 1}
                            <Input
                              inputType="default"
                              value={info.name}
                              onChange={(e) =>
                                setTradingAccount((pre) => {
                                  const updated = [...pre[sub.key]];
                                  updated[ix] = {
                                    ...updated[ix],
                                    name: e.target.value,
                                  };
                                  return { ...pre, [sub.key]: updated };
                                })
                              }
                            />

                            <CurrencyField
                              error={""}
                              value={info.amount}
                              handleChange={(e) =>
                                setTradingAccount((pre) => {
                                  const updated = [...pre[sub.key]];
                                  updated[ix] = {
                                    ...updated[ix],
                                    amount: e.target.value,
                                  };
                                  return { ...pre, [sub.key]: updated };
                                })
                              }
                            />

                            <button
                              className="delete_button mb-2"
                              onClick={() => {
                                ix != 0 &&
                                  setTradingAccount((prev) => ({
                                    ...prev,
                                    [sub["key"]]: prev[sub["key"]].filter(
                                      (_, index) => index !== ix
                                    ),
                                  }));
                              }}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        ))}

                        <div className="flex justify-end mt-5">
                          <button
                            className="add_item_button"
                            onClick={() =>
                              setTradingAccount((previous) => ({
                                ...previous,
                                [sub["key"]]: [
                                  ...(previous[sub["key"]] || []),
                                  { name: "", amount: "" },
                                ],
                              }))
                            }
                          >
                            {strings.button.add_more_item}
                          </button>

                          <AddActionButton
                            title={strings.button.add}
                            handleChange={(e) => {
                              console.log(e);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="flex_input my-2">
                          {sub.title.length > 0 && <label>{sub.title}</label>}
                          {sub.input && (
                            <div style={{ paddingLeft: "15px" }}>
                              <CurrencyField
                                error={""}
                                value={
                                  sub.total
                                    ? totals[sub.key]
                                    : tradingAccount[sub?.key]
                                }
                                handleChange={(e) => {
                                  !sub.total &&
                                    setTradingAccount({
                                      ...tradingAccount,
                                      [sub.key]: e.target.value,
                                    });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {sub?.subCategory?.map((child) =>
                          child.addMore ? (
                            <div style={{ padding: "0 15px 0 15px" }}>
                             <label>{child.title}</label>
                              {tradingAccount[child["key"]].map((info, ix) => (
                                <div className="flex gap-5 items-center	">
                                  {ix + 1}
                                  <Input
                                    inputType="default"
                                    value={info.name}
                                    onChange={(e) =>
                                      setTradingAccount((pre) => {
                                        const updated = [...pre[child.key]];
                                        updated[ix] = {
                                          ...updated[ix],
                                          name: e.target.value,
                                        };
                                        return { ...pre, [child.key]: updated };
                                      })
                                    }
                                  />

                                  <CurrencyField
                                    error={""}
                                    value={info.amount}
                                    handleChange={(e) =>
                                      setTradingAccount((pre) => {
                                        const updated = [...pre[child.key]];
                                        updated[ix] = {
                                          ...updated[ix],
                                          amount: e.target.value,
                                        };
                                        return { ...pre, [child.key]: updated };
                                      })
                                    }
                                  />

                                  <button
                                    className="delete_button mb-2"
                                    onClick={() => {
                                      ix != 0 &&
                                        setTradingAccount((prev) => ({
                                          ...prev,
                                          [child["key"]]: prev[
                                            child["key"]
                                          ].filter((_, index) => index !== ix),
                                        }));
                                    }}
                                  >
                                    <RiDeleteBin6Fill />
                                  </button>
                                </div>
                              ))}

                              <div className="flex justify-end mt-5">
                                <button
                                  className="add_item_button"
                                  onClick={() =>
                                    setTradingAccount((previous) => ({
                                      ...previous,
                                      [child["key"]]: [
                                        ...(previous[child["key"]] || []),
                                        { name: "", amount: "" },
                                      ],
                                    }))
                                  }
                                >
                                  {strings.button.add_more_item}
                                </button>

                                <AddActionButton
                                  title={strings.button.add}
                                  handleChange={(e) => {
                                    console.log(e);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="flex_input my-2">
                              <div style={{ paddingLeft: "15px" }}>
                               <label>{child.title}</label>
                              </div>
                              {child.input != false && (
                                <CurrencyField
                                  error={""}
                                  value={
                                    child.total
                                      ? totals[child.key]
                                      : tradingAccount[child?.key]
                                  }
                                  handleChange={(e) => {
                                    !child.total &&
                                      setTradingAccount({
                                        ...tradingAccount,
                                        [child?.key]: e.target.value,
                                      });
                                  }}
                                />
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
                <hr />
              </>
            );
          })}
        </StyleFormBox>
      </AccordianBox>
      <AccordianBox title={strings.detail_balance_sheet.profitAndLossAccount}>
        <StyleFormBox>
          {profitAndLossObj?.map((items) => {
            return (
              <>
                <div className="py-2">
                  <div className="flex_input my-2 ">
                    <h2>{items.title}</h2>
                    {items?.input && (
                      <div style={{ paddingLeft: "15px" }}>
                        <CurrencyField
                          error={""}
                          value={
                            items.total
                              ? totals[items.key]
                              : profitAndLoss[items?.key]
                          }
                          handleChange={(e) => {
                            !items.total &&
                              setProfitAndLoss({
                                ...profitAndLoss,
                                [items.key]: e.target.value,
                              });
                          }}
                        />
                      </div>
                    )}
                    {items?.button && (
                      <div className="flex justify-end">
                        <AddActionButton
                          title={strings.button.addBadDebts}
                          handleChange={() => {
                            setBadDebtPop(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {items?.subCategory?.map((sub) =>
                    sub.addMore ? (
                      <div style={{ padding: "0 15px 0 15px" }}>
                        <label>{sub.title}</label>
                        {profitAndLoss[sub["key"]].map((info, ix) => (
                          <div className="flex gap-5 items-center	">
                            {ix + 1}
                            <Input
                              inputType="default"
                              value={info.name}
                              onChange={(e) =>
                                setProfitAndLoss((pre) => {
                                  const updated = [...pre[sub.key]];
                                  updated[ix] = {
                                    ...updated[ix],
                                    name: e.target.value,
                                  };
                                  return { ...pre, [sub.key]: updated };
                                })
                              }
                            />

                            <CurrencyField
                              error={""}
                              value={info.amount}
                              handleChange={(e) =>
                                setProfitAndLoss((pre) => {
                                  const updated = [...pre[sub.key]];
                                  updated[ix] = {
                                    ...updated[ix],
                                    amount: e.target.value,
                                  };
                                  return { ...pre, [sub.key]: updated };
                                })
                              }
                            />

                            <button
                              className="delete_button mb-2"
                              onClick={() => {
                                ix != 0 &&
                                  setProfitAndLoss((prev) => ({
                                    ...prev,
                                    [sub["key"]]: prev[sub["key"]].filter(
                                      (_, index) => index !== ix
                                    ),
                                  }));
                              }}
                            >
                              <RiDeleteBin6Fill />
                            </button>
                          </div>
                        ))}

                        <div className="flex justify-end mt-5">
                          <button
                            className="add_item_button"
                            onClick={() =>
                              setProfitAndLoss((previous) => ({
                                ...previous,
                                [sub["key"]]: [
                                  ...(previous[sub["key"]] || []),
                                  { name: "", amount: "" },
                                ],
                              }))
                            }
                          >
                            {strings.button.add_more_item}
                          </button>

                          <AddActionButton
                            title={strings.button.add}
                            handleChange={(e) => {
                              console.log(e);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="flex_input my-2">
                          {sub.title.length > 0 && <label>{sub.title}</label>}
                          {sub.input && (
                            <div style={{ paddingLeft: "15px" }}>
                              <CurrencyField
                                error={""}
                                value={
                                  sub.total
                                    ? totals[sub.key]
                                    : profitAndLoss[sub?.key]
                                }
                                handleChange={(e) => {
                                  !sub.total &&
                                    setProfitAndLoss({
                                      ...profitAndLoss,
                                      [sub.key]: e.target.value,
                                    });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {sub?.subCategory?.map((child) =>
                          child.addMore ? (
                            <div style={{ padding: "0 15px 0 15px" }}>
                             <label>{child.title}</label>
                              {profitAndLoss[child["key"]].map((info, ix) => (
                                <div className="flex gap-5 items-center	">
                                  {ix + 1}
                                  <Input
                                    inputType={
                                      child.type === "pan" ? "pan" : "default"
                                    }
                                    value={info.name}
                                    onChange={(e) =>
                                      setProfitAndLoss((pre) => {
                                        const updated = [...pre[child.key]];
                                        updated[ix] = {
                                          ...updated[ix],
                                          name: e.target.value,
                                        };
                                        return { ...pre, [child.key]: updated };
                                      })
                                    }
                                  />
                                  <CurrencyField
                                    error={""}
                                    value={info.amount}
                                    handleChange={(e) =>
                                      setProfitAndLoss((pre) => {
                                        const updated = [...pre[child.key]];
                                        updated[ix] = {
                                          ...updated[ix],
                                          amount: e.target.value,
                                        };
                                        return { ...pre, [child.key]: updated };
                                      })
                                    }
                                  />

                                  <button
                                    style={{ marginLeft: "0px" }}
                                    className="delete_button mb-2"
                                    onClick={() => {
                                      ix != 0 &&
                                        setProfitAndLoss((prev) => ({
                                          ...prev,
                                          [child["key"]]: prev[
                                            child["key"]
                                          ].filter((_, index) => index !== ix),
                                        }));
                                    }}
                                  >
                                    <RiDeleteBin6Fill />
                                  </button>
                                </div>
                              ))}

                              <div className="flex justify-end mt-5">
                                <button
                                  className="add_item_button"
                                  onClick={() =>
                                    setProfitAndLoss((previous) => ({
                                      ...previous,
                                      [child["key"]]: [
                                        ...(previous[child["key"]] || []),
                                        { name: "", amount: "" },
                                      ],
                                    }))
                                  }
                                >
                                  {strings.button.add_more_item}
                                </button>

                                <AddActionButton
                                  title={strings.button.add}
                                  handleChange={(e) => {
                                    console.log(e);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="flex_input my-2">
                              <div style={{ paddingLeft: "15px" }}>
                               <label>{child.title}</label>
                              </div>
                              {child.input != false && (
                                <CurrencyField
                                  error={""}
                                  value={
                                    child.total
                                      ? totals[child.key]
                                      : profitAndLoss[child?.key]
                                  }
                                  handleChange={(e) => {
                                    !child.total &&
                                      setProfitAndLoss({
                                        ...profitAndLoss,
                                        [child?.key]: e.target.value,
                                      });
                                  }}
                                />
                              )}
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </div>
                <hr />
              </>
            );
          })}
        </StyleFormBox>
      </AccordianBox>

      <FormDialogBox
        open={badDebtPop}
        handleClose={() => {
          setBadDebtPop(false);
        }}
        formTitle="Bad Debts"
      >
        <BadDebts />
      </FormDialogBox>
    </div>
  );
}
