import React, { useEffect, useState } from "react";
import SelectOption from "../../../../../styledComponent/Inputs/SelectOption";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import TextArea from "../../../../../styledComponent/Inputs/TextArea";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { removeEmptyKeys } from "../../../../../utils/helpers";
import Select from "../../../../../styledComponent/Inputs/Select";
import { editAdditionToPAndL, saveAdditionToPAndL } from "../../../../../redux/itr/action";
import { toast } from "react-toastify";

export default function AddToProfitLoss({ section, data, handleCallback }) {
  const [formData, setFormData] = useState({});

  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editAdditionToPAndL : saveAdditionToPAndL;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    setFormData({
      section: data?.section || "",
      subSection: data?.subSection || "",
      description: data?.description || "",
      amount: data?.amount || "",
      itrId: ItrData?.id,
      id: data?.id || "",
      type: data?.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);

  return (
    <div>
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("setFormData ===>", values);
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <div>
                <label>{strings.income.section}</label>
                <Select
                  name={"section"}
                  error={errors.section}
                  value={values?.section}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {section?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <label>{strings.income.sub_section}</label>
                <Select
                  name={"subSection"}
                  error={errors.subSection}
                  value={values?.subSection}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    {strings.label.choose_option}
                  </option>
                  {section
                    ?.filter((i) => i.id == values?.section)[0]
                    ?.subSection?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </Select>
              </div>
              <div>
                <label>
                  {strings.label.description +
                    strings.label.this_field_is_optional}
                </label>
                <Input
                  type={"number"}
                  inputType={"message"}
                  name={"description"}
                  error={errors.description}
                  value={values.description}
                  onChange={handleChange}
                />
              </div>
              <p className="opacity-80 font-extralight">
                {strings.message.specify_nature_of_expense_incurred}
              </p>
              <div>
                <label>{strings.income.amount}</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"amount"}
                  error={errors.amount}
                  value={values.amount}
                  onChange={handleChange}
                />
              </div>
            </StyleFormBox>
            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
