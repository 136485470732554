import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../../styledComponent/Inputs/Select";
import Input from "../../../../styledComponent/Inputs/Input";
import { Formik } from "formik";
import { saveBusinessDetails44AEAction, saveGoodsCarriagesAction } from "../../../../redux/itr/action";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";

export default function Section44AE({ businessCode, data, ownershipStatus }) {
  const [businessBox, setBusinessBox] = useState(false);
  const [businessFormData, setBusinessFormData] = useState({});
  const [goodsCarriageFormData, setGoodsCarriageFormData] = useState({});
  const [formData, setFormData] = useState({});
  const [goodsCarriageBox, setGoodsCarriageBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  const submitBusinessDetails = async (data) => {
    dispatch(
      saveBusinessDetails44AEAction(data, (e) => {
        // if (e.status == 200) {
        //   setOptionData({
        //     ...optionData,
        //     businessProfessionIncome: {
        //       ...optionData.businessProfessionIncome,
        //       professionalIncome: e.data,
        //     },
        //   });
        //   setProfessionalIncomeForm(false);
        //   setLoading(false);
        // } else {
        //   toast.error(e.message);
        //   setLoading(false);
        // }
      })
    );
  };
  
  const submitGoodsCarriages = async (data) => {
    console.log('data =====>',data)
    dispatch(
      saveGoodsCarriagesAction(data, (e) => {
        // if (e.status == 200) {
        //   setOptionData({
        //     ...optionData,
        //     businessProfessionIncome: {
        //       ...optionData.businessProfessionIncome,
        //       professionalIncome: e.data,
        //     },
        //   });
        //   setProfessionalIncomeForm(false);
        //   setLoading(false);
        // } else {
        //   toast.error(e.message);
        //   setLoading(false);
        // }
      })
    );
  };

  const delBusinessDetailsApi = async (id, businessId) => {
    const data = {
      id: id,
      professionalBusinessId: businessId,
    };
    dispatch(
      saveBusinessDetails44AEAction(data, (e) => {
        // if (e.status == 200) {
        //   setOptionData({
        //     ...optionData,
        //     businessProfessionIncome: {
        //       ...optionData.businessProfessionIncome,
        //       professionalIncome: e.data,
        //     },
        //   });
        //   setProfessionalIncomeForm(false);
        //   setLoading(false);
        // } else {
        //   toast.error(e.message);
        //   setLoading(false);
        // }
      })
    );
  };

  useEffect(() => {
    setBusinessFormData({
      itrId: ItrData.id,
      businessCode: "",
      nameOfBusiness: "",
      description: "",
    });

    setGoodsCarriageFormData({
      numberOfMonths: '',
      // numberOfTons: 40,
      incomePerVehiclePerMonth: '',
      registrationNumber: '',
      ownershipStatus: '',
      itrId: ItrData.id,
    });
  }, []);

  useEffect(() => {
    setFormData(data);
  }, [data]);
  return (
    <>
      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.income.business_details}
          </h4>

          <button
            className="add_button"
            onClick={() => {
              setBusinessBox(!businessBox);
            }}
          >
            {businessBox ? strings.button.close : strings.button.add}
          </button>
        </div>

        {formData?.businessDetails?.map((i, ix) => (
          <StyleFormBox style={{ padding: "10px" }}>
            <h4>{i.nameOfBusiness}</h4>
            <div className="flex items-center justify-between">
              <p>{i.description}</p>
              <div className="action_buttons">
                <button className="edit_btn">
                  <FaRegEdit />
                </button>
                <button
                  className="delete_btn"
                  onClick={() => delBusinessDetailsApi()}
                >
                  <RiDeleteBin6Fill />
                </button>
              </div>
            </div>
          </StyleFormBox>
        ))}
        {businessBox == true ? (
          <Formik
            initialValues={businessFormData}
            enableReinitialize={true}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              submitBusinessDetails(values);
            }}
          >
            {({ values, errors, handleChange, handleSubmit, setValues }) => (
              <div>
                <StyleFormBox style={{ marginTop: "30px" }}>
                  <h4>{strings.income.add_business_details}</h4>
                  <hr />
                  <div className="flex_input my-2">
                    <label>{strings.income.business_code}</label>
                    <Select
                      name={`businessCode`}
                      value={values?.businessCode}
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        {strings.label.choose_option}
                      </option>
                      {businessCode?.map((i, ix) => (
                        <option value={i.id} key={ix}>
                          {i.name}
                        </option>
                      ))}
                    </Select>
                  </div>

                  <div className="flex_input my-2">
                    <label>{strings.income.name_of_business}</label>
                    <Input
                      name={`nameOfBusiness`}
                      value={values?.nameOfBusiness}
                      error={errors?.nameOfBusiness}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex_input mt-4">
                    <label>{strings.label.description}</label>
                    <Input
                      name={`description`}
                      value={values?.description}
                      error={errors?.description}
                      onChange={handleChange}
                    />
                  </div>

                  <button className="primary_button" onClick={handleSubmit}>
                    {strings.button.save_and_close}
                  </button>
                </StyleFormBox>
              </div>
            )}
          </Formik>
        ) : (
          ""
        )}
      </StyleFormBox>
      <StyleFormBox>
        <div className="flex-1 flex items-center justify-between">
          <h4 style={{ color: theme.primary_txt }}>
            {strings.income.goods_carriage_details}
          </h4>

          <button
            className="add_button"
            onClick={() => {
              setGoodsCarriageBox(true);
            }}
          >
            {strings.button.add}
          </button>
        </div>
        {goodsCarriageBox == true ? (
          <StyleFormBox style={{ marginTop: "30px" }}>
            <Formik
              initialValues={goodsCarriageFormData}
              enableReinitialize={true}
              // validationSchema={validationSchema}
              onSubmit={(values) => {
                submitGoodsCarriages(values);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, setValues }) => (
                <div>
                  <StyleFormBox style={{ marginTop: "30px" }}>
                    <h4>{strings.income.add_goods_carriage_details}</h4>
                    <hr />

                    <div className="flex_input my-2">
                      <label>{strings.income.number_of_months}</label>
                      <Select
                        name={`numberOfMonths`}
                        value={values?.numberOfMonths}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          {strings.label.choose_option}
                        </option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                          (i, ix) => (
                            <option value={i} key={ix}>
                              {i}
                            </option>
                          )
                        )}
                      </Select>
                    </div>

                    <div className="flex_input my-2">
                      <label>
                        {strings.income.income_per_vehicle_per_month}
                      </label>
                      <Input
                        name={`incomePerVehiclePerMonth`}
                        value={values?.incomePerVehiclePerMonth}
                        error={errors?.incomePerVehiclePerMonth}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input my-2">
                      <label>{strings.income.registration_number}</label>
                      <Input
                        name={`registrationNumber`}
                        value={values?.registrationNumber}
                        error={errors?.registrationNumber}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex_input my-2">
                      <label>{strings.income.ownership_status}</label>
                      <Select
                        name={`ownershipStatus`}
                        value={values?.ownershipStatus}
                      >
                        <option value="" disabled>
                          {strings.label.choose_option}
                        </option>
                        {ownershipStatus?.map((i, ix) => (
                          <option value={i.id} key={ix}>
                            {i.name}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <button className="primary_button" onClick={handleSubmit}>
                      {strings.button.save_and_close}
                    </button>
                  </StyleFormBox>
                </div>
              )}
            </Formik>
          </StyleFormBox>
        ) : (
          ""
        )}
      </StyleFormBox>
    </>
  );
}
