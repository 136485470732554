import React from 'react'
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export default function Phone(props) {
    const { countryCode, error, ...prop } = props;
    const { theme } = useSelector((state) => state.platformSetting);

  return (
    <Root theme={theme}>
     <div className="input_field">
          <PhoneInput country={'in'} containerClass={"phone_field"} {...prop} />
        </div>
      <p className="error">{error}</p>

    </Root>
  )
}
const Root = styled.section`
  .input_field {
    position: relative;
    padding-bottom: 10px;
    input {
      margin: 14px 0px;
      width: 100%;
      height: 50px;
      padding: 12px;
      border-radius: 12px;
      border: 1px solid ${(p) => p.theme.border_clr};
      color: ${(p) => p.theme.primary_txt};
      background-color: transparent;
      &::placeholder {
        color: ${(p) => p.theme.primary_txt};
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
  .flag-dropdown {
    background-color: ${(p) => p.theme.secandary_bg};
    color: ${(p) => p.theme.primary_txt};
    :hover {
      background-color: ${(p) => p.theme.secandary_bg};
    }
  }
  .react-tel-input {
    .country-list .country:hover {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    .country-list {
      background-color: ${(p) => p.theme.secandary_bg};
    }
    .country-list .country.highlight {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    .flag-dropdown.open .selected-flag {
      background-color: ${(p) => p.theme.tertiary_bg};
    }
    input {
      padding-left: 50px;
    }
    .flag-dropdown,
    .selected-flag,
    .selected-flag.open {
      border: 1px solid ${(p) => p.theme.border_clr};
      border-radius: 10px 0 0 10px;
    }
  }`