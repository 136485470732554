import styled from "styled-components";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaRegSquareCaretRight } from "react-icons/fa6";
import { FaRupeeSign } from "react-icons/fa";
import { useSelector } from "react-redux";
export default function IncomeTaxCalculator() {
  const {theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <div className="header-section">
        <div className="header-content flex justify-center items-center">
          <div className="text-line">
            <h5>Filing for AY 2024-25 is coming soon</h5>
          </div>
          <div className="pre-button">
            <button type="button">Pre-Book Now</button>
            <span className="off_coupon">Grab 25% OFF</span>
          </div>
        </div>
      </div>
      <div className="tds-Calculator-section">
        <div className="container_box">
          <div className="tds-main">
            <div className="tds-left">
              <div className="tds-left-heading">
                <h2> Income From House Property Calculator</h2>
                <p>
                  All types of properties are taxed under the head 'income from
                  house property' in the income tax return. Use the Income from
                  House property calculator to figure out how taxation and
                  benefits.
                </p>
              </div>
              <div className="second-field">
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      PAN of Recipient is Available:
                    </label>
                  </div>
                  <select name="cars" id="cars">
                    <option value="volvo">Yes</option>
                    <option value="saab">No</option>
                  </select>
                </div>
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      Amount of Payment :
                    </label>
                  </div>
                  <select name="cars" id="cars">
                    <option value="volvo">Yes</option>
                    <option value="saab">No</option>
                  </select>
                </div>
              </div>
              <div className="hddd">
                <h4>Income from Self-occupied Property</h4>
              </div>
              <div className="second-field">
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      Interest Paid/Payable on Housing Loan
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      Total interest for Pre-Construction Period
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
              </div>
              <div className="result">
                <h5>Income from self-occupied house property</h5>
                <h6>0</h6>
                <small>
                  This loss will be set-off from your other income and will
                  reduce the tax liability
                </small>
              </div>

              <div className="hddd mt-4">
                <h4>Income from Let-out Property</h4>
              </div>
              <div className="second-field">
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      1. Annual Letable Value
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      2. Municipal Taxes Paid During the Year
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
              </div>
              <div className="second-field">
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      3. Less:Unrealized Rent
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      4. Net Annual Value (1-(2+3))
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
              </div>
              <div className="hddd mt-4">
                <h4>Less: Deductions from Net Annual Value</h4>
              </div>
              <div className="second-field">
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      i. Standard Deduction @ 30% of Net Annual Value
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
                <div className="second-main">
                  <div className="abc">
                    <label className="abc" for="css">
                      ii. Interest on Housing Loan
                    </label>
                  </div>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder="Amount of Payment"
                  ></input>
                </div>
              </div>
            </div>
            <div className="tds-right">
              <div className="right-top">
                <img src="Images/hpi-calculator.png"></img>
              </div>
              <div className="cta">
                <h2>Easy and Accurate ITR Filing on Tax2win</h2>
                <p>Best income tax efiling website in india</p>
                <a
                  href="https://tax2win.in/efile-income-tax-return"
                  className="blue-btn"
                >
                  File ITR Now
                </a>
              </div>
              <article className="relevant_bx trending_guides scroller_bx">
                <h3>Trending Guides : </h3>
                <div className="news-container">
                  <ul>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/income-tax-slabs"
                          className="links"
                          target="_blank"
                        >
                          Income Tax Slab &amp; Tax Rates for FY 2020-21(AY
                          2021-22) &amp; FY 2019-20 (AY 2020-21)
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/section-234f"
                          className="links"
                          target="_blank"
                        >
                          Section 234F: Penalty for Late Filing of Income Tax
                          Return
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/deductions"
                          className="links"
                          target="_blank"
                        >
                          Deductions under Chapter VI A of Income Tax Act for FY
                          2019-20(AY 2020-21)
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        <a
                          href="https://tax2win.in/guide/income-tax-return"
                          className="links"
                          target="_blank"
                        >
                          Income Tax Return (ITR) Filing 2019: How to File ITR
                          Online India
                        </a>
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/form16"
                          className="links"
                          target="_blank"
                        >
                          Form 16: What is Form 16? Form 16 Meaning, Format
                          &amp; How to Upload
                        </a>{" "}
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/rent-receipt"
                          className="links"
                          target="_blank"
                        >
                          Complete Guide on Rent Slips/Receipts and Claim HRA
                          Tax
                        </a>{" "}
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/income-tax-notice-us-143-1"
                          className="links"
                          target="_blank"
                        >
                          Notice u/s 143-(1) Intimation from Income Tax
                          Department
                        </a>{" "}
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/income-tax-notice-us-139-9"
                          className="links"
                          target="_blank"
                        >
                          Notice for Defective Return u/s 139(9): How to
                          Respond?
                        </a>{" "}
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/income-tax-form-60"
                          className="links"
                          target="_blank"
                        >
                          Income Tax Form 60: For those who don't have Pan Card
                          in India
                        </a>{" "}
                      </div>
                    </li>
                    <li>
                      <div>
                        {" "}
                        <a
                          href="https://tax2win.in/guide/tax-saving-benefits-for-home-buyers"
                          className="links"
                          target="_blank"
                        >
                          Tax Saving Benefits for Home Buyers with &amp; without
                          Home Loan
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>
          <div className="min-section">
            <div className="content">
              <p>
                House property could be your home or any other building,
                including factory buildings, offices, shops, godowns and other
                commercial premises, or any land attached to the building like a
                parking lot, garden, garage etc. The Income-tax Act does not
                differentiate between commercial and residential property. Every
                type of property is taxed under the head ‘Income from House
                Property’ in the income tax return(ITR). You may compute such
                house property income using the calculator here Income From
                House Property Calculator. Follow the below steps to understand
                how the calculator is used in computing your house property
                income under various circumstances:
              </p>
              <h4 className="my-4">A) Income from Self-occupied Property</h4>
              <p className="my-3">
                Where the property is occupied by you for your own residence or
                remains unoccupied throughout the year, then the income of 2
                such house properties can be taxed as ‘Income from Self-occupied
                Property’ where the taxable amount shall be nil. However, you
                may claim interest on a home loan.
              </p>
              <p>
                Select the assessment year for which you wish to file your ITR
                and enter the interest amount on the home loan paid during the
                previous year. You will now see your house property income
                generated on the screen. (calculator allows an interest amount
                of more than INR 2,00,000 for self-occupied and the answer
                doesn’t appear)
              </p>
              <h4 className="my-4">B) Income from Let-out Property</h4>
              <p className="my-3">
                Select the assessment year for which you wish to file your ITR
              </p>
              <p>
                To determine the annual value click on ‘calculate’ and enter the
                corresponding amounts in each box. (unrealized rent is included
                twice in the calculator-once in annual lettable value and once
                on the face of the calculator) Note:
              </p>
              <ul>
                <li>Enter ‘0’ if any of the fields do not apply to you.</li>
                <li>
                  Municipal valuation of property’ is the value determined by
                  the municipal authorities for levying municipal taxes on house
                  property.
                </li>
                <li>
                  Market rent means rent that a similar property in the same
                  locality would fetch.
                </li>
                <li>Standard rent is a rent fixed by the Rent Control Act.</li>
                <li>Other fields may not apply under the given case</li>
                <p>Enter the municipal taxes paid by you during the year</p>
                <p>
                  Enter the interest amount on the home loan paid during the
                  previous year. By doing that, the calculator shall generate
                  your income from the let-out house property and your total
                  income from the house property.
                </p>
              </ul>
              <h4 className="my-4">
                How to calculate income from house property?
              </h4>
              <p className="my-3">
                Calculating income from house property in India is an essential
                aspect of determining your taxable income. Here's how you can
                calculate income from house property:
              </p>
              <p className="my-3">Step 1: Determine the Type of Property</p>
              <p className="my-3">Step 2: Calculate Gross Annual Value (GAV)</p>
              <p className="my-3">
                1.For a self-occupied property: If you live in the property, the
                GAV is typically considered as zero for tax purposes.
              </p>
              <p className="my-3">
                2.For a let-out property: Calculate the fair rental value or the
                actual rent received, whichever is higher.
              </p>
              <p className="my-3">
                For a deemed to be let-out property: If you have more than one
                house property and all are self-occupied, one is considered as
                deemed to be let out, and GAV is calculated as if it were
                rented.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  .header-section {
    width: 100%;
    background: linear-gradient(90.09deg, ${(p) => p.theme.primary_bg} 1.01%, #fc466b 99.06%);
    padding: 20px 0;
    .header-content {
      width: 100%;
      .text-line {
        width: 30%;
        h5 {
          color: #fff;
          text-align: center;
        }
        @media (max-width: 576px) {
          margin-bottom: 10px;
          width: 100%;
        }
      }
      .pre-button {
        button {
          width: 150px;
          background: ${(p) => p.theme.secandary_bg};
          padding: 12px;
          border-radius: 5px;
        }
        .off_coupon {
          border: dashed 2px #fff;
          font-weight: 600;
          background: rgba(255, 255, 255, 0.1);
          margin-left: 1rem;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          color: #fff;
        }
      }
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }
  }
  .tds-Calculator-section {
    width: 100%;
    padding: 50px 0;
    .tds-main {
      width: 100%;
      display: flex;
      gap: 80px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      /* background: #fff; */
      background: ${(p) => p.theme.secandary_bg};
      padding: 20px;
      border-radius: 6px;
      .tds-left {
        width: 60%;
        .tds-left-heading {
          h2 {
            font-size: 32px;
            font-weight: 700;
          }
          p {
            font-size: 18px;
            font-weight: 400;
          }
        }
        @media (max-width: 991px) {
          width: 100%;
          text-align: center;
          margin-bottom: 20px;
        }
        .second-field {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          margin: 10px 0;
          .second-main {
            width: 50%;
            .abc {
              margin: 10px 0;
              font-size: 12px;
            }
            select {
              margin-bottom: 10px;
              padding: 15px;
              height: auto;
              width: 100%;
              border-radius: 3px;
              background-clip: padding-box;
              border-color: #d9dbde;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              transition: 0.5s;
              font-size: 12px;
            }
            input {
              margin-bottom: 10px;
              padding: 15px;
              height: auto;
              width: 100%;
              border-radius: 3px;
              background-clip: padding-box;
              border-color: #d9dbde;
              box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
              transition: 0.5s;
              font-size: 12px;
            }
          }
        }
        .result {
          background: #effbee;
          border: solid 1px #c7edc4;
          padding: 2rem;
          border-radius: 6px;
          text-align: center;
          color: #000;
          h5 {
            font-weight: 700;
          }
          h6 {
            font-weight: 400;
          }
        }
      }
      .tds-right {
        width: 40%;

        .right-top {
          img {
            width: 100%;
          }
        }
        .cta {
          padding: 3rem 2rem;
          background: ${(p) => p.theme.primary_bg};
          border-radius: 6px;
          text-align: center;
          color: #fff;
          h2 {
            font-size: 20px;
            line-height: 27px;
          }
          p {
            font-size: 14px;
          }
          a {
            display: inline-block;
            background: #0e2544;
            border-radius: 4px;
            color: #fff;
            padding: 12px 60px;
            text-decoration: none;
            font-size: 16px;
            margin-top: 2rem;
          }
        }
        .relevant_bx {
          /* background: #fff; */
          box-shadow: 0 0 10px 0 #ccc;
          margin-top: 1rem;
          position: relative;
          border-bottom: 5px solid ${(p) => p.theme.primary_bg};
          border-radius: 10px 10px 10px 10px;
          margin-bottom: 10px;
          padding: 15px;
          padding-top: 5px;
          .news-container {
            height: 230px;
            overflow-x: hidden;
            ul {
              li {
                div {
                  font-weight: 500;
                  border: 1px solid #f5f5f5;
                  padding-left: 40px;
                  padding-right: 10px;
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
    }
    .min-section {
      width: 100%;
      background: ${(p) => p.theme.secandary_bg};
      margin: 50px 0;
      padding: 40px 20px;
      .content {
        width: 100%;
        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 23px;
        }
      }
    }
  }
`;
