import React, { useState } from "react";
import AccordianTab from "../../styledComponent/Form/AccordianTab";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { HiDotsVertical } from "react-icons/hi";
import styled from "styled-components";
import { addOpacity } from "../../utils/helpers";
import UploadDocuments from "../DialogBox/UploadDocuments";

export default function Documents() {
  const [uploadBox, setUploadBox] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);
  return (
    <Root theme={theme}>
      <h4>{strings.profile.documents}</h4>
      <div>
        <NavLink
          to="/add-documents"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Add Document{" "}
        </NavLink>
        <button
          onClick={() => {
            setUploadBox(true);
          }}
        >
          Upload Document
        </button>

        <div>
          <div className="document_box flex items-center gap-3 my-3">
            <img src="/images/pan_icon.png" />
            <div>
              <h4>Pan Card</h4>
              <p>SDNA Tech </p>
            </div>
            <button className="ml-auto">
              <HiDotsVertical />
            </button>
          </div>
          <div className="document_box flex items-center gap-3 my-3">
            <img src="/images/aadhar_icon.png" />
            <div>
              <h4>Aadhaar Card</h4>
              <p>SDNA Tech </p>
            </div>
            <button className="ml-auto">
              <HiDotsVertical />
            </button>
          </div>
          <div className="document_box flex items-center gap-3 my-3">
            <img src="/images/pan_icon.png" />
            <div>
              <h4>Form 16</h4>
              <p>SDNA Tech </p>
            </div>
            <button className="ml-auto">
              <HiDotsVertical />
            </button>
          </div>
        </div>

        <UploadDocuments
          open={uploadBox}
          handleClose={() => {
            setUploadBox(false);
          }}
          handleCallback={() => {}}
        />
      </div>
    </Root>
  );
}
const Root = styled.div`
  .document_box {
    background-color: ${(p) => addOpacity(p.theme.tertiary_bg, 0.5)};
    padding: 10px;
    border-radius: 10px;
  }
`;
