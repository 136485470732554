import React, { useEffect, useState } from "react";
import StyleFormBox from "../../../../../styledComponent/Form/StyleFormBox";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../../../styledComponent/Inputs/CheckBox";
import { Formik } from "formik";
import LoadingBox from "../../../../DialogBox/LoadingBox";
import Input from "../../../../../styledComponent/Inputs/Input";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  editRemunerationAction,
  saveRemunerationAction,
} from "../../../../../redux/itr/action";
import { removeEmptyKeys } from "../../../../../utils/helpers";

export default function RemunerationPartnershipFirms({ data, handleCallback }) {
  const [formData, setFormData] = useState({});
  const { strings } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const handleSubmitForm = (obj) => {
    setLoader(true);
    let { type, ...data } = obj;
    const action =
      type == "edit" ? editRemunerationAction : saveRemunerationAction;
    dispatch(
      action(data, (e) => {
        if (e.status === 200) {
          handleCallback(type, e.data);
          toast.success(
            `Successfully ${type == "edit" ? "updated" : "added"} data`
          );
        } else {
          toast.error("Something Went wrong");
        }
        setLoader(false);
      })
    );
  };

  const validationSchema = Yup.object().shape({
    nameOfPartnershipFirm: Yup.string().required(
      "Name of partnership firm is required"
    ),
    sharePercentageInPartnershipFirm: Yup.number()
      .required("Share percentage in partnership firm is required")
      .min(0, "Share percentage cannot be negative")
      .max(100, "Share percentage cannot exceed 100"),
    panOfPartnershipFirm: Yup.string()
      .required("PAN of partnership firm is required")
      .length(10, "PAN must be exactly 10 characters"),
    remunerationFromFirm: Yup.number().required(
      "Remuneration from firm is required"
    ),
    interestReceived: Yup.number().required("Interest received is required"),
    profitAmountReceivedFromFirm: Yup.number().required(
      "Profit amount received from firm is required"
    ),
    capitalBalanceInFirmEOLastFY: Yup.number().required(
      "Capital balance in firm as of last FY is required"
    ),
    anyOtherAmountAllowableAsDeduction: Yup.number().required(
      "Any other amount allowable as deduction is required"
    ),
    isTheFirmLiableForAudit: Yup.boolean().required(
      "Liability for audit is required"
    ),
    firmLiableToFurnishReportUnder92E: Yup.boolean().required(
      "Firm liability to furnish report under 92E is required"
    ),
  });

  useEffect(() => {
    setLoader(true);
    setFormData({
      nameOfPartnershipFirm: data?.nameOfPartnershipFirm || "",
      sharePercentageInPartnershipFirm:
        data?.sharePercentageInPartnershipFirm || "",
      panOfPartnershipFirm: data?.panOfPartnershipFirm || "",
      remunerationFromFirm: data?.remunerationFromFirm || "",
      interestReceived: data?.interestReceived || "",
      profitAmountReceivedFromFirm: data?.profitAmountReceivedFromFirm || "",
      capitalBalanceInFirmEOLastFY: data?.capitalBalanceInFirmEOLastFY || "",
      anyOtherAmountAllowableAsDeduction:
        data?.anyOtherAmountAllowableAsDeduction || "",
      isTheFirmLiableForAudit: data?.isTheFirmLiableForAudit || true,
      firmLiableToFurnishReportUnder92E:
        data?.firmLiableToFurnishReportUnder92E || false,
      itrId: ItrData?.id,
      id: data?.id || "",
      type: data.id ? "edit" : "add",
    });
    setLoader(false);
  }, [data]);

  return (
    <div>
      <LoadingBox open={loader} />
      <Formik
        initialValues={formData}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(removeEmptyKeys(values));
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setValues }) => (
          <form onSubmit={handleSubmit}>
            <StyleFormBox>
              <h4>{strings.income.remunerationDetails}</h4>
              <hr />
              <div className="flex_input my-3">
                <label>{strings.income.name_of_partnership_firm} *</label>
                <Input
                  name={"nameOfPartnershipFirm"}
                  error={errors.nameOfPartnershipFirm}
                  value={values.nameOfPartnershipFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-2">
                <label>
                  {strings.income.share_percentage_partnership_firm} *
                </label>
                <Input
                  type={"number"}
                  inputType={"percentage"}
                  name={"sharePercentageInPartnershipFirm"}
                  error={errors.sharePercentageInPartnershipFirm}
                  value={values.sharePercentageInPartnershipFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.pan_of_partnership_firm} *</label>
                <Input
                  name={"panOfPartnershipFirm"}
                  error={errors.panOfPartnershipFirm}
                  value={values.panOfPartnershipFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.remuneration_from_firm} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"remunerationFromFirm"}
                  error={errors.remunerationFromFirm}
                  value={values.remunerationFromFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.interest_received} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"interestReceived"}
                  error={errors.interestReceived}
                  value={values.interestReceived}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.profit_amount_received_firm} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"profitAmountReceivedFromFirm"}
                  error={errors.profitAmountReceivedFromFirm}
                  value={values.profitAmountReceivedFromFirm}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>
                  {strings.income.capital_balance_firm_as_31_march} *
                </label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"capitalBalanceInFirmEOLastFY"}
                  error={errors.capitalBalanceInFirmEOLastFY}
                  value={values.capitalBalanceInFirmEOLastFY}
                  onChange={handleChange}
                />
              </div>
              <div className="flex_input my-3">
                <label>{strings.income.amount_allowable_as_deduction} *</label>
                <Input
                  type={"number"}
                  inputType={"currency"}
                  name={"anyOtherAmountAllowableAsDeduction"}
                  error={errors.anyOtherAmountAllowableAsDeduction}
                  value={values.anyOtherAmountAllowableAsDeduction}
                  onChange={handleChange}
                />
              </div>

              <CheckBox
                value={values.isTheFirmLiableForAudit}
                label={strings.income.firm_liable_audit}
                handleChange={(e) =>
                  setValues({
                    ...values,
                    isTheFirmLiableForAudit: !values.isTheFirmLiableForAudit,
                  })
                }
              />
              <CheckBox
                value={values.firmLiableToFurnishReportUnder92E}
                label={strings.income.firm_liable_furnish_report_under_92e}
                handleChange={(e) =>
                  setValues({
                    ...values,
                    firmLiableToFurnishReportUnder92E:
                      !values.firmLiableToFurnishReportUnder92E,
                  })
                }
              />
            </StyleFormBox>

            <button className="primary_button my-4" type="submit">
              {strings.button.save_and_close}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
}
