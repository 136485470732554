import React, { useEffect, useState } from "react";
import PanVerify from "../component/LogIn/PanVerify";
import { useParams } from "react-router-dom";
import Questions from "./Questions";
import ItrUploadForm from "../component/ITR/ItrUploadForm";

export default function FileItrFlow() {
  const [activePage, setActivePage] = useState('');
  const { active_tab } = useParams();

  useEffect(()=>{
    if(active_tab == 'link_pan'){
      setActivePage('link_pan')
    }else if(active_tab == "questions"){
      setActivePage('questions')
    }else if(active_tab.split('_')[0] +active_tab.split('_')[1]  == 'itrform'){
      setActivePage('itr_form')
    }
  },[active_tab])
  return (
    <div>
      {activePage === "link_pan" ? (
        <PanVerify />
      ) : activePage === "questions" ? (
        <Questions />
      ) : activePage === "itr_form" ? (
        <ItrUploadForm redirect={active_tab.split('_')[2]}/>
      ) : ''}
    </div>
  );
}
