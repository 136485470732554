import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export default function OtpField({otpLength, handleClick }) {
  const [otp, setOtp] = useState([]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const { strings, theme } = useSelector((s) => s.platformSetting);
  const handleChange = (index, e) => {
    if (e.target.value.length > 1) {
      return;
    }
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    if (e.target.value !== "" && index < otp.length - 1) {
      inputRefs[index + 1].current.focus();
    }
    setOtp(newOtp);
    handleClick(newOtp)
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text");
    if (pastedData.length === otp.length) {
      const newOtp = pastedData.split("").slice(0, otp.length);
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(()=>{
    if(otpLength){
      setOtp(Array.from({ length: otpLength }, () => ""))
    }else{
      setOtp(Array.from({ length: 4 }, () => ""))
    }
  },[otpLength])
  return (
    <div>
      <div className="flex items-center justify-center mt-8">
      {otp.map((value, index) => (
        <input
          key={index}
          ref={inputRefs[index]}
          type="number"
          maxLength={1}
          className="w-12 h-12 mx-1 text-2xl border border-gray-300 text-center focus:outline-none rounded-xl font-medium otp_input bg-transparent"
          style={{ width: "58px" }}
          value={value}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          onPaste={handlePaste}
        />
      ))}
    </div>
    
    </div>
  );
}
