import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { addOpacity } from "../utils/helpers";
import { NavLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../styledComponent/Inputs/Input";
import { userLoginAction } from "../redux/profile/action";
import LoadingBox from "../component/DialogBox/LoadingBox";
import { toast } from "react-toastify";
import SocialLogin from "../component/LogIn/SocialLogin";

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const { strings, theme } = useSelector((s) => s.platformSetting);

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const validationSchema = Yup.object().shape({
    userEmail: Yup.string().required(strings.validation_message.phone_email),
    password: Yup.string().required(strings.validation_message.password),
  });

  const handleCallback = (e) => {
    if (e.status === 200) {
      toast.success(strings.toast_message.login_success);
      navigator("/");
    }
    setLoading(false);
  };
 
  const handleSubmitForm = (data) => {
    setLoading(true);
    dispatch(userLoginAction(data, handleCallback));
  };



  return (
    <Root theme={theme}>
      <Formik
        initialValues={{
          userEmail: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitForm(values);
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <label>
              {strings.label.phone_number} / {strings.label.email}
            </label>
            <Input
              name={"userEmail"}
              placeholder={`${strings.label.phone_number} / ${strings.label.email}`}
              error={errors.userEmail}
              value={values.userEmail}
              onChange={handleChange}
            />

            <label>{strings.label.password}</label>
            <Input
              inputType={"password"}
              name={"password"}
              placeholder={strings.label.password}
              error={errors.password}
              value={values.password}
              onChange={handleChange}
            />

            <NavLink to="/password-reset" className="forgot_pass">
              {strings.signUp.forgot_password} ?{" "}
            </NavLink>
            <button className="primary_button mt-5" type="submit">
              {strings.button.sign_in}
            </button>
          </form>
        )}
      </Formik>

<SocialLogin />
   

      <LoadingBox open={loading} />
    </Root>
  );
}
const Root = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${(p) => p.theme.secandary_bg};
  .forgot_pass {
    text-align: center;
    color: ${(p) => addOpacity(p.theme.primary_txt, 0.4)};
    background: none;
    border: none;
    margin: auto;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 60px auto 20px auto;
    cursor: pointer;
  }

`;
