
import { all } from 'redux-saga/effects';
import { ProfileData } from './profile/sagas';
import PlatformSettingData from './platformSetting/sagas';
import { ItrData } from './itr/sagas';

export default function* rootSaga() {
  yield all([
    ProfileData(),
    PlatformSettingData(),
    ItrData(),
  ]);
}
