import React, { useEffect, useState } from "react";
import SalaryIncome from "../../CommonScreens/GrossIncome/SalaryIncome";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrossIncomeAction,
  getProfessionalBusiness,
} from "../../../redux/itr/action";
import { toast } from "react-toastify";
import { addOpacity } from "../../../utils/helpers";
import CapitalGainIncome from "../../CommonScreens/GrossIncome/CapitalGainIncome";
import ProfessionalIncome from "../../CommonScreens/GrossIncome/ProfessionalIncome";
import OtherSourceIncome from "../../CommonScreens/GrossIncome/OtherSourceIncome";
import HousePropertyIncome from "../../CommonScreens/GrossIncome/HousePropertyIncome";
import styled from "styled-components";

export default function Index({ handleNext }) {
  // const [optionData, setOptionData] = useState({});
  const [loading, setLoading] = useState(false);
  const [incomeData, setIncomeData] = useState({});

  const { strings, theme } = useSelector((s) => s.platformSetting);
  const { ItrData } = useSelector((s) => s.itr);
  const dispatch = useDispatch();

  // const getProfessionalBusinessApi = async () => {
  //   setLoading(true);
  //   let parm = `?itrId=${ItrData.id}`;
  //   dispatch(
  //     getProfessionalBusiness(parm, (e) => {
  //       console.log("getProfessionalBusiness3=========>", e.data);
  //       if (e.status == 200) {
  //         setOptionData(e.data);
  //         setLoading(false);
  //       } else {
  //         toast.error(e.message);
  //         setLoading(false);
  //       }
  //     })
  //   );
  // };

  // useEffect(() => {
  //   getProfessionalBusinessApi();
  // }, []);

  const getGrossIncomeApi = () => {
    dispatch(
      getGrossIncomeAction(ItrData.id, (data) => {
        if (data.status == 200) {
          setIncomeData(data.data.grossIncomeDetails);
        }
      })
    );
  };
  useEffect(() => {
    getGrossIncomeApi();
  }, []);

  console.log("incomeData =====>", incomeData);
  return (
    <Root theme={theme}>
      <SalaryIncome
        getData={incomeData}
        handleNext={() => handleNext()}
        handleClick={() => {}}
        handleRefresh={() => getGrossIncomeApi()}
      />
      {incomeData && incomeData?.grossSalary && (
        <div className="box_heading">
          <h4 style={{ color: theme.primary_txt }}> Gross Salary</h4>
          <div>
            <h4
              style={{ color: theme.primary_txt }}
            >{`${strings.label.rupee} ${incomeData?.grossSalary}`}</h4>
          </div>
        </div>
      )}
      <ProfessionalIncome
        gstData={incomeData?.businessProfessionIncome?.gstDetails}
        financialParticularsData={incomeData?.financialParticulars}
      />
      <CapitalGainIncome />
      <OtherSourceIncome
        getData={incomeData?.otherIncomeSource}
        handleRefresh={() => getGrossIncomeApi()}
      />
      <HousePropertyIncome
        data={incomeData?.housePropertyIncome}
        handleCallback={() => getGrossIncomeApi()}
      />

      <button
        type="submit"
        className="primary_button mt-4"
        onClick={handleNext}
      >
        {strings.button.next}
      </button>
    </Root>
  );
}

const Root = styled.div`
  .box_heading {
    display: flex;
    width: 100%;
    background-color: ${(p) => p.theme.secandary_bg};
    gap: 18px;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    cursor: pointer;
    button {
      background-color: ${(p) => addOpacity(p.theme.primary_bg, 0.5)};
      border-radius: 50%;
      padding: 6px;
      border: none;
      font-size: 16px;
      transition: all 0.5s;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
`;
